export default {
  "screen0": {
    "enTitle1": "Together",
    "enTitle2": "Let's <text style='color:#800'>Build The Future.</text>",
    "title1": "EBCのパートナーになって <br>",
    "title2": "より多くのマーケットチャンスを手にいれよう",
    "list": ["国際機関による二重の監督", "グローバルで開かれたパートナーシップ", "充実のテクニカルソリューション"],
    "desc": "EBCはパートナーとの密接なビジネス関係の構築に取り組んでいます。多様なサポート体制で新たな可能性を切り拓くお手伝いをさせていただきます。グローバルビジネスにおける無限の可能性を引き出すために、私たちはここにいます。",
    "links": [{
      "url": "/about-us",
      "text": "Why EBC?"
    }, {
      "url": "",
      "text": "もっと詳しく見る"
    }],
		"more": "もっと詳しく見る"
  },
  "screen1": {
    "pageEnTitle": "Customization",
    "pageTitle": "ご要望に応じたサービス",
    "title": "紹介ブローカー（IB）に<span>100万ドル</span>の支援プラン",
    "desc": "EBCの発展に紹介ブローカー（IB）のサポートは不可欠です。我々は実力のある紹介ブローカー（IB）に成長の場を提供します。",
    "list": ["マージン体系", "ビジネスイノベーションマーケティング&取引リソース", "効率的な決済と支払いプロセス", "専任アカウントマネージャー", "マルチティアコミッションシステム", "多言語サポートチーム", "優れたバックオフィスとレポートツール ", "事業スケールアウトの全面サポート" ],
    "text": "オールラウンドに<span>カスタマイズされた育成計画</span>",
    "more": "他のサービスについて詳しく見る"
  },
  "screen2": {
    "pageEnTitle": "Trading  service",
    "pageTitle": "取引コミュニティサービス",
    "title": "事業発展協力モデル",
    "desc": "あなたのご要望に応じて、EBCはこれまでに蓄積した豊富な経験やノウハウをもとにアドバイスを提供するとともに、それぞれのブローカーの事業の発展に合ったテクニカルサポートをします。",
    "list": ["取引コミュニティ", "取引シグナル", "リスクマネジメント"],
    "btn": "取引コミュニティに加入する"
  },
  "screen3": {
    "pageEnTitle": "Liquidity service",
    "pageTitle": "流動性アクセスのサポート",
    "title": "EBCはストレートスループロッセッシング(STP)を採用",
    "desc": "ブローカーのヘッジコスト削減と迅速な注文約定を実現するために、国際的な多くのトップ流動性プロバイダーと連携した、専門の技術チームによる24時間365日のテクニカルサポートサービスも提供しています。",
    "remark": "<span>時間が経つほどに私たちのことがお分かり頂けると思います</span>"
  },
  "screen4": {
    "pageEnTitle": "Liquidity service",
    "pageTitle": "リクイディティ供給サービス",
    "title": "EBCは常に変化を受け入れる熱意と能力を備えています",
    "desc": "金融業界は常に革新と変化を求めています。私達は市場の大きな変化の波に乗ることで着実に前進できると信じています。もしあなたが革新的なパートナープランをお持ちであればぜひお聞かせください。",
    "list": ["<span>4</span>つのアセットクラス", "<span>100</span>種類以上の取引商品"]
  },
	"box-class": "partner-box-en"
}
