export default {
  "box-name": "en-style",
  "public-btn-text1": "ดูชั่วโมงการซื้อขาย",
  "public-btn-text2": "ดูสเปรดและสินค้าโภคภัณฑ์ทั้งหมด",
  screen0: {
      "title": "เวลาเท่านั้นที่ทำใ<br>ห้คุณรู้จักเราดีขึ้น",
      "desc": "ที่ EBC คุณสามารถเข้าถึงตลาดที่มีสภาพคล่องมากที่สุดในโลกและเพลิดเพลินไปกับการเทรดตลอด24 ชั่วโมง 5 วันทำการ บนตราสารกว่า 60 ตัวเลือก",
      "btn": "เรียนรู้เพิ่มเติม",
  },
  screen1: {
      "link1": "ฟอเร็กซ์",
      "link2": "สินค้าโภคภัณฑ์",
      "link3": "ดัชนี CFDs",
      "link4": "หุ้น CFDs",
  },
  screen2: {
      "title": "ฟอเร็กซ์",
      "text1": "เลเวอเรจยืดหยุ่นสูงถึง1000:1",
      "text2": "ความเร็วในการส่งคำสั่งซื้อขายเฉลี่ย20ms",
      "imgUrl": "trade-01-th",
      "title2": "ซื้อขายเพียง 0 pips 36 คู่สกุลเงิน",
      "desc2": "ตามการสำรวจของ ธนาคารเพื่อการชำระหนี้ระหว่างประเทศ (BIS) การซื้อขายในตลาดฟอเร็กซ์ทั่วโลกพุ่งขึ้นสู่ระดับสูงสุดเป็นประวัติการณ์ที่ 6.6 ล้านล้านดอลลาร์ต่อวันในเดือนกันยายนปี 2019",
  },
  screen3: {
      "title": "สินค้าโภคภัณฑ์",
      "text1": "เลเวอเรจที่ยืดหยุ่น",
      "text2": "สเปรดที่มีการแข่งขันสูง",
      "text3": "ธุรกรรมเปิดตำแหน่งที่ยืดหยุ่น",
      "title2": "หากคุณต้องการกระจายพอร์ตการลงทุน สินค้าโภคภัณฑ์จะเป็นตัวเลือกที่เหมาะสมที่สุดของคุณ",
      "desc2": "โลหะมีค่า น้ำมันดิบ ก๊าซธรรมชาติ ฯลฯ",
  },
  screen4: {
      "title": "ดัชนี CFDs",
      "text1": "ราคาที่ยืดหยุ่น",
      "text2": "ความเร็วในการส่งคำสั่งซื้อเฉลี่ย 20ms",
      "text3": "ใช้ประโยชน์จากความยืดหยุ่นของเลเวอเรจสูงสุด 100: 1",
      "text4": "ไม่มีค่าคอมมิชชั่นที่ซ่อนอยู่หรือค่าธรรมเนียมการทำธุรกรรม",
      "title2": "เลือกดัชนีอันดับต้น ๆ สำหรับคุณและสำรวจพื้นที่ที่มีมูลค่าและคึกคักที่สุดของเศรษฐกิจโลก",
  },
  screen5: {
      "title": "หุ้น CFDs",
      "text1": "สเปรดที่มีการแข่งขันสูงเป็นพิเศษ สามารถซื้อขายในราคาต่ำเพียง $3 สำหรับการซื้อขายหุ้นสหรัฐฯ",
      "text2": "เข้าถึงตลาดหุ้น 36 แห่งทั้งในตลาดที่เติบโตเต็มที่และตลาดเกิดใหม่",
      "text3": "สภาพคล่องที่เหนือชั้น",
      "text4": "ความยืดหยุ่นของเลเวอเรจสูงถึง 20: 1 (ระดับมาร์จิ้น 5%)",
      "title2": "CFD หุ้น ใส่บริษัทชั้นนำของโลกลงในพอร์ตโฟลิโอของคุณได้อย่างง่ายดาย",
  },
}