export default {
  "box-name": "en-account-page",
  "screen0": {
    "title": "Where your goals begin",
    "desc": "귀하의 거래 경험이나 거래 스타일에 관계없이, 저희는 귀하의 수요에 적합한 거래 계좌를 제공합니다."
  },
  "screen1": {
    "btn": "추가 정보",
    "title1": "STD 일반계좌",
    "title2": "PRO 전문계좌",
    "line11": "1.1의 낮은 스프레드",
    "line13": "0.0의 낮은 스프레드",
    "line99": "무제한",
    "line100": "100USD",
    "list": [
      "스프레드 ",
      "커미션",
      "최대 레버리지",
      "최소 1건 거래량",
      "최대 1건 거래량",
      "최저 예금액",
      "유지 증거금 비율",
      "지정가 주문 제한",
      "고정 포지션의 허용",
      "EA 호환성",
      "24/5 온라인 지원",
      "전속 고객 관리자",
      "계정은 다음에 적용됩니다",
      "계정 개설하기"
    ]
  },
  "screen2": {
    "title": "전 세계 수백만 명의 상인 중 하나가 되십시오",
    "desc": "계좌 개설을 위한 간단한 3단계 과정",
    "list": [{
      "title": "계정 등록",
      "desc": "안전하고 간편한 온라인 페이지에서 기본 정보 제출"
    }, {
      "title": "개인 정보 제출",
      "desc": "1분이면 작성하는 계좌 개설 정보"
    }, {
      "title": "입금 및 활성화",
      "desc": "$200 의 최소 예치금으로 실시간 활성화 "
    }],
    "btn1": "데모 계좌 개설 →",
    "btn2": "실제 계좌 개설 →",
    "yuyan": "ko"
  },
  "screen3": {
    "pay": "입금방식"
  },
  "screen4": {
    "enTitle": "Your Trading Solution Has Been Never <span style='color: #800000;'>Easier",
    "title": "거래 관리의 새로운 경험",
    "desc": "복잡함은 단순함으로 귀결된다, 저희는 단순함과 질서가 모든 금융 시장의 장기 수익성에 가장 중요한 요소라고 믿습니다. EBC는 고객 전용 CRM 관리 시스템을 제공하여 고객님의 거래를 질서 있게 진행합니다.",
    "imgUrl": "ko"
  },
	"screen5": {
		"title": "거래계좌",
		"register-btn": "즉시 계좌 개설",
		"text1": "계좌 유형을 선택하세요.",
		"text2": "EBC는 다양한 계좌 유형을 제공하고, 유연하고 효율적인 거래 경험을 보장합니다.",
		"text3": "STD 일반계좌",
		"text4": "모든 투자자 및 소액 투자자에게 적합합니다.",
		"text5": "최저 스프레드  <i>1.1</i>부터 시작하여 귀하의 거래를 더욱 효율적으로 만듭니다.",
		"text6": "거래 시 수수료가  <i>0</i> %이며 투자 비용을 투명하게 절감하세요",
		"text7": "<i>$100</i>의 최소 입급 금액으로 거래 계정을 활성화할 수 있습니다",
		"text8": "PRO 전문계좌",
		"text9": "전문 투자자 및 고급 트레이더에게 적합합니다.",
		"text10": "<i>0</i> 핍 스프레드 거래 환경을 제공하여 최소 거래 비용을 찾는 전문 트레이더에게 적합합니다.",
		"text11": "1랏 당  <i>6</i> 달러로,  전문 금융 기관 수준의 경제적인 혜택을 제공합니다.",
		"text12": "최소 입금 금액 <i>5000</i> 달러가 필요합니다.",
		"text13": "계정 세부 규정 비교",
		"text14": "각 계정 유형의 주요 차이점을 통해 귀하의 거래 전략에 적합한 계정을 선택하는 데 도움을 드리겠습니다.",
		"text15": "유형",
		"text16": "계정은 다음에 적용됩니다",
		"text17": "모든 투자자",
		"text18": "전문 투자자",
		"text19": "STD 표준 계정 개설하기",
		"text20": "PRO 전문가 계정 개설하기",
		"text21": "EBC의 강점",
		"text22": "STD 표준 계정과 PRO 전문가 계정 중 어느 것을 선택하든, 플랫폼의 핵심 장점을 모두 누릴 수 있습니다.",
		"text23": "간편한 계좌 개설 3단계 과정",
		"text24": "3단계 과정으로 간편하게 계정을 개설하고, 전 과정에서 고객 서비스가 지원되어 귀하의 거래 여정을 신속하게 시작할 수 있습니다.",
		"text25": "거래 플랫폼 다운로드",
		"text26": "MT4는 전 세계적으로 최고 수준의 외환 거래 플랫폼으로, 우수한 차트 도구, 다양한 기술 지표 및 자동 거래 기능으로 유명합니다. MT4는 PC에서 깊이 있는 분석과 핸드폰에서 실시간 거래, 웹 페이지에서의 실시간 모니터링에 대해서도 원활하고 유연한 거래 경험을 제공합니다.",
		"text27": "즉시 다운로드하기",
	}
}
