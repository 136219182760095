export default {
    "box-name": "",
    "public-btn-text1": "即刻交易",
    "public-btn-text2": "查看点差和全部产品",
    screen0: {
        "title": "CFD 股息",
        "desc": "请注意：CFD 股息收取会在您的MT4账户上的余额里以存款或者取款形式反映出来，注释为 “Dividend”",
    },
    screen1: {
        "title": "安全交易",
    },
    screen2: {
        "title": "金融世界 触手可及",
    }
}