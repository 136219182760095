export default {
	"official-class-name": "official-cn-box",
	"lang": "tw",
	"official-page1": {
		"text1": "官方頻道驗證",
		"text2": "為防止不法分子盜用EBC名義謀利，您可透過此頁面進行資訊查詢，驗證頻道真實性。",
		"text3": "請輸入完整網站鏈接",
		"text4": "查詢驗證",
	},
	"official-page2": {
		"text1": "EBC官方聲明：",
		"text2": "1. EBC目前只上線官方的MetaTrader 4（MT4）交易平台，請從官方網站下載正版MT4軟體。",
		"text3": "2. EBC目前未涉足任何加密貨幣及與其相關的CFD交易。",
		"text4": "3. EBC不提供任何形式的投資建議，也不會對交易收益進行任何形式的承諾或保證。",
		"text5": "4. 對任何盜用EBC名義的行為，我們將立即採取法律行動，對盜用者進行嚴厲打擊。",
		"text6": "5. EBC致力於維護客戶權益，若您發現任何可疑活動，請將證據提交給我們的客服團隊，或透過電子郵件至<a href=\"mailto:cs@ebc.com\" style=\"color: #4D0101;\">cs@ebc.com</a>與我們聯繫。我們將迅速做出回應，並為受騙者提供必要的法律支援和援助。",
	},
	"official-page3": {
		"text1": "您查詢的資訊已通過官方驗證",
		"text2": "返回重新查詢",
		"text3": "您查詢的資訊未經官方驗證，請不要與未經驗證的管道互動",
		"text4": "檢舉該訊息",
		"text5": "如果您認為該資訊涉嫌虛假盜用，請向我們提交舉報",
		"text6": "檢舉訊息",
		"text7": "網址",
		"text8": "*如果您需要獲得更多法律援助與回饋，請",
		"text8-1": "請點此留言",
		"text8-2": "",
		"text9": "收起留言板",
		"text10": "貴姓/暱稱",
		"text11": "請輸入貴姓/暱稱",
		"text12": "信箱",
		"text13": "請輸入您的電子郵件信箱",
		"text14": "手機號碼",
		"text15": "請輸入您的手機號碼",
		"text16": "資訊回饋",
		"text17": "請填寫資訊回饋",
		"text18": "驗證碼",
		"text19": "請輸入驗證碼",
		"text20": "取消",
		"text21": "提交舉報",
		"text22": "已提交，感謝您的回饋。",
	},
}