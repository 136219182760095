export default {
	"box-class-name": "oxford-box-en",
	"button-text": "もっと詳しく知る",
	"oxford-page1": {
		"text1": "WHAT<br> ECONOMISTS<br> REALLY<br> DO",
		"text2": "租税回避に関する経済学",
		"text3": "Sarah Clifford, with David Barrett",
		"text4": "今すぐ予約する",
		"text5": "2024年3月6日（水）21：00～22：00　（日本時間）",
	},
	"oxford-page2": {
		"text1": "教育を通じた経済の繁栄",
		"text2": "EBC FINANCIAL GROUPがオックスフォード大学とのウェビナー「経済学者が実際に何をしているか」を共催",
		"text3": "企業の社会的責任（CSR）への継続的な取り組みとして、 EBC Financial Groups は社会進歩を促進する先駆者を積極的に支援しています。教育や人材育成への意義ある貢献を目指し、オックスフォード大学などの機関と協力しています。",
		"text4": "",
		"text5": "イベント紹介",
		"text6": "「経済学者が実際に何をしているか」というウェビナーシリーズは、2024年3月6日（水）21時～22時（日本時間）に予定されており、「租税回避に関する経済学」というテーマで行われます。このウェビナーは、金融業界が直面している問題と、経済学および公共教育が政策の改善や経済の繁栄を促進するためにどのように発揮できるかを明らかにします。",
	},
	"oxford-page3": {
		"text1": "関係者",
		"text2": "オックスフォード大学経済学部、経済学準教授",
		"text3": "Sarah氏は公共経済学を専門とし、特に税金に関連する経済行動、国際企業税、多国籍企業の租税回避、家計による個人所得税回避に焦点を当てています。専門分野には公共経済学と応用ミクロ経済学が含まれます。",
		"text4": "ナフィールド大学経済学教授、副学長（外部連携）",
		"text5": "Abi氏の応用経済学に関する研究では、消費者と家族の選択の計量分析に焦点を当てました。 研究の主なテーマは、データに対する意思決定に新しいモードを導入する実証的手法の開発、労働市場をより深く理解するために大規模なデータセットを活用すること、英国の法制度における司法へのアクセスを定量化するためのフレームワークの開発などです。",
		"text6": "CEO, EBC Financial Group (UK) Ltd.",
		"text7": "Barrett氏は、大手金融機関からベンチャー企業に至るまで、外国為替、債券、デリバティブ等の金融商品分野において35年以上従事し、専門知識と経験が豊富な金融プロフェッショナルです。 世界的な金融危機と規制変革を乗り越えられた彼は、経済サイクルと金融規制環境に対して深く理解し、リスク管理とビジネス戦略について鋭いインサイトを提供できます。",
	},
	"oxford-page4": {
		"text1": "EBC Financial Group について",
		"text2": "ロンドンに設立された EBC Financial Group は、東京、シドニー、シンガポール、香港などの主要な国際金融都市に拠点を擁しており、外国為替、コモディティ、株価指数において革新的な取引サービスを提供しています。英国FCAライセンスや豪州ASICライセンス等複数の金融規制当局の認可を受けております。<br><br>EBCは誠実さと顧客第一の経営理念を大切にし、グローバルでは複数の賞を受賞しました。EBCは高度なインフラ、独自の受発注ツール、高い流動性に加え、迅速な注文執行及び24時間365日のカスタマーサポートを提供しています。<br><br>すべてのトレーダーに対して、誠実に対応いたします。",
		"text3": "オックスフォード大学経済学部について",
		"text4": "オックスフォード大学の経済学部は、影響力のある研究成果と政策への貢献、活発な若手研究者コミュニティ、評判の高い教育プログラム等の面で世界的に有名であり、そして世界で最大かつ最も多様な経済学術コミュニティの一つとして誇ります。",
	},
}