export default {
	"screen0": {
		"title": "<span>We Value Your Safety</span><br>허가·안전성·안정성",
	},
	"screen1": {
		"link1": "EBC 소개",
		"link2": "FCA와 ASIC의 이중 규제",
		"link3": "EBC 명예",
		"link4": "EBC의 강점",
		"link5": "기업 문화",
		"link6": "파트너",
		"link7": "문의하기",

	},
	"screen2": {
		"title": "EBC 소개",
		"desc1": "EBC 그룹은 영국 런던에 설립된 금융 중개, 자산관리, 이민투자 등 서비스를 통합한 종합 금융그룹입니다. EBC의 비즈니스는 도쿄, 시드니, 싱가포르, 홍콩 및 기타 국제 금융 센터에 지사와 사무소를 두고 있으며, 경영진은 30년 이상의 글로벌 금융시장 운영 경험을 보유하고 있습니다.",
		"desc2": "EBC는 항상 자금의 안전을 유지하는 것을 주요 책임으로 삼습니다. EBC는 세계에서 가장 엄격한 감독을 받는 것 외에도 파트너를 위한 바클레이은행의 독립 에스크로 계정을 제공하고 매년 1천만 달러 이상의 보험에 가입하며 CASS 규정을 엄격히 시행합니다. 또한 강력한 자본력과 최고 수준의 위험 관리 조치를 통해 불확실한 위험 요소를 차단합니다.",
		"desc3": "EBC는 거래를 열심히 하는 사람은 모두 열심을 다한 대우를 받을 가치가 있다고 굳게 믿습니다."
	},
	"screen3": {
		"title": "FCA와 ASIC의 이중 규제",
		"desc": "EBC 금융 그룹의 자회사는 해당 지역 관할 구역에서 규제 및 라이센스를 받습니다.",
		"desc-l1": "EBC Financial Group (UK) Ltd",
		"desc-l2": "영국 금융감독청(FCA)의 승인 및 규제를 받고 있습니다",
		"desc-l3": "레퍼런스 번호: 927552",
		"desc-r1": "EBC Financial Group (Australia) Pty Ltd",
		"desc-r2": "호주 증권 투자 위원회(ASIC)의 승인 및 규제를 받고 있습니다",
		"desc-r3": "레퍼런스 번호:500991",
		"desc-rr1": "EBC Financial Group (Cayman) Ltd",
		"desc-rr2": "는 케이맨 제도 통화당국(CIMA)의 승인 및 규제를 받고 있습니다.",
		"desc-rr3": "레퍼런스 번호: 2038223",
	},
	"screen4": {
		"title": "EBC 명예",
		"desc1": "가장 신뢰받는 FX 브로커",
		"desc2": "최고의 CFD 브로커",
		"desc3": "톱 10 증권 회사",
		"desc4": "글로벌 최고의 ECN 브로커",
		"desc5": "글로벌 신예 브로커",
		"desc6": "가장 투명한 브로커",
		"desc7": "최고 등급 브로커",
		"desc8": "가장 효율적인 브로커 상",
	},
	"screen5": {
		"title": "EBC의 강점",
		"list": [
			{
				"title": "1000",
				"desc": "초당 최고 집계 주문"
			},
			{
				"title": "<20<i>MS</i>",
				"desc": "평균 실행 속도"
			},
			{
				"title": "98<i>.75%</i>",
				"desc": "최대 데이터 전송 안정성"
			},
			{
				"title": "25<i>+</i>",
				"desc": "헤지 편드 및 상위 투자 은행과의 협력"
			},
			{
				"title": "7*24",
				"desc": "VIP 전용 서비스"
			},
			{
				"title": "0.0<i>PIPS",
				"desc": "인터뱅크 레벨의 RAW ECN 스프레드"
			},
		]
	},
	"screen6": {
		"title": "기업 문화",
		"desc": "우리의 핵심 가치는 고객을 위해「Real Value」의 전 영역을 적극적으로 창출하는 것입니다. ",
		"list": [
			{
				"title": "안전",
				"desc": "FCA 및 ASIC의 이중 규제, 바클레이의 자금 안전성 보장"
			},
			{
				"title": "공평",
				"desc": "EBC는 신용과 존중의 원칙을 고수하며 모든 거래자를 진지하게 대합니다"
			},
			{
				"title": "전념",
				"desc": "세부적으로 EBC 서비스의 이념을 감지하며 가장 뛰어난 사용자 경험을 제공합니다"
			},
			{
				"title": "가치",
				"desc": "\"안전, 전문성, 안정성\"<br>개인 자산의 가치를 전방위적으로 제고"
			},
			{
				"title": "성실",
				"desc": "EBC는 성실과 신뢰를 바탕으로 완벽한 거래 경험을 선사합니다."
			},
			{
				"title": "책임",
				"desc": "모든 긍정적인 상호작용은 세상을 더 나은 곳으로 나아가게 합니다"
			},
		]
	},
	"screen7": {
		"title": "파트너",
	},
	"screen8": {
		"title": "문의하기",
		"desc1": "시장 협력",
		"desc2": "우리와 함께",
		"desc3": "불만 및 제안",
	},
}

