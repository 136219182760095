export default {
  "box-name": "",
  "public-btn-text1": "查看交易時段",
  "public-btn-text2": "查看點差和全部產品",
  screen0: {
      "title": "唯有時間,讓您,<br>更了解我",
      "desc": "超過60種金融產品和極具競爭力的機構定價，帶您進入全球最受歡迎且流動性最高的市場，掌控24/5的最佳交易機會。",
      "btn": "了解更多",
  },
  screen1: {
      "link1": "衍生品交易",
      "link2": "大宗商品",
      "link3": "指數差價合約",
      "link4": "股票差價合約",
  },
  screen2: {
      "title": "衍生品交易",
      "text1": "槓桿率高達500:1",
      "text2": "平均執行速度20ms",
      "imgUrl": "trade-01",
      "title2": "最低0點差即可交易<br>36種貨幣對",
      "desc2": "全球最大的金融交易市場 2019年9月國際清算銀行（BIS）報告顯示，全球衍生品市場交易量已躍升至歷史最高記錄，日均交易量達6.6萬億美元。",
  },
  screen3: {
      "title": "大宗商品",
      "text1": "靈活的槓桿",
      "text2": "極具競爭力的點差",
      "text3": "靈活建倉交易",
      "title2": "想投資組合多元化，大宗商品會是您的理想選擇",
      "desc2": "貴金屬、原油、天然氣等。",
  },
  screen4: {
      "title": "指數差價合約",
      "text1": "實時彈性定價",
      "text2": "平均執行速度 20ms",
      "text3": "槓桿率高達100:1",
      "text4": "無隱藏佣金和交易費用",
      "title2": "為您甄選全球主流指數，積極探索全球經濟中最有價值和最活躍的領域",
  },
  screen5: {
      "title": "股票差價合約",
      "text1": "極具競爭力的定價",
      "text2": "交易全球成熟和新興市場 36 家交易所股票",
      "text3": "超乎尋常的流動性支持",
      "text4": "槓桿率高達20:1",
      "title2": "股票差價合約，輕鬆將世界頂級公司納入您的投資組合",
  },
}