export default {
	"box-class-name": "oxford-box-en",
	"button-text": "Tìm hiểu thêm",
	"oxford-page1": {
		"text1": "WHAT ECONOMISTS REALLY DO",
		"text2": "KINH TẾ HỌC TRÁNH THUẾ",
		"text3": "Sarah Clifford & David Barrett",
		"text4": "Tìm hiểu thêm",
		"text5": "12 GIỜ TRƯA GMT NGÀY 6 THÁNG 3 NĂM 2024",
	},
	"oxford-page2": {
		"text1": "NỀN KINH TẾ PHÁT TRIỂN DỰA VÀO GIÁO DỤC",
		"text2": "TẬP ĐOÀN TÀI CHÍNH EBC HỖ TRỢ CÁC SÁNG KIẾN CSR CỦA KHOA KINH TẾ OXFORD, NỔI BẬT QUA HỘI THẢO TRỰC TUYẾN SẮP TỚI “CÁC NHÀ KINH TẾ THỰC SỰ LÀM GÌ”",
		"text3": "Trong cam kết liên tục của chúng tôi về trách nhiệm xã hội của doanh nghiệp (CSR), Tập đoàn Tài chính EBC tự hào hỗ trợ các sáng kiến thúc đẩy tiến bộ xã hội. Cho dù đó là hỗ trợ giáo dục, bồi dưỡng phát triển tài năng hay thúc đẩy sự bền vững về môi trường, cam kết của chúng tôi đối với CSR sẽ hướng dẫn chúng tôi tạo ra sự khác biệt có ý nghĩa. Chúng tôi tin vào việc tận dụng nguồn lực và kiến thức chuyên môn của mình để tạo ra sự thay đổi tích cực và đóng góp vào một tương lai tốt đẹp hơn cho tất cả mọi người.",
		"text4": "Sự hợp tác mới nhất của chúng tôi minh chứng cho cam kết này. Chúng tôi tự hào tuyên bố hợp tác với Khoa Kinh tế của trường Đại học Oxford, một ngôi trường nổi tiếng về sự xuất sắc trong học tập và sự cống hiến cho sự tiến bộ xã hội. Thông qua sự hợp tác này, chúng tôi đang khai thác sức mạnh của giáo dục để tạo ra tác động lâu dài. Cùng nhau, chúng tôi khuyến khích các cá nhân đổi mới và thúc đẩy sự thay đổi tích cực trong cộng đồng trên toàn thế giới. Hãy tham gia cùng chúng tôi trên hành trình này vì chúng tôi mong muốn tạo ra một tương lai tươi sáng hơn, công bằng hơn cho các thế hệ mai sau.",
		"text5": "GIỚI THIỆU SỰ KIỆN",
		"text6": "Dự kiến diễn ra vào ngày 6 tháng 3 năm 2024 lúc 12 giờ trưa GMT, hội thảo trực tuyến về “Các nhà kinh tế thực sự làm gì” sẽ đi sâu vào chủ đề “Kinh tế học tránh thuế”. Cuộc thảo luận làm sáng tỏ các vấn đề mà ngành tài chính phải đối mặt ngày nay và cách sử dụng kinh tế và giáo dục công để cải thiện chính sách và tạo điều kiện cho một nền kinh tế thịnh vượng.",
	},
	"oxford-page3": {
		"text1": "NHỮNG NGƯỜI LIÊN QUAN",
		"text2": "Phó Giáo sư Kinh tế, Khoa Kinh tế, Đại học Oxford",
		"text3": "Sarah chuyên về kinh tế công cộng, tập trung vào hành vi kinh tế liên quan đến thuế, đặc biệt là việc thực thi. Nghiên cứu của cô bao gồm thuế doanh nghiệp quốc tế, tránh thuế đa quốc gia và trốn thuế thu nhập cá nhân của các hộ gia đình. Lĩnh vực chuyên môn của cô bao gồm kinh tế công cộng và kinh tế vi mô ứng dụng.",
		"text4": "Giáo sư Kinh tế, Cao đẳng Nuffield, Phó Trưởng phòng (Hợp tác đối ngoại)",
		"text5": "Trong nghiên cứu của Abi về Kinh tế Ứng dụng, cô thường tập trung vào kinh tế lượng về sự lựa chọn của người tiêu dùng và gia đình. Các chủ đề chính trong nghiên cứu của cô bao gồm: phát triển các phương pháp thực nghiệm để đưa các phương thức mới vào việc ra quyết định vào dữ liệu, khai thác các bộ dữ liệu quy mô lớn để hiểu rõ hơn về thị trường lao động và phát triển các khuôn khổ để định lượng khả năng tiếp cận công lý trong hệ thống pháp luật của Anh.",
		"text6": "CEO, EBC Financial Group (UK) Ltd.",
		"text7": "Ông Barrett có hơn 35 năm chuyên môn về ngoại hối, thu nhập cố định và các công cụ phái sinh, với kinh nghiệm trong công việc có được từ các tổ chức cấp 1 đến các doanh nghiệp nhỏ. Với sự hiểu biết toàn diện về chu kỳ kinh tế và môi trường pháp lý, ông đã cung cấp những hiểu biết có giá trị về quản lý rủi ro và chiến lược kinh doanh nhằm ứng phó với cuộc khủng hoảng tài chính toàn cầu và những thay đổi về quy định.",
	},
	"oxford-page4": {
		"text1": "VỀ EBC FINANCIAL GROUP",
		"text2": "Được thành lập tại London, EBC Financial Group cung cấp các dịch vụ giao dịch về ngoại hối, hàng hóa và chỉ số với sự chính trực và tập trung vào khách hàng. Được công nhận với nhiều giải thưởng, dịch vụ tận tâm của chúng tôi thúc đẩy tăng trưởng khách hàng thông qua cơ sở hạ tầng tiên tiến, các công cụ chuyên dụng và khả năng truy cập thanh khoản, khớp lệnh nhanh và hỗ trợ khách hàng 24/7.<br><br>Với dấu ấn quốc tế rộng khắp, chúng tôi điều hướng thị trường địa phương một cách nhạy bén và duy trì tính liêm chính và đạo đức. Đối với những người đang tìm kiếm một đối tác tận tâm với triết lý đặt khách hàng lên hàng đầu, EBC Financial Group là một lựa chọn tuyệt vời trong thế giới tài chính năng động.<br><br>Cung cấp cho mỗi nhà giao dịch trung thành sự chăm sóc vượt trội và xuất sắc.",
		"text3": "VỀ KHOA KINH TẾ, ĐẠI HỌC OXFORD",
		"text4": "Khoa Kinh tế của Đại học Oxford nổi tiếng toàn cầu nhờ nghiên cứu có tác động, đóng góp chính sách có ảnh hưởng, cộng đồng khởi nghiệp sôi động và các chương trình đại học và sau đại học được đánh giá cao, khiến trường trở thành một trong những cộng đồng kinh tế học thuật lớn nhất và đa dạng nhất.",
	},
}