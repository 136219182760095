<template>
	<div class="box">
		<div class="swiper-container1" :style="`width: ${width}; height: ${height};`">
			<div class="swiper-wrapper">
				<div class="swiper-slide banner-li" v-for="(item, index) in list" :key="index"
					:class="$t('CFooters.bannerLang')">
					<div class="banner-li__bg" @click="jumPage(item)" :class="'img' + index">
						<img v-if="isMobile" :src="require(`../../assets/image/banner/mobile-banner${item.img}-${$t('CFooters.bannerLang')}.jpg`)"
							alt="">
					</div>
				</div>
			</div>
			<div class="swiper-pagination"></div>
			<div class="slogan">{{$t('Index.pages1.title')}}</div>
		</div>
		<!-- 如果需要导航按钮 -->
		<div class="swiper-button-prev banner-prev"><img src="../../assets/image/index/prev.png" alt=""></div>
		<!--左箭头。如果放置在swiper外面，需要自定义样式。-->
		<div class="swiper-button-next banner-next"><img src="../../assets/image/index/next.png" alt=""></div>
		<!--右箭头。如果放置在swiper外面，需要自定义样式。-->
	</div>
</template>
<script>
	import 'swiper/css/swiper.css'
	import Swiper from 'swiper'
	import {
		mapState
	} from "vuex";
	export default {
		name: 'BannerSlide',
		props: {
			width: { // 宽度
				type: String,
				default: '100%'
			},
			height: { // 高度
				type: String,
				default: '100%'
			}
		},
		data() {
			return {
				swiper1: null,
				num: 4,
				list: [{
					path: "FCB",
					img: 0
				},{
					path: "oxford",
					img: 1
				},{
					path: "malaria",
					img: 2
				}, {
					path: "LEARN-647-43B",
					img: 3
				}],
				status: false,
				isMobile:""
			}
		},
		computed: {
			...mapState({
				Lan: (state) => state.Lan,
			}),
		},
		mounted() {
			this.isMobile = this._isMobile()
			setTimeout(() => {
				this.swiper1 = new Swiper('.swiper-container1', {
					lazy: {
						loadPrevNext: true, // 默认false情况下swiper只加载当前slide的图片，其他图片不加载，设置为true后，swiper会提前加载下一个slide的图片
						loadPrevNextAmount: 2 // 默认为1，设置在延迟加载图片时，提前多少个slide
					},
					navigation: { // 如果需要前进后退按钮
						nextEl: '.swiper-button-next',
						prevEl: '.swiper-button-prev'
					},
					scrollbar: { // 如果需要滚动条
						el: '.swiper-scrollbar',
						hide: true // 滚动条是否自动隐藏，默认false
					},
					// 显示分页
					pagination: {
						el: ".swiper-pagination",
						clickable: true, //允许分页点击跳转
					},
					slidesPerView: 1,
					mousewheel: false, // 是否开启鼠标滚轮控制swiper切换 ，默认false
					direction: 'horizontal', // 滑动方向
					speed: 500, // 切换速度，自动滑动开始到结束的时间
					grabCursor: true, // 悬浮时鼠标样式切换
					centerInsufficientSlides: true, // 当slide总数小于slidesPerView时，slide居中
					effect: 'slide', // slide的切换效果，默认为'slide'位移切换，'fade'淡入，'cube'方块，'coverflow'3d流，'flip'3d翻转，'cards'卡片式，'creative'创意性
					cubeEffect: { // cube效果参数
						slideShadows: false, // 是否开启slide投影，默认true
						shadow: false, // 是否开启投影，默认true
						shadowOffset: 20, // 投影距离。默认 20，单位px。
						shadowScale: 1 // 投影缩放比例。默认0.94。
					},
					autoplay: true, // 启动自动切换，等同于以下设置
					autoplay: {
						delay: 3000, // 多少秒切换一次，默认3000ms
						disableOnInteraction: false, // 用户操作之后，是否禁止autoplay，默认true，操作包括触碰，拖动，点击pagination
						waitForTransition: true // 是否等待过渡完成，再开始自动切换的计时，默认true
					},
					loop: true // 循环模式选项
				})
			}, 500)
			// 6.6.2之前的版本需要通过代码实现此功能；开启后，鼠标置于swiper时暂停自动切换，离开时回复自动切换

		},
		methods: {
			_isMobile() {
				let flag = navigator.userAgent.match(
					/(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i
				);
				return flag;
			},
			jumPage(idx) {
				if(idx.path == "oxford"){
					this.$router.push('/oxford')
				}else if(idx.path == "FCB"){
					this.$router.push('/FCB')
				}else if(idx.path == "malaria"){
					this.$router.push('/malaria')
				}else{
					this.$router.push({
						name: 'LearningCenterInfo',
						query: {
							id: idx.path
						}
					})
				}
			}
		}
	}
</script>
<style lang="scss">
	.box {
		position: relative;
		overflow: hidden;
		height: 100%;
		.swiper-button-prev {
			left: 30px;
			opacity: 0.6;
		}

		.swiper-button-next {
			right: 30px;
			opacity: 0.6;
		}
		.swiper-pagination{
			bottom:90px;
			.swiper-pagination-bullet{
				height: 10px;
				width: 10px;
				margin: 0 !important;
				background-color: rgba(#fff,0.8);
				&:nth-child(2){
					margin: 0 20px!important;
				}
				&:nth-child(4){
					margin: 0 20px!important;
				}
			}
		}
		.swiper-wrapper {
			height: 100%;

			.banner-li {
				height: 100%;

				.img0 {
					background: url('../../assets/image/banner/banner0-cn.jpg') 100%/auto 100% no-repeat;
					background-position: center center;
					background-size: cover;
					height: 100%;
					cursor: pointer;
				}

				.img1 {
					background: url('../../assets/image/banner/banner1-cn.jpg') 100%/auto 100% no-repeat;
					background-position: center center;
					background-size: cover;
					height: 100%;
					cursor: pointer;
				}

				.img2 {
					background: url('../../assets/image/banner/banner2-cn.jpg') 100%/auto 100% no-repeat;
					background-position: center center;
					background-size: cover;
					height: 100%;
				}

				.img3 {
					background: url('../../assets/image/banner/banner3-cn.jpg') 100%/auto 100% no-repeat;
					background-position: center center;
					background-size: cover;
					height: 100%;
				}

				&.en {
					.img0 {
						background: url('../../assets/image/banner/banner0-en.jpg') 100%/auto 100% no-repeat;
						background-position: center center;
						background-size: cover;
						height: 100%;
						cursor: pointer;
					}

					.img1 {
						background: url('../../assets/image/banner/banner1-en.jpg') 100%/auto 100% no-repeat;
						background-position: center center;
						background-size: cover;
						height: 100%;
						cursor: pointer;
					}

					.img2 {
						background: url('../../assets/image/banner/banner2-en.jpg') 100%/auto 100% no-repeat;
						background-position: center center;
						background-size: cover;
						height: 100%;
					}

					.img3 {
						background: url('../../assets/image/banner/banner3-en.jpg') 100%/auto 100% no-repeat;
						background-position: center center;
						background-size: cover;
						height: 100%;
					}
				}
				&.ja {
					.img0 {
						background: url('../../assets/image/banner/banner0-ja.jpg') 100%/auto 100% no-repeat;
						background-position: center center;
						background-size: cover;
						height: 100%;
						cursor: pointer;
					}

					.img1 {
						background: url('../../assets/image/banner/banner1-en.jpg') 100%/auto 100% no-repeat;
						background-position: center center;
						background-size: cover;
						height: 100%;
						cursor: pointer;
					}
				
					.img2 {
						background: url('../../assets/image/banner/banner2-ja.jpg') 100%/auto 100% no-repeat;
						background-position: center center;
						background-size: cover;
						height: 100%;
					}
				
					.img3 {
						background: url('../../assets/image/banner/banner3-ja.jpg') 100%/auto 100% no-repeat;
						background-position: center center;
						background-size: cover;
						height: 100%;
					}
				}
				&.ko {
					.img0 {
						background: url('../../assets/image/banner/banner0-ko.jpg') 100%/auto 100% no-repeat;
						background-position: center center;
						background-size: cover;
						height: 100%;
						cursor: pointer;
					}

					.img1 {
						background: url('../../assets/image/banner/banner1-en.jpg') 100%/auto 100% no-repeat;
						background-position: center center;
						background-size: cover;
						height: 100%;
						cursor: pointer;
					}
				
					.img2 {
						background: url('../../assets/image/banner/banner2-ko.jpg') 100%/auto 100% no-repeat;
						background-position: center center;
						background-size: cover;
						height: 100%;
					}
				
					.img3 {
						background: url('../../assets/image/banner/banner3-ko.jpg') 100%/auto 100% no-repeat;
						background-position: center center;
						background-size: cover;
						height: 100%;
					}
				}
				&.tw {
					.img0 {
						background: url('../../assets/image/banner/banner0-tw.jpg') 100%/auto 100% no-repeat;
						background-position: center center;
						background-size: cover;
						height: 100%;
						cursor: pointer;
					}

					.img1 {
						background: url('../../assets/image/banner/banner1-tw.jpg') 100%/auto 100% no-repeat;
						background-position: center center;
						background-size: cover;
						height: 100%;
						cursor: pointer;
					}
				
					.img2 {
						background: url('../../assets/image/banner/banner2-tw.jpg') 100%/auto 100% no-repeat;
						background-position: center center;
						background-size: cover;
						height: 100%;
					}
				
					.img3 {
						background: url('../../assets/image/banner/banner3-tw.jpg') 100%/auto 100% no-repeat;
						background-position: center center;
						background-size: cover;
						height: 100%;
					}
				}
				&.ug {
					.img0 {
						background: url('../../assets/image/banner/banner0-en.jpg') 100%/auto 100% no-repeat;
						background-position: center center;
						background-size: cover;
						height: 100%;
						cursor: pointer;
					}

					.img1 {
						background: url('../../assets/image/banner/banner1-en.jpg') 100%/auto 100% no-repeat;
						background-position: center center;
						background-size: cover;
						height: 100%;
						cursor: pointer;
					}
				
					.img2 {
						background: url('../../assets/image/banner/banner2-ug.jpg') 100%/auto 100% no-repeat;
						background-position: center center;
						background-size: cover;
						height: 100%;
					}
				
					.img3 {
						background: url('../../assets/image/banner/banner3-ug.jpg') 100%/auto 100% no-repeat;
						background-position: center center;
						background-size: cover;
						height: 100%;
					}
				}
				&.th {
					.img0 {
						background: url('../../assets/image/banner/banner0-th.jpg') 100%/auto 100% no-repeat;
						background-position: center center;
						background-size: cover;
						height: 100%;
						cursor: pointer;
					}

					.img1 {
						background: url('../../assets/image/banner/banner1-en.jpg') 100%/auto 100% no-repeat;
						background-position: center center;
						background-size: cover;
						height: 100%;
						cursor: pointer;
					}
				
					.img2 {
						background: url('../../assets/image/banner/banner2-th.jpg') 100%/auto 100% no-repeat;
						background-position: center center;
						background-size: cover;
						height: 100%;
					}
				
					.img3 {
						background: url('../../assets/image/banner/banner3-th.jpg') 100%/auto 100% no-repeat;
						background-position: center center;
						background-size: cover;
						height: 100%;
					}
				}

				&.vn {
					.img0 {
						background: url('../../assets/image/banner/banner0-vn.jpg') 100%/auto 100% no-repeat;
						background-position: center center;
						background-size: cover;
						height: 100%;
						cursor: pointer;
					}

					.img1 {
						background: url('../../assets/image/banner/banner1-en.jpg') 100%/auto 100% no-repeat;
						background-position: center center;
						background-size: cover;
						height: 100%;
						cursor: pointer;
					}
				
					.img2 {
						background: url('../../assets/image/banner/banner2-vn.jpg') 100%/auto 100% no-repeat;
						background-position: center center;
						background-size: cover;
						height: 100%;
					}
				
					.img3 {
						background: url('../../assets/image/banner/banner3-vn.jpg') 100%/auto 100% no-repeat;
						background-position: center center;
						background-size: cover;
						height: 100%;
					}
				}
			}
		}
	}

	.swiper-button-prev:after,
	.swiper-button-next:after {
		display: none;
	}

	.banner-prev{
		left: 18px !important;
	}
	.banner-next{
		right: 18px !important;
	}
	.banner-prev,
	.banner-next{
		width: 100px;
    	height: 100px;
		top: 44%;
		img{
			width: 24px;
		}
	}

	.slogan{
		color: #ffffff;
		position: absolute;
		bottom: 121px;
		z-index: 999;
		left: 50%;
		transform: translateX(-50%);
		letter-spacing: 2px;
		font-size: 16px;
	}

	@media(max-width:1400px) {
		.slogan {
			font-size: 14px;
		}
	}

	@media(max-width:750px) {
		.swiper-button-prev {
			display: none;
		}

		.swiper-button-next {
			display: none;
		}
		.swiper-pagination{
			bottom: 40px !important;;
		}
		.box .swiper-pagination .swiper-pagination-bullet{
			height: 8px;
    		width: 8px;
		}
		.box .swiper-pagination .swiper-pagination-bullet:nth-child(2),.box .swiper-pagination .swiper-pagination-bullet:nth-child(4){
			margin: 0 16px !important;
		}
		.slogan {
			bottom: 62px;
			font-size: 14px;
			width: 100%;
			text-align: center;
		}
	}
</style>