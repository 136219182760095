export default {
    "box-name": "",
    "public-btn-text1": "即刻交易",
    "public-btn-text2": "查看點差和全部產品",
    screen0: {
        "title": "CFD互換率/股息",
        "desc": "請注意：CFD股息收取會在您的MT4帳戶上的餘額裏以存款或者取款形式反映出來，注釋為“Dividend”",
    },
    screen1: {
        "title": "安全交易",
    },
    screen2: {
        "title": "金融世界 觸手可及",
    }
}