export default {
	"headlist": [{
		"text": "Trang chủ",
		"url": "/"
	}, {
		"text": "Giao dịch",
		"tips": "",
		"img": "11",
		"menu": [{
			"tit": "Tài khoản & Điều kiện",
			"list": [ {
				"text": "Tài khoản giao dịch",
				"url": "/TradingAccount"
			}, {
				"text": "Sản phẩm giao dịch",
				"url": "/tradingproduct"
			}, {
				"text": "Quỹ đảm bảo/ đòn bẩy",
				"url": "/leverage-margin"
			},{
				"text": "Nạp/Rút tiền",
				"url": "/funding"
			}, {
				"text": "Cổ tức",
				"url": "/dividend"
			}]
		},  {
			"tit": "Công cụ giao dịch",
			"list": [{
				"text": "Tải xuống MT4",
				"url": "/MT4"
			},{
				"text": "Tải xuống MT5",
				"url": "/MT5"
			},{
				"text": "Công cụ giao dịch MT4",
				"url": "/tools"
			}, {
				"text": "PAMM",
				"url": "/PAMM"
			}]
		}]
	}, {
		"text": "Viện nghiên cứu EBC",
		"tips": "",
		"img": "33",
		"menu": [{
			"tit": "",
			"list": [{
				"text": "Trung tâm học tập",
				"url": "/LearningCenter"
			}]
		}]
	}, {
		"text": "Hoạt động gần đây",
		"tips": "",
		"img": "33",
		"menu": [{
			"tit": "",
			"list": [{
				"text": "Sao chép giao dịch",
				"url": "/from0tohero"
			},{
				"text": "Cuộc thi giao dịch",
				"url": "/win"
			},
			// {
			// 	"text": "Thách thức môi trường giao dịch mạnh nhất",
			// 	"url": "/no1"
			// }
		]
		}]
	}, {
		"text": "Về EBC",
		"tips": "",
		"img": "55",
		"menu": [ {
			"tit": "Về EBC",
			"list": [{
				"text": "WHY EBC",
				"url": "/about-us"
			},{
				"text": "Trách nhiệm xã hội",
				"url": "/ESG"
			}, {
				"text": "Các câu hỏi thường gặp",
				"url": "/FAQ"
			}]
		},{
			"tit": "Mẫu hợp tác",
			"list": [{
				"text": "Quan hệ đối tác",
				"url": "/affiliate-programmes"
			},{
				"text": "Cơ chế phục vụ",
				"url": "/service"
			},{
				"text": "Công nghệ nền tảng",
				"url": "/technology"
			},
			]
		}]
	}],
	"FCA": "Tài khoản FCA",
	"login": "Đăng nhập",
	"zhuce": "Đăng ký",
	"from0tohero_text": "Sao chép giao dịch",
	"from0tohero_url": "/from0tohero",
	"faq_url": "/FAQ",
}

