<template>
	<div class="login-panel__dropdown dropdown" @mouseenter="dropdownshow()" @mouseleave='dropdownhied()'
		v-if="isMobil">
		<a class="nav-link" :class="{'dropdown-toggle': showArrow_}" :style="[textStyle]" href="#" id="navbarDropdown"
			role="button" data-bs-toggle="dropdown" aria-expanded="false">
			<img src="@/assets/image/img/language.svg" alt="" class="icon-img" v-if="mode == 'home'">
			<img src="@/assets/image/img/language-1.svg" alt="" class="icon-img" v-else>
			{{$t('CFooters.language')}}
		</a>
		<ul class="dropdown-menu" aria-labelledby="navbarDropdown" :style="{backgroundColor: bgColor}"
			:class="{'show':index, [optionClass]: true}">
			<li v-for="(item, index) in languageList" :key="index" @click="handleChangeLamguage(index)">
				<span class="dropdown-item">{{item.text}}</span>
			</li>
		</ul>
	</div>
	<div class="login-panel__dropdown dropdown" @click="dropdownshow()" v-else>
		<a class="nav-link" :class="{'dropdown-toggle': showArrow_}" :style="[textStyle]" href="#" id="navbarDropdown"
			role="button" data-bs-toggle="dropdown" aria-expanded="false">
			<img src="@/assets/image/img/language.svg" alt="" class="icon-img" v-if="mode == 'home'">
			<img src="@/assets/image/img/language-1.svg" alt="" class="icon-img" v-else>
			{{$t('CFooters.language')}}
		</a>
		<ul class="dropdown-menu" aria-labelledby="navbarDropdown" :style="{backgroundColor: bgColor}"
			:class="{'show':index, [optionClass]: true}">
			<li v-for="(item, index) in languageList" :key="index" @click="handleChangeLamguage(index)"
				v-if="item.text != $t('CFooters.language')">
				<span class="dropdown-item">{{item.text}}</span>
			</li>
		</ul>
	</div>
</template>

<script>
	export default {
		name: 'Language',
		inject: ['reload'], //注入App里的reload方法
		data() {
			return {
				languageList: [],
				index: false,
				isMobil: ''
			}
		},
		props: {
			textStyle: {
				type: Object,
				default: () => {}
			},
			optionClass: {
				type: String,
				default: ''
			},
			mode: {
				type: String,
				default: ''
			},
			bgColor: {
				type: String,
				default: 'rgba(247, 247, 247, 0.2)'
			},
			showArrow: {
				type: [String, Boolean],
				default: true
			}
		},
		computed: {
			showArrow_() {
				return String(this.showArrow) !== 'false'
			}
		},
		created() {
			this.isMobil = this._isMobile()
		},
		methods: {
			_isMobile() {
				let flag = navigator.userAgent.match(
					/(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i
				)
				return flag;
			},
			dropdownshow() {
				this.index = true
			},
			dropdownhied() {
				this.index = false
			},
			handleChangeLamguage(language) {
				console.log(language);
				if (language != 'zh' && this.$route.path == '/tools') {
					this.$router.push({
						name: 'Home',
					})
				}
				if(language == 'zh'){
					this.$store.commit('setPosition', 'zh_CN')
				}else if(language == 'en'){
					this.$store.commit('setPosition', 'en_US')
				}else if(language == 'ko'){
					this.$store.commit('setPosition', 'ko_KR')
				}else if(language == 'ja'){
					this.$store.commit('setPosition', 'ja_JP')
				}else if(language == 'th'){
					this.$store.commit('setPosition', 'th_TH')
				}else if(language == 'vi'){
					this.$store.commit('setPosition', 'weiwuer')
				}else if(language == 'tw'){
					this.$store.commit('setPosition', 'zh_TW')
				}else if(language == 'vn'){
					this.$store.commit('setPosition', 'vn')
				}
				this.$i18n.locale = language
				localStorage.setItem('language', language)
				this.reload();
			}
		},
		created() {
			this.languageList = this.$config.languageData
		}
	}
</script>

<style lang="scss" scoped>
	.dropdown {
		.nav-link{
			display: flex;
			align-items: center;
		}
		.icon-img {
			width: 20px;
			height: 20px;
			margin-right: 4px;
		}

		&-menu {
			width: 7.5rem;

			&-white {
				.dropdown-item {
					color: #212529;

					&:focus,
					&:hover {
						background-color: #e9ecef;
						color: #1e2125;
					}
				}
			}
		}

		&-item {
			color: #fff;
			cursor: pointer;

			&:focus,
			&:hover {
				background-color: #fff;
				color: $theme-color;
			}

			&__img {
				display: none;
				vertical-align: middle;
				margin-right: 5px;
			}
		}

		&-toggle::after {
			font-size: 0.8rem;
			vertical-align: middle;
		}
	}
</style>