export default {
    "h1-title": "MT4便利ツール",
    "imgUrlLang": "-en",
    "public-btn-text": "今体験してください",
    screen0: {
        "title": "取引ニーズを満たすツールのライブラリを作成します",
    },
    screen1: {
        "title": "同じ画像の製品オーバーレイ ツール",
        "desc": "このツールを使用すると、投資家は 2 つの商品の任意の期間における K ラインの相対的な強さを明確に理解でき、またブレイクスルーの有効性を特定することもできます。",
    },
    screen2: {
        "title": "MACDプロフェッショナルインジケーター（二重線）",
        "desc": "株や先物でよく使われるMACDインジケーターツールで、MT4付属のMACDと比べて中国ユーザーの習慣に合致しています。",
    },
    screen3: {
        "title": "MTDriver 損益統計指標",
        "desc": "このツールは、投資家がさまざまな期間の損益を正確に把握するのに役立ちます",
    },
    screen4: {
        "title": "価格変更モバイルプッシュ通知",
        "desc": "ほとんどの投資家は市場を観察する時間が限られており、携帯電話の価格変動は市場のノイズを避けながら市場の傾向を把握するのに役立ちます。",
    },
    screen5: {
        "title": "ワンクリックでEAを閉じる",
        "desc": "操作インターフェースはチャートインターフェースにあり、極端な市場状況が発生した場合に投資家がより迅速かつ便利に損失をストップするのに便利です。",
    },
    screen6: {
        "title": "タイミングオーダーEA",
        "desc": "\MQL4\Experts フォルダに入れてください。指定した時間に注文を行う EA です。使い方は非常に簡単です。ユーザーは自分で調べてください。",
    },
    screen7: {
        "title": "株式モニターEA",
        "desc": "これを \MQL4\Experts フォルダーに置き、EA の正味値の変化を監視し、プロンプトを表示するサウンド ファイルを \Sounds に置き、EA の音楽名に対応する音楽ファイルの名前を入力します。",
    }
}