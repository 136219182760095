export default {
    "box-name": "",
    "public-btn-text1": "즉석 거래",
    "public-btn-text2": "스프레드 및 모든 제품 보기",
    screen0: {
        "title": "CFD 배당금",
        "desc": "알림: CFD 배당금의 수령은 귀하의 MT4 계좌의 잔액에 예금 또는 출금 형식으로 반영되며, \"Dividend\"이라고 기록됩니다. ",
    },
    screen1: {
        "title": "안전한 거래",
    },
    screen2: {
        "title": "재계가 여러분의 손 안에 있습니다",
    }
}