export default{
  "srceen0": {
    "title": "杠杆與保證金",
    "subTitle": "界定<span>交易方式</span>的關鍵",
    "desc": "鑒於金融市場的不確定性和潜在的巨幅波動,出於保護客戶交易安全的考量,EBC現時提供的最大杠杆為500:1。",
    "rateTitle": "杠杆率高達",
    "rateNum": "500:1",
    "rateDesc": "保證金比例0.20%"
  },
  "srceen1": {
    "pageEnTitle": "Secure Transaction",
    "pageTitle": "安全交易",
    "subTitle": "選擇適合的杠杆比例",
    "desc1": "請謹慎考慮您的投資目標及風險承受能力,選擇適合的杠杆比例。",
    "desc2": "為了更好地實現風險管控,請參攷下錶,瞭解每項資產的保證金要求和最大杠杆。",
    "btn": "開始投資"
  }
}
