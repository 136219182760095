export default {
	"leftNav": [{ "id": "#Trading", "name": "Giao dịch" },
		{ "id": "#Regulation", "name": "Giám sát hàng đầu" },
		{ "id": "#Security", "name": "Bảo vệ" },
		{ "id": "#Fintech", "name": "Công nghệ tài chính" },
		{ "id": "#Black-Box", "name": "Black Box" },
		{ "id": "#Private-Room", "name": "Phòng riêng" },
		{ "id": "#Liquidity", "name": "Tính thanh khoản" },
		{ "id": "#Honor", "name": "Giải thưởng quốc tế" },
	],
	"pages1": {
		"h1":"Trust & Respect",
		"title": "Mỗi một nhà giao dịch nghiêm túc đều xứng đáng được coi trọng",
		"btn": " XEM VIDEO ĐẦY ĐỦ "
	},
	"pages2": {
		"title": "Kết nối những sản phẩm giao dịch hot trên toàn thế giới",
		"title1": "Khám phá một thế giới của các cơ hội và lợi thế cạnh tranh, nắm giữ được giá cả thị trường một cách ổn định và chân thực nhất.",
		"btn": " Sản phẩm giao dịch ",
		"text": " ngoại hối ",
		"text1": " Hàng hóa ",
		"text2": " Chỉ số CFD ",
		"text3": " Chia sẻ CFD "
	},
	"pages3": {
		"title": "Giám sát hàng đầu",
		"title1": "Các công ty con thuộc EBC Financial Group đều được quản lý chặt chẽ và nhận giấy phép hàng đầu trong các khu vực pháp lý tương ứng.",
		"btn": " Tìm hiểu về giấy phép của EBC ",
		"text": " Cơ quan Quản lý Tài Chính (FCA) ",
		"text1": " EBC Financial Group (UK) Ltd được cấp phép và quản lý bởi Cơ quan Quản lý Tài Chính (FCA). Số giấy phép: 927552",
		"text2": " Ủy ban Chứng khoán và Đầu tư (ASIC) ",
		"text3": "EBC Financial Group (Australia) Pty Ltd được cấp phép và quản lý bởi Ủy ban Chứng khoán và Đầu tư (ASIC). Số giấy phép: 500991",
		"text4": " Cơ quan tiền tệ Quần đảo Cayman (CIMA) ",
		"text5": "EBC Financial Group (Cayman) Ltd được cấp phép và quản lý bởi Cơ quan tiền tệ Quần đảo Cayman (CIMA). Số giấy phép: 2038223"
	},
	"pages4": {
		"title": "Công hệ hỗ trợ trong giao dịch",
		"title1": "Được triển khai tại London LD5, New York NY4, Singapore SG1, Tokyo TY3 và Hong Kong HK2, các máy chủ độc lập được kết nối bằng các đường chuyên dụng tốc độ cực cao cung cấp khả năng tổng hợp độ trễ cực thấp, định tuyến đơn hàng thông minh và công cụ tối ưu hóa báo giá, đồng thời xây dựng một hệ sinh thái tài chính tốc độ cực cao toàn cầu.",
		"btn": "Khám phá công nghệ nền tảng",
		"text": " Lệnh được khớp mỗi giây ",
		"text1": " Tốc độ khớp lệnh ",
		"text2": " Tốc độ ổn định của số liệu ",
		"text3": " Chênh lệch RAW ECN cấp liên ngân hàng",
	},
	"pages5": {
		"title": "Để 85% lệnh trở lên được chốt với giá tốt hơn",
		"title1": "EBC Trading Black-Box, tối ưu hóa chính xác thuật toán lệnh bán. Xét về tỷ lệ tối ưu hóa giá, tỷ lệ giao dịch của lệnh bán và độ ổn định của thuật toán, EBC Trading Black-Box vượt trội hơn nhiều so với hệ thống giao dịch truyền thống.",
		"btn": " Tìm hiểu EBC Trading Black-Box"
	},
	"pages6": {
		"title": "Không gian giao dịch EBC",
		"title1": "Cho dù bạn là người giao dịch tần xuất cao, hay là nhà giao dịch lệnh lớn, Private Room của EBC đều sẽ xây dựng kênh giao dịch riêng dành cho bạn dựa trên cơ sở khớp thanh khoản, thậm chí là ẩn một phần thông tin giao dịch của bạn để bảo vệ \"con Át chủ\" của bạn, điều này cũng có nghĩa là đơn hàng của bạn không chỉ nhận được báo giá tổng thể hơn, mà còn giúp tránh bị đánh úp trong quá trình giao dịch.",
		"btn": " Tìm hiểu không gian giao dịch"
	},
	"pages7": {
		"title": "Tính thanh khoản hàng đầu quốc tế",
		"title1": "EBC có mối quan hệ hợp tác lâu dài và ổn định với nhiều ngân hàng quốc tế hàng đầu trên thế giới, kết hợp với báo cáo thanh khoản chất lượng cao, cung cấp mốc chênh lệch thị trường có tính cạnh tranh, giúp nhà đầu tư dễ dàng tận hưởng chi phí giao dịch cực thấp.",
		"btn": " Tìm hiểu tính thanh khoản của EBC"
	},
	"pages8": {
		"btn": "Thêm tin tức"
	},
	"pages9": {
		"title": "Bảo vệ toàn diện An tâm giao dịch",
		"title1": "EBC coi an ninh quỹ là nguyên tắc đầu tiên. Tiền của khách hàng được lưu trữ độc lập trong tài khoản lưu ký của Ngân hàng Barclays và thông qua hợp tác với FCA, Ủy ban Tài chính, Lloyds of London và AON, EBC đã thiết lập một cơ chế bảo mật toàn diện và đa cấp.",
		"btn": "Bảo vệ"
	},
	"lang": "en"
}

