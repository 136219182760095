export default {
	"fca1": "Open FCA Account",
	"fca1-m": "Open FCA Account",
	"fca2": "EBC Financial Group (UK) Ltd are authorised by the Financial Conduct Authority (FCA) to deal in investments as principal with a limited licence as a matched principal broker, all client orders are passed to our liquidity providers with no dealer intervention. Reference Number: 927552FCA<br><br>Clients who have an account with EBC Financial Group (UK) Ltd are entitled to claim compensation up to £85,000 under the FSCS compensation scheme. ",
	"fca3": "In line with the Financial Conduct Authority (FCA)’s rules on Client Classification we must classify our clients as either professional clients, retail clients or eligible counterparties.<br><br>According to the Firms FCA permissioning EBC Financial Group (UK) Ltd only service professional clients or Eligible counterparties. If you qualify as either of these classifications you may be able to open an account with 100x leverage and your funds will be held in a EBC Financial Group (UK) Ltd account with Barclays Bank PLC.",
    "fca4":{
		"title":"Professional investors must<br> meet two of the following requirements:",
		"title-m":"Professional investors must<br> meet two of the following requirements:",
		"desc":"The client has carried out, in significant size, on relevant market(s) for an average number of 10 transactions or more per quarter over the previous year",
		"desc1":"Hold a portfolio worth more than half a million Euros including cash deposits and financial instruments.",
		"desc2":"At least one-years' experience working in the financial industry with sound knowledge of CFD/derivatives trading"
	},
	"fca5":{
		"title":"FCA Account opening process",
		"desc":"Download and fill in application form",
		"desc1":"Prepare and certify documents",
		"desc2":"Submit documents and apply by email to EBC Financial Group (UK) Ltd",
		"desc3":"compliance assess the application and documents for signing will be sent if the applicant qualifies.",
		"desc4":"Once signing is complete the account will be open",
		"btn":"Application form for professional clients",
		"btn1":"Application form for institutional investors"
	},
	"fca6":{
		"title":"Open an FCA regulated account with EBC Financial Group (UK) Ltd<br> and gain the comfort of leading regulation and fund security",
		"title-m":"Open an FCA regulated account with EBC Financial Group (UK) Ltd and gain the comfort of leading regulation and fund security",
		"desc":"Financial Conduct Authority (FCA)",
		"desc1":"email: "
	},
	"lang":"en"
}

