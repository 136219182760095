export default {
	"box-class-name": "oxford-box-en",
	"button-text": "더 찾아 봐",
	"oxford-page1": {
		"text1": "WHAT<br> ECONOMISTS<br> REALLY<br> DO",
		"text2": "탈세의 경제학",
		"text3": "Sarah Clifford & David Barrett",
		"text4": "지금 예약",
		"text5": "2024년 3월 6일 오후 12시(그리니치 표준시)",
	},
	"oxford-page2": {
		"text1": "교육을 통한 번영하는 경제",
		"text2": "EBC 금융 그룹은 다가오는 웨비나 \"경제학자들이 실제로 하는 일\"을 통해 주목받는 옥스퍼드 경제학부의 CSR 이니셔티브를 지원합니다.",
		"text3": "기업의 사회적 책임(CSR)에 대한 지속적인 노력으로 EBC Financial Group은 사회적 발전을 주도하는 이니셔티브를 자랑스럽게 지원합니다. 교육 지원, 인재 개발 육성, 환경 지속 가능성 촉진 등 CSR에 대한 우리의 헌신은 우리가 의미 있는 변화를 만들 수 있도록 안내합니다. 우리는 우리의 자원과 전문 지식을 활용하여 긍정적인 변화를 만들고 모두를 위한 더 나은 미래에 기여한다고 믿습니다.",
		"text4": "우리의 최근 협력은 이러한 약속을 잘 보여줍니다. 우리는 학문적 우수성과 사회 발전에 대한 헌신으로 유명한 기관인 옥스퍼드 대학교 경제학과와의 파트너십을 발표하게 된 것을 자랑스럽게 생각합니다. 이러한 협력을 통해 우리는 교육의 힘을 활용하여 지속적인 영향을 미치고 있습니다. 우리는 함께 개인이 전 세계 지역 사회에서 혁신하고 긍정적인 변화를 주도하도록 장려합니다. 우리는 다음 세대를 위해 더 밝고 공평한 미래를 만들기 위한 이 여정에 함께 해주세요.",
		"text5": "이벤트 소개",
		"text6": "2024년 3월 6일 오후 12시(GMT)에 열릴 예정인 \"경제학자들이 실제로 하는 일\"에 대한 웨비나는 \"탈세의 경제학\"이라는 주제를 탐구합니다. 이 토론에서는 오늘날 금융 산업이 직면한 문제와 경제 및 공교육을 활용하여 정책을 개선하고 경제 번영을 촉진하는 방법을 조명합니다.",
	},
	"oxford-page3": {
		"text1": "관련 인물",
		"text2": "옥스퍼드대학교 경제학과 부교수",
		"text3": "Sarah는 과세, 특히 집행과 관련된 경제적 행동에 초점을 맞춘 공공경제학을 전문으로 합니다. 그녀의 연구 분야는 국제 법인세, 다국적 조세 회피, 가계의 개인 소득세 탈세 등입니다. 그녀의 전문 분야는 공공경제학과 응용미시경제학입니다.",
		"text4": "경제학 교수, 너필드 칼리지, 부대표(외부 참여)",
		"text5": "Abi의 응용경제학 연구에서 그녀는 종종 소비자와 가족 선택의 계량경제학에 중점을 두었습니다. 그녀의 연구 주요 주제는 데이터 의사결정에 새로운 모드를 제공하는 실증적 방법 개발, 노동 시장을 더 잘 이해하기 위해 대규모 데이터 세트 활용, 영국 법률 시스템에서 사법에 대한 접근을 정량화하기 위한 프레임워크 개발입니다.",
		"text6": "CEO, EBC Financial Group (UK) Ltd.",
		"text7": "Barrett 씨는 Tier 1 기관에서 소규모 사업 벤처에 이르기까지 외환, 채권 및 파생상품 분야에서 35년 이상의 전문 지식을 보유하고 있습니다. 그는 경제 주기와 규제 환경에 대한 포괄적인 이해를 바탕으로 글로벌 금융 위기와 규제 변화를 헤쳐나가며 위험 관리 및 비즈니스 전략에 대한 귀중한 통찰력을 제공합니다.",
	},
	"oxford-page4": {
		"text1": "에 대한 EBC Financial Group",
		"text2": "런던에 설립된 EBC Financial Group은 무결성과 고객 중심 초점을 바탕으로 외환, 원자재, 지수 부문에서 혁신적인 거래 서비스를 제공합니다. 여러 상을 수상한 것으로 인정받은 당사의 전용 서비스는 고급 인프라, 독점 도구 및 유동성 액세스 신속한 주문 실행 및 연중무휴 고객 지원을 통해 고객의 성장을 주도합니다.<br><br>광범위한 국제적 입지를 바탕으로 우리는 정직성과 윤리적 관행을 유지하면서 현지 시장을 예리하게 탐색합니다. 고객 우선 철학을 지닌 헌신적인 파트너를 찾는 사람들에게 EBC Financial Group은 역동적인 금융 세계에서 확실한 선택으로 떠오릅니다.<br><br>모든 헌신적인 거래자를 위한 탁월한 광채 관리.",
		"text3": "옥스퍼드대학교 경제학과 소개",
		"text4": "옥스포드 대학의 경제학과는 영향력 있는 연구, 영향력 있는 정책 기여, 활발한 초기 경력 커뮤니티, 존경받는 학부 및 대학원 프로그램으로 세계적으로 유명하여 가장 크고 다양한 학술 경제학자 커뮤니티 중 하나입니다.",
	},
}