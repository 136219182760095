export default {
  "box-name": "en-account-page",
  "screen0": {
    "title": "Where your goals begin",
    "desc": "تەجرىبىڭىز ياكى سودا ئۇسلۇبىڭىز قانداق بولسۇن، بىزنىڭ سىزنى，<br/>مەيلى سىزنىڭ سوداڭ ئېھتىياجىڭىزغا ماس كېلىدىغان سودا ھېساباتىمىز بار. "
  },
  "screen1": {
    "btn": "تېخىمۇ كۆپ چۈشىنىش ",
    "title1": "STDﺋﯚﻟﭽﻪﻣﻠﯩﻚ ﮬﯧﺴﺎب",
    "title2": "ﻛﻪﺳﭙﯩﻲ ﮬﯧﺴﺎب PRO",
    "line11": "تۆۋەندىن 1.1 pips غىچە ",
    "line13": "تۆۋەندىن 0.0 pips غىچە ",
    "line99": "چەكلىمىسىز",
    "line100": "100USD",
    "list": [
      "ئۆلچەملىك نۇقتا پەرقى",
      "رەسمىيەت ھەققى ",
      "ئەڭ چوڭ پىشاڭ",
      "ئەڭ كىچىك يەككە سودا مىقدارى",
      "ئەڭ چوڭ يەككە سودا مىقدارى",
      "ﺋﻪڭ ﺗﯚۋەن ﺋﺎﻣﺎﻧﻪت ﺳﻮﻣﻤﯩﺴﻰ ",
      "كۆيۈپ كېتىش نىسبىتى،",
      "يەككە ئارىلىق چەكلىمىسى",
      "مالنى قۇلۇپلاپ قويۇشقا بولىدۇ",
      "EA ماسلىشىشچانلىق",
      "لىنىيىدە قوللاش 24/5",
      "مەخسۇس خېرىدار دىرېكتورى",
      "ماس كېلىدىغان ئاكونتلار",
      "ھېسابات ئېچىش"
    ]
  },
  "screen2": {
    "title": " دۇنياۋى مىليونلىغان سودا قىلغۇچىلارنىڭ بىرى بولۇش،",
    "desc": "ھېسابات ئېچىشتىكى 3 ئاسان قەدەم",
    "list": [{
      "title": "ھېسابات ئېچىش",
      "desc": "ئاساسىي ئۇچۇرلارنى تاپشۇرۇش، بىخەتەر، ئاددىي بولغان توردا ئىلتىماس قىلىش بېتى"
    }, {
      "title": "شەخسىي ئۇچۇرنى تاپشۇرۇش",
      "desc": "پەقەت بىر مىنۇت ئىچىدە ھېسابات ئېچىپ تولدۇرۇشنى ئاسانلا تاماملىغىلى بولىدۇ"
    }, {
      "title": "مەبلەغ سېلىپ قوزغىتىش",
      "desc": "ئەڭ تۆۋەن پۇل كىرگۈزۈش سوممىسى 200 دوللار"
    }],
    "btn1": "تەقلىدىي ھېسابات ئېچىش →",
    "btn2": "ھەقىقىي ھېسابات ئېچىش →",
    "yuyan": "zh"
  },
  "screen3": {
    "pay": "مەبلەغ سېلىش ئۇسۇلى"
  },
  "screen4": {
    "enTitle": "Your Trading Solution Has Been Never <span style='color: #800000;'>Easier",
    "title": "مىسلى كۆرۈلمىگەن سودا باشقۇرۇش يېڭى تەسىراتى ",
    "desc": "مۇرەككەپ، ئاددىي، تەرتىپلىك بولۇشنىڭ ھەرقانداق پۇل مۇئامىلە بازىرىنىڭ ئۇزاق مۇددەت پايدا ئېلىشىدىكى ئەڭ مۇھىم ئامىل ئىكەنلىكىگە چوڭقۇر ئىشىنىمىز. EBC خېرىدارلارنى مەخسۇس CRM باشقۇرۇش سىستېمىسى بىلەن تەمىنلەپ، سودىڭىزنى تەرتىپلىك قىلىدۇ. ",
    "imgUrl": "zh"
  },
	"screen5": {
		"title": "سودا ھېساباتى",
		"register-btn": " ھېساب ئېچىش",
		"text1": "سىزگە ماس كېلىدىغان سودا ھېساباتىنى تاللاڭ",
		"text2": "EBC كۆپ خىل ھېسابات تىپى بىلەن تەمىنلەيدۇ، ئوخشاش بولمىغان تىپتىكى سودىگەرلەرگە ماس كېلىدۇ، سودا تەسىراتىنىڭ يۇقىرى ئۈنۈملۈك بولۇشىغا كاپالەتلىك قىلىدۇ",
		"text3": "STD ئۆلچەملىك ھېساباتى",
		"text4": "بارلىق مەبلەغ سالغۇچىلار ۋە يېنىك كاپىتال سودىگەرلىرىگە ماس كېلىدۇ",
		"text5": "باشلىنىش نۇقتا پەرقى ئەڭ تۆۋەن بولغاندا <i>1.1</i> بولۇپ، سودا تەننەرخنى تېخىمۇ ئۈنۈمىگە ئىگە قىلىدۇ",
		"text6": "<i>0</i> سودا ھەققى ئېتىباردىن بەھرىمەن بولىسىز، سودا تەننەرخى تېخىمۇ ئېنىق ",
		"text7": "پەقەت <i>100</i> $ باشلىنىش مەبلىغى بىلەنلا سودا ھېساباتىنى قوزغىتىشقا بولىدۇ",
		"text8": "PRO كەسپىي ھېساباتى",
		"text9": "كەسپىي مەبلەغ سالغۇچىلار ۋە ئىلغار سودىگەرلەرگە ماس كېلىدۇ",
		"text10": "ئەڭ تۆۋەن سودا تەننەرخىنى ئىزدەۋاتقان كەسپىي سودىگەرلەرگە ماس كېلىدىغان 0 نۇقتا پەرقلىق سودا مۇھىتى بىلەن تەمىنلەيمىز",
		"text11": "پەقەت 6 $/lot بولسىلا، ئاپپارات ئىسچوتى تەننەرخى ۋە ئەۋزەللىك بىلەن تەمىنلەيدۇ.",
		"text12": "5000 $ باشلىنىش مەبلىغى بىلەن سودا ھېساباتىنى قوزغىتىش كېرەك",
		"text13": "ھېسابات تەپسىلىي قائىدىلىرىنى سېلىشتۇرۇش",
		"text14": "ھەر بىر ھېسابات تۈرىدىكى ئاساسلىق پەرقلەر سودا ئىستراتېگىيىڭىزگە ماس كېلىدىغان ھېساباتنى تاللىشىڭىزغا ياردەم بېرىدۇ",
		"text15": " تۈر ئايرىمىسى",
		"text16": "ماس كېلىدىغان ئاكونتلار",
		"text17": "بارلىق مەبلەغ سالغۇچىلار",
		"text18": "كەسپىي مەبلەغ سالغۇچىلار",
		"text19": "STD ئۆلچەملىك ھېسابات ئاچماق",
		"text20": "PRO كەسپىي ھېسابات ئاچماق",
		"text21": "EBC ئەۋزەللىكى",
		"text22": "مەيلى STD ئۆلچەملىك ھېساباتى تاللاڭ ياكى PRO كەسپى ھېساباتى تاللاڭ، ئوخشاشلا سۇپىنىڭ يادرولۇق ئەۋزەللىكىدىن بەھرىمان بولالايسىز",
		"text23": "3 قەدەمدە ئاسانلا ھېسابات ئېچىش",
		"text24": "3 قەدەمدە ئاسانلا ھېسابات ئېچىش، خېرىدارلار مۇلازىمىتى ، سودا مۇساپىڭىزنى ئېچىڭ",
		"text25": " سودا سۇپىسىنى چۈشۈرۈش",
		"text26": "MT 4 دۇنيادىكى ئەڭ ئىلغار تاشقى پېرېۋوت سودا سۇپىسى بولۇپ، ئۆزىنىڭ ئالاھىدە دىئاگرامما قورالى، مول تېخنىكا كۆرسەتكۈچى ۋە ئاپتوماتىك سودا ئىقتىدارى بىلەن داڭلىق.مەيلى سىز PC تېرمىنالىدا چوڭقۇر تەھلىل قىلىش، يان تېلېفون تېرمىنالىدا دەرھال سودا قىلىش ياكى تور بەت تېرمىنالىدا ھەر ۋاقىت نازارەت قىلىش بولسۇن، MT 4 سىزنىڭ راۋان، جانلىق سودا تەسىراتىدىن بەھرىمەن بولۇشىڭىزغا كاپالەتلىك قىلىدۇ",
		"text27": "دەرھال چۈشۈرۈش",
	}
}
