export default {
	"pages1":{
		"title":"EBCインテリジェントコピートレーディングコミュニティ",
		"tips":"エバーティトレーダー",
		"text":"コミュニティの利点",
		"text1":"信号表示",
		"text2":"トレードリワードプラン",
		"text3":"大賞",
		"text4":"ログイン"
	},
	"pages2":{
		"title":"コミュニティの利点",
		"list":[{
			"tit":"柔軟なコピー取引パターン",
			"tips":"EAプラグインなしのコピー取引の開始と柔軟なカスタマイズ"
		},{
			"tit":"ミリ秒の応答",
			"tips":"コネクション指向のMTプロトコルを介してシグナルと同期して取引"
		},{
			"tit":"プロフェッショナルな6次元解析",
			"tips":"6次元解析により、信号評価システムを強化し、高品質の信号を追求します"
		},{
			"tit":"インテリジェントなAI支援ツール",
			"tips":"トップトレーディング戦略を探索するための好みに基づいたワンクリックシグナルスクリーニング"
		},{
			"tit":"透明性のある取引開示",
			"tips":"観察学習のための詳細な取引の実績"
		}],
		"btn":"サインイン"
	},
	"pages3":{
		"title":"信号表示",
		"text":"収益率",
		"text1":"最大ドローダウン",
		"text2":"シャープレシオ",
		"text3":"P/Lレシオ",
		"text4":"無料",
		"text5":"今すぐ購読",
		"btn":"より多くの信号"
	},
	"pages4":{
		"title":"トレードリワードプラン",
		"num":"月々$100",
		"num1":"月々$300",
		"num2":"月々$1,000",
		"num3":"月々$5,000",
		"num4":"月々$10,000",
		"text1":"AUM",
		"text2":"以下のトレーダー数",
		"name":"初期",
		"name1":"良い",
		"name2":"とても良い",
		"name3":"星",
		"name4":"伝説",
		"tips":"'+1% 管理資金/年 (毎月支払い)",
		"btn":"今すぐ参加"
	},
	"pages5":{
		"title":"大賞",
		"text":"24時間カスタマーサポート",
		"text1":"EBCマーケティングキャンペーンへの関与",
		"text2":"VPSスポンサーシップ",
		"text3":"投資家の本のスポンサーシップ",
		"text4":"注文フロー取引ソフトウェアの1年間のサブスクリプション",
		"text5":"カスタマイズされた報酬パッケージ",
		"text6":"上部に表示される信号",
		"text7":"EBC海外事務所訪問",
		"text8":"共有メディアソリューション",
		"text9":"ブランド提携",
		"text10":"オフショアファンド立ち上げアドバイザリーサービス",
		"btn":"サインイン"
	}
}