export default {
	"screen0": {
		"title": "<span>We Value Your Safety</span><br>規制、安全、安定",
	},
	"screen1": {
		"link1": "EBCについて",
		"link2": "規制とライセンス",
		"link3": "受賞歴",
		"link4": "選ばれる理由",
		"link5": "カルチャー",
		"link6": "流動性提供先",
		"link7": "お問い合わせ",

	},
	"screen2": {
		"title": "EBCについて",
		"desc1": "EBC Financial Group は英国ロンドンで設立され、FX、コモディティ、株価指数CFDなどを取り揃えている金融サービス・プロバイダーです。 グローバル金融市場における30年以上に及ぶ経験を持つベテランCEOの下、東京、シドニー、シンガポール、香港などの主要な国際金融都市に拠点を擁しています。",
		"desc2": "EBCは、顧客資金の安全性を常に第一優先としています。 弊社は世界最高水準のFCAライセンスをはじめ、複数の金融ライセンスを保有し、規制を遵守しています。バークレイズ銀行での信託保全（FCA）や、1,000万ドルを超える責任保険などの措置でCASSルールを厳格に遵守します。EBCの堅実な財務基盤と強固なリスク管理体制により、顧客の資産保護を強化します。",
		"desc3": "すべてのトレーダーに誠実な対応をします。"
	},
	"screen3": {
		"title": "規制とライセンス",
		"desc": "EBC Financial Group 傘下の子会社は、各所在地域のライセンスを保有し、認可を受けています。",
		"desc-l1": "EBC Financial Group (UK) Ltd",
		"desc-l2": "英国金融規制当局（FCA）の認可および規制を受けています。",
		"desc-l3": "規制番号：927552",
		"desc-r1": "EBC Financial Group (Australia) Pty Ltd",
		"desc-r2": "オーストラリア証券投資委員会（ASIC）の認可および規制を受けています。",
		"desc-r3": "規制番号：500991",
		"desc-rr1": "EBC Financial Group (Cayman) Ltd",
		"desc-rr2": "Ltdはケイマン諸島金融庁（CIMA）の認可を受け、規制を遵守しています。",
		"desc-rr3": "規制番号: 2038223",
	},
	"screen4": {
		"title": "受賞歴",
		"desc1": "最も信頼されるFXブローカー賞",
		"desc2": "ベストCFDブローカー賞",
		"desc3": "人気ブローカーTOP10賞",
		"desc4": "ベストグローバルECNブローカー賞",
		"desc5": "最優秀グローバル新鋭ブローカー賞",
		"desc6": "最も透明性の高いブローカー",
		"desc7": "最高評価のブローカー",
		"desc8": "最良執行ブローカー賞",
	},
	"screen5": {
		"title": "選ばれる理由",
		"list": [
			{
				"title": "1000",
				"desc": "1秒あたりの注文数"
			},
			{
				"title": "<20<i>MS</i>",
				"desc": "平均執行速度"
			},
			{
				"title": "98<i>.75%</i>",
				"desc": "データ通信安定率"
			},
			{
				"title": "25<i>+</i>",
				"desc": "トップクラスの流動性提供先"
			},
			{
				"title": "24/7",
				"desc": "日本語サービス"
			},
			{
				"title": "0.0<i>PIPS",
				"desc": "銀行間取引並みのローECNスプレッド"
			},
		]
	},
	"screen6": {
		"title": "カルチャー",
		"desc": "私たちのコアバリューは、お客様の「真の価値」を積極的に創造すること。",
		"list": [
			{
				"title": "安全性",
				"desc": "英国FCAと豪州ASICのライセンスを保有。顧客資金はバークレイズ銀行に分別管理（FCA口座）。"
			},
			{
				"title": "公平",
				"desc": "誠実さと敬意を持って、すべてのお客様に真摯に向き合う"
			},
			{
				"title": "献身",
				"desc": "お客様の視点から最高品質のサービスを提供します"
			},
			{
				"title": "バリュー",
				"desc": "安全、プロフェッショナルかつ安定した資産形成"
			},
			{
				"title": "誠実さ",
				"desc": "お客様の信頼を事業活動の原点におき、顧客本位の業務運営体制"
			},
			{
				"title": "責任",
				"desc": "業界の進歩発展に貢献し、より明るい未来へ"
			},
		]
	},
	"screen7": {
		"title": "流動性提供先",
	},
	"screen8": {
		"title": "お問い合わせ",
		"desc1": "法人のお客様",
		"desc2": "求人応募",
		"desc3": "ご意見・ご要望",
	},
}

