export default {
	"esg-class-name": "en-esg",
	"esg-banner": {
		"btn": "詳細はこちら",
		"text1": "EBC Financial Groupは国連財団のマラリア撲滅キャンペーンを支援し、すべての人々の明るい未来のためにマラリア撲滅を目指します。",
		"text2": "子どもたちの成長に力を： EBCの国際的な取り組み",
		"text3": "EBC Financial Groups がオックスフォード大学経済学部のウェビナー「経済学者が実際に何をしているか」をサポート",
		"text3-2": "",
		"text4": "EBC、世界ガールガイド・ガールスカウト協会と提携： 女性の成長に力を与え、明日のリーダーを育てる",
		"text5": "共に未来を築く",
		"text6": "EBC Financial Groupは、環境・社会・ガバナンス（ESG）の原則を実践しています。当社は、財務面における成果にとどまらず、環境、地域社会、そして私たち全員が共有する未来にプラスの影響を与えることを優先しています。"
	},
	"esg-nav": {
		"text1": "私たちの理念",
		"text2": "私たちの対策",
		"text3": "私たちの取り組み",
		"text4": "今までの活動状況",
	},
	"esg-page1": {
		"text1": "私たちの理念",
		"text2": "教育を通じて未来を育む",
		"text3": "私たちの教育への取り組みは、子どもたちの教育環境を整備し、高等教育機関とのパートナーシップを築くことに重点が置かれています。そのために知識共有のための開かれたコミュニティを育成し、イノベーションと学習を奨励することを目指しています。",
		"text4": "社会的公平性の推進",
		"text5": "慈善活動を支援することで、私たちは平等な人権や 社会的公平性を支持しています。私たちの取り組みは、地域社会に力を与え、公平性を確保し、すべての人々が豊かに暮らせるようにすることを目的としています。",
		"text6": "社員へのエンパワーメント",
		"text7": "私たちは、社員が評価され、誇りを感じられる職場づくりに取り組んでいます。私たちの企業文化は開放的であり、包括的であることを奨励し、多様で受容的なグローバル・コミュニティに積極的に貢献するようチームを後押しします。",
		"text8": "持続可能な環境づくり",
		"text9": "サステイナビリティ（持続可能な社会）は、当グループの戦略的ビジョンに不可欠な要素です。私たちは資源の適切な利用を提唱し、持続可能な未来の創造に尽力することで、環境保全への取り組みを示すとともに、より良い明日の世界を創造していきます。",
	},
	"esg-page2": {
		"text1": "私たちの対策",
		"text2": "環境に対する取り組み",
		"text3": "<span>持続可能な資源管理：</span><br>私たちの取り組みは、環境に優しい包装資材の利用、廃棄物の削減、循環型経済の原則の推進にまで及び、これらすべてがエコロジカル・フットプリントの削減を目指しています。",
		"text4": "<span>カーボンフットプリントの削減： </span><br>私たちは地球温暖化対策に積極的に取り組み、再生可能エネルギーを活用する企業と提携することで、持続可能な社会に向けてに貢献しています。",
		"text5": "社会貢献",
		"text6": "<span>慈善事業への取り組み： </span><br>私たちは、寄付やボランティア活動を通じて慈善財団を支援し、公平で包括的な社会の実現に尽力しています。",
		"text7": "<span>倫理的パートナーシップ：</span><br>私たちは、パートナーが環境保護と社会的責任に関する厳格な基準を守ることを徹底し、社会の進歩に向けた取り組み強化しています。",
		"text8": "企業ガバナンスの実践",
		"text9": "<span>多様性と包括性：</span><br>私たちは、多様性と包括性を尊重し、差別に反対し、すべての人に平等な環境を提供する職場づくりに取り組んでいます。",
		"text10": "<span>社員の福利厚生：</span><br>私たちは社員の総合的な福利厚生とプロフェッショナルとしての能力育成を優先し、継続的な学習とスキルアップを支援することで、組織内での成長と活躍を促します。",
	},
	"esg-page3": {
		"text1": "私たちの取り組み",
		"text2": "教育基金",
		"text3": "EBCは、教育が社会的発展の要であるという信念を掲げています。",
		"text4": "非営利団体や 教育機関とのパートナーシップは、EBCの教育改革への取り組みを象徴しています。EBC教育基金は、青少年の心を育むとともに、十分な教育が行き届いていない地域の教育インフラを強化することに資金を提供しており、これは教育のエンパワーメントに対する当社の信念を表しています。",
		"text5": "社会福祉基金",
		"text6": "慈愛の精神が前向きな変化を生み出す。",
		"text7": "この基金は、介護施設への援助をはじめ、身寄りのない高齢者にやすらぎを提供し、健康問題に直面している子どもたちに笑顔を届けるなど、社会の健康と福祉に対する私たちの取り組みを表しているのです。",
		"text8": "社員サポート基金",
		"text9": "社員の幸せを実現し、組織を成長させる。",
		"text10": "従業員サポート基金は、従業員の家族のケアを支援し、組織内の一体感と支えを奨励し、従業員の包括的な幸福への貢献を確認することを目的としています。",
		"text11": "ボランティア基金",
		"text12": "私たちは災害復興に貢献致します。",
		"text13": "ボランティア基金は、世界的な危機に見舞われた地域社会の復興と支援活動をサポートし、困窮時における思いやりと社会的責任を果たすという当グループの理念を体現しています。",
	},
	"esg-page4": {
		"text1": "今までの活動状況",
	}
}