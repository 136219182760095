<template>
	<div id="app">
		<router-view v-if="isRouterAlive"></router-view>
	</div>
</template>
<script>
	export default {
		name: '',
		provide() { //父组件中通过provide来提供变量，在子组件中通过inject来注入变量。                                             
			return {
				reload: this.reload
			}
		},
		data() {
			return {
				isRouterAlive: true
			}
		},
		created() {
			if (this.$route.query.utm_campaign !== undefined) {
				localStorage.setItem('advert', JSON.stringify(this.$route.query))
			}
		},
		mounted() {
			let pathname = window.location.pathname
			if (pathname.indexOf('/cn') != -1) {
				this.$i18n.locale = 'zh'
				localStorage.setItem('language', 'zh')
				this.$store.commit('setPosition', 'zh_CN')
				this.$store.commit('setSuffix', 'cn')
			}else if (pathname.indexOf('/en') != -1) {
				this.$i18n.locale = 'en'
				localStorage.setItem('language', 'en')
				this.$store.commit('setPosition', 'en_US')
				this.$store.commit('setSuffix', 'en')
			}else if (pathname.indexOf('/ja') != -1) {
				this.$i18n.locale = 'ja'
				localStorage.setItem('language', 'ja')
				this.$store.commit('setPosition', 'ja_JP')
				this.$store.commit('setSuffix', 'ja')
			}else if (pathname.indexOf('/ko') != -1) {
				this.$i18n.locale = 'ko'
				localStorage.setItem('language', 'ko')
				this.$store.commit('setPosition', 'ko_KR')
				this.$store.commit('setSuffix', 'ko')
			}else if (pathname.indexOf('/th') != -1) {
				this.$i18n.locale = 'th'
				localStorage.setItem('language', 'th')
				this.$store.commit('setPosition', 'th_TH')
				this.$store.commit('setSuffix', 'th')
			}else if (pathname.indexOf('/tw') != -1) {
				this.$i18n.locale = 'ct'
				localStorage.setItem('language', 'ct')
				this.$store.commit('setPosition', 'th_TW')
				this.$store.commit('setSuffix', 'tw')
			}else if (pathname.indexOf('/ug') != -1) {
				this.$i18n.locale = 'vi'
				localStorage.setItem('language', 'vi')
				this.$store.commit('setPosition', 'weiwuer')
				this.$store.commit('setSuffix', 'ug')
			}else if (pathname.indexOf('/vn') != -1) {
				this.$i18n.locale = 'vn'
				localStorage.setItem('language', 'vn')
				this.$store.commit('setPosition', 'vn')
				this.$store.commit('setSuffix', 'vn')
			} else {
				this.$http("/ebc/api/getIPInfo/run").then(res => {
					if (res.data.data.country == '日本') {
						this.$i18n.locale = 'ja'
						localStorage.setItem('language', 'ja')
						this.$store.commit('setPosition', 'ja_JP')
					} else if (res.data.data.country == '韩国') {
						this.$i18n.locale = 'ko'
						localStorage.setItem('language', 'ko')
						this.$store.commit('setPosition', 'ko_KR')
					} else if (res.data.data.country == '美国') {
						this.$i18n.locale = 'en'
						localStorage.setItem('language', 'en')
						this.$store.commit('setPosition', 'en_US')
					} else if (res.data.data.country == '泰国') {
						this.$i18n.locale = 'th'
						localStorage.setItem('language', 'th')
						this.$store.commit('setPosition', 'th_TH')
					} else if (res.data.data.country == '越南') {
						this.$i18n.locale = 'vn'
						localStorage.setItem('language', 'vn')
						this.$store.commit('setPosition', 'vn')
					} else if (res.data.data.country == '中国') {
						if (res.data.data.province == '台湾') {
							this.$i18n.locale = 'ct'
							localStorage.setItem('language', 'ct')
							this.$store.commit('setPosition', 'tw')
						} else {
							this.$i18n.locale = 'zh'
							localStorage.setItem('language', 'zh')
							this.$store.commit('setPosition', 'zh_CN')
						}

					}
				})
			}
		},
		methods: {
			reload() {
				this.isRouterAlive = false; //先关闭，
				this.$nextTick(function() {
					this.isRouterAlive = true; //再打开
				})
			}
		},
	}
</script>