export default {
	"headlist": [{
		"text": "首页",
		"url": "/"
	}, {
		"text": "交易",
		"tips": "每个认真交易的人，都值得被认真对待",
		"img": "11",
		"menu": [{
			"tit": "账户&条件",
			"list": [{
				"text": "交易账户",
				"url": "/TradingAccount"
			}, {
				"text": "交易产品",
				"url": "/tradingproduct"
			}, {
				"text": "杠杆与保证金",
				"url": "/leverage-margin"
			}, {
				"text": "出入金",
				"url": "/funding"
			}, {
				"text": "股息",
				"url": "/dividend"
			}]
		}, {
			"tit": "交易工具",
			"list": [{
				"text": "MT4下载",
				"url": "/MT4"
			},{
				"text": "MT5下载",
				"url": "/MT5"
			}, {
				"text": "MT4交易工具",
				"url": "/tools"
			}, {
				"text": "PAMM",
				"url": "/PAMM"
			}]
		}]
	}, {
		"text": "EBC研究院",
		"tips": "",
		"img": "33",
		"menu": [{
			"tit": "",
			"list": [{
				"text": "学习中心",
				"url": "/LearningCenter"
			}, {
				"text": "数据中心",
				"url": "/lab"
			},]
		}]
	}, {
		"text": "最新活动",
		"tips": "",
		"img": "33",
		"menu": [{
			"tit": "",
			"list": [{
				"text": "积分商城",
				"url": "PointsMall"
			}, {
				"text": "百万美金交易大赛",
				"url": "/win"
			}, 
			// {
			// 	"text": "挑战最强交易环境",
			// 	"url": "/no1"
			// },
			 {
				"text": "创作者激励计划",
				"url": "/cfa"
			}, {
				"text": "领取订单流工具",
				"url": "/orderflow"
			}, {
				"text": "交易领薪计划",
				"url": "/pay"
			}]
		}]
	}, {
		"text": "关于EBC",
		"tips": "您可信赖的全球合作伙伴",
		"img": "55",
		"menu": [{
			"tit": "关于EBC",
			"list": [{
				"text": "关于我们",
				"url": "/about-us"
			}, {
				"text": "社会责任",
				"url": "/ESG"
			}, {
				"text": "EBC动态",
				"url": "/LearningCenter?tabId=1"
			}]
		}, {
			"tit": "合作伙伴",
			"list": [{
				"text": "影响力联盟",
				"url": "/affiliate-programmes"
			}, {
				"text": "机构服务",
				"url": "/service"
			}, {
				"text": "底层科技",
				"url": "/technology"
			},
			]
		}]
	}, {
		"text": "跟单社区",
		"url": "/from0tohero"
	}],
	"FCA": "FCA开户",
	"login": "登录",
	"zhuce": "立即开户",
	"from0tohero_text": "跟单社区",
	"from0tohero_url": "/from0tohero",
	"faq_url": "https://support.ebchelp.com/hc/zh-cn",
}

