export default {
  "srceen0": {
    "title": "レバレッジと必要証拠金",
    "subTitle": "取引における一定のルール",
    "desc": "金融市場のボラティリティを考慮して、お客様に安全な取引環境を守るために、EBCが現在提供しているレバレッジは最大500倍までです。",
    "rateTitle": " レバレッジ最高倍率 ",
    "rateNum": "500倍",
    "rateDesc": "証拠金率0.20%"
  },
  "srceen1": {
    "pageEnTitle": "Secure Transaction",
    "pageTitle": "取引銘柄と最大レバレッジ",
    "subTitle": "適切なレバレッジ倍率の選択",
    "desc1": "投資目的とリスク許容度を慎重に検討し、適切なレバレッジを選択してください。",
    "desc2": "下表の各資産の証拠金要件とレバレッジ最高倍率を参照し、より良いリスクマネジメントにお役立てください。",
    "btn": "今すぐ取引"
  }
}
