export default {
  "lang": "ja",
  "screen0": {
    "title": "金融の世界が<br />目の前に拡がる",
    "desc": "There is no Trading Secret!<br>Only to find what works best for you and applying it with strick risk management",
    "quick": "いますぐ見る",
    "quick1": "4つのアセットクラス",
    "quick2": "＋100種類以上の取引",
    "trList": [
      "区分",
      "買付単価",
      "購入数量",
      "スプレッド",
      "約定単価",
      "約定数量"
    ],
    "mobileTrList": [
      "区分",
      "買付単価",
      "スプレッド",
      "約定単価"
    ],
    "btn": "投資をはじめる"
  },
  "screen1": {
    "pageEnTitle": "MT4 Trading Platform",
    "pageTitle": "MT4取引プラットフォーム",
    "desc": "MT4は、小売外国為替取引プラットフォームの業界ベンチマークとして世界で広く認められており、多くのトレーダーから長年にわたり支持されています。オンライン取引プラットフォームの中で最も人気があります。",
    "title": "あらゆるニーズに応える取引プラットフォーム",
    "desc1": "EBCのMT4プラットフォームは、市場とリアルタイムに連動した理想的ともいえるツールです。高度なカスタマイズができ直感的に操作しやすいユーザーインターフェイス、強化されたチャート作成機能、洗練された注文管理、分析、アラートツールが利用できます。"
  },
  "screen2": {
    "num1": "3種類",
    "text1": "さまざまなタイプのチャート",
    "num2": "9種類",
    "text2": "時間枠",
    "num3": "50種",
    "text3": "プリインストールされたテクニカルインジケーター",
    "title": "トレーダーは、3つの異なるチャートタイプ、9つの時間枠、50以上のプリインストールされたテクニカルインジケーター、そして高度なカスタマイズが可能なインターフェースを利用できます。お客様の取引スタイルやシステムを選ばず、効率的なポジション管理と正確な市場モニタリングが簡単にできます。",
    "desc": "MT4をダウンロードすれば、ブラウザやPC/モバイルからいつどこでも世界中の市場にアクセスできます。",
    "btnText": "取引プラットフォームについてもっと詳しく見る",
    "btn": "取引商品について",
    "smallBtn": "Lab 研究センター/取引プラットフォーム部門"
  },
  "screen3": {
    "title": "チャンスに満ちた人生をいますぐはじめよう",
    "desc": " EBCはMetaQuotesと連携し、当社光回線とサーバーを適応させることで、トレーダーに素早い取引の実行とアセットクラスごとの透明性の高い価格を提供します。",
    "link1": "取引時間帯について詳しく見る",
    "link2": "スプレッドと商品すべてを詳しく見る",
    "list": [{
      "title": "さまざまな市場情報をカバー",
      "desc": "為替、株式、株価指数、CFDなど、100以上の銘柄の取引が可能です。"
    }, {
      "title": "いつどこでも市場を確認できる",
      "desc": "MT4はインストールしてのご利用だけでなく、インストール不要で使えるウェブ版MT4にも対応しています。環境が整えばブラウザを使ってどこでもMT4チャートを開けます。Mac環境でもMT4が利用できます。"
    }, {
      "title": "透明性の高い価格をリアルタムにお届け",
      "desc": "取引において0.01秒の差はとても大きいものです。インターバンクやトレーディングシステムから提供されるリアルタイムの相場情報によって、優れた透明性を実現します。"
    }, {
      "title": "最先端のチャートツール",
      "desc": "高度なチャート機能によって、価格判断に基づいたより迅速で正確な投資判断が可能です。"
    }, {
      "title": "ニュース速報",
      "desc": "世界中の資本市場から最新のニュースを素早くお届けします。"
    }, {
      "title": "スピーディな注文約定",
      "desc": "売買注文を高速で約定されます。成行注文であれ、指値注文や逆指値注文であれ、損切にストップロス、利益を確保する際にテイクプロフィットを使用することができます。成行注文、指値注文、逆指値注文、ストップロスオーダー、トレイリングストップあらゆる種類の注文をサポートします。"
    }, {
      "title": "オンラインでのアカウントアクセス",
      "desc": "ポジション、残高、損益情報、ステータスなどがいつどこでも確認できるため、資産状況が一目で分かります。"
    }, {
      "title": "安心・安全",
      "desc": "プラットフォームとサーバー間のデータは最大256bitで暗号化され、お客様に安全で確実な取引環境を提供します。"
    }],
    "btn": "いますぐダウンロード"
  },
  "screen4": {
    "pageEnTitle": "MT4 Trading Platform",
    "pageTitle": "MT4取引プラットフォーム",
    "title": "1つのアカウントで世界のほぼすべての金融商品が取引可能",
    "desc": "MT4プラットフォームの優れた機能",
    "list": [{
        "title": "MT4モバイルアプリ",
        "desc": "MT4モバイルアプリで外出先でもあらゆる取引機会を掴みます"
      },
      {
        "title": "MT4デスクトップアプリ",
        "desc": "受賞歴のある MT4 アプリで、取引経験を新たなレベルに引き上げることができます。 WindowsおよびOS Xオペレーティングシステムの両方と互換性があります。"
      },
      {
        "title": "MT4ウェブトレーダー",
        "desc": "Webベースのアプリを使用すると、ダウンロードせずにすぐにデバイス上で取引できます。 すべてのメインストリーム ブラウザと互換性があります。"
      }
    ],
    "text1": "MT4の取扱説明書を",
    "text2": "ダウンロードすれば",
    "text3": "MT4のすべての機能がより深くご覧いただけます",
    "text4": "世界で最も人気のある取引端末の簡単なインストール方法を説明します",
    "btn": "投資をはじめる"
  },
	"section1": {
		"title": "即時執行によるトレードグローブ",
	},
	"section2": {
		"title": "多様なニーズに対応するトレーディングプラットフォーム",
		"desc": "EBC MetaTrader4 プラットフォームは、直感的でカスタマイズされた UI と、強化されたチャート作成機能および分析ツールを提供します。これにより、市場の変化に対応し、高度な注文管理を実行することができます。",
	},
	"section3": {
		"title": "Meta Trader 4は何でしょうか？",
		"desc1": "MetaTrader 4（MT4）は、MetaQuotes Software Corporationによって開発された世界で最も人気のある取引プラットフォームの1つです。 このプラットフォームは、最先端のテクノロジー、使いやすさ、堅牢なセキュリティのおかげで、世界中のトレーダーに認知され信頼されています。 MT4の主な特徴は、便利な市場分析を容易にし、ユーザーが適時に効果的な出入りをできるようにし、制限および停止注文を下すことができるという点です。",
		"desc2": "EBCのMT4プラットフォームを選択することで、技術指標とEAをカスタマイズできるだけでなく、外国為替、貴金属、原油、株式、指数など多様な取引商品の中から選択できる機会が得られます。"
	},
	"section4": {
		"title": "MT4によくある質問",
		"list": [{
			"title": "MT4をダウンロードした後、ライブアカウントにログインする方法は？",
			"desc": "入金が成功するとアカウントが有効になり、取引アカウント、パスワード、サーバーデータがシステムから提供されます。 必要なのは、指定されたアカウントとパスワードを入力し、正しいサーバを選択してライブ アカウントにログインするだけです。"
		},
		{
			"title": "どうやってMT4のパスワードを変更しますか？",
			"desc": "MT4 プラットフォームにログインしたら、[ツール（Tools）] - [オプション（Options）] をクリックし、サーバーバーでパスワードを変更します。"
		},
		{
			"title": "どうやってMT4のチャートインディケーターを挿入しますか？",
			"desc": "次の手順に従ってください:<br>1. トップ メニューから [Insert] -> [Technical Indicators] を選択します。<br>2. または、「ナビゲーション」メニューから「テクニカルインジケータ」を挿入できます。"
		},
		{
			"title": "トレンドラインまたはその他のダイナミクスの研究を作成する方法",
			"desc": "次の手順に従ってください:<br>1. トップメニューから「Insert」をクリックします。<br>2. 必要なトレンドラインを選択します。<br>3. クリックを解除すると、選択したトレンドラインと一緒に十字線アイコンが表示されます。 チャートの十字線を調整するには、マウスボタンをクリックして押したままにし、それに応じてマウスを移動します。 トレンドラインリサーチには、「参照」->「ツールバー」->「ラインリサーチ」をクリックすると、メニューバーの下にチャートが表示されます。 トレンドライン、クロスヘア、または Fibonacci 再トレースラインをクリックすると、画面上部にあるさまざまなオプションをさらに選択できます。"
		},
		{
			"title": "MT4でユーザー言語を選択するにはどうすればいいですか？",
			"desc": "トップ メニューに移動し、[View] -> [Language] -> [e.g.] を選択します。 簡体字中国語。 その後、アプリを再起動すると、ユーザー言語が簡体字中国語に切り替わります。"
		}
		],
	},
	"btn-text": "MT4をダウンロード",
  "download-btn1": "Download for Android",
	"download-btn2": "Download for iOS",
	"download-btn3": "Download for iPad",
	"download-btn4": "Download on Windows",
	"download-btn5": "Download for MacOS",
	"download-btn6": "WebTrader",
}
