export default {
	"fca1": "FCA 流動性清算帳戶開設流程",
	"fca1-m": "FCA 流動性清算帳戶",
	"fca2": "EBC Financial Group (UK) Ltd 是受英國金融市場行為管理局（FCA）授權並監管的綜合金融券商，所有交易訂單直通國際清算池。 監管編號：927552<br><br>在EBC Financial Group (UK) Ltd開設FCA流動性清算賬戶，有權根據FSCS賠償計劃要求最高85000英鎊的賠償。",
	"fca3": "歐盟《金融工具市場法規（MiFID）》及其後修訂版MiFID II根據客戶的知識水准、經驗和專長將投資者分為「零售投資者」或「專業投資者」等。<br><br>根據FCA法規，EBC Financial Group (UK) Ltd僅接受專業投資者和機構投資者，可開設杠杆為100倍的FCA流動性清算帳戶，投資者資金將獨立託管於巴克萊銀行。",
    "fca4":{
		"title":"專業投資者須滿足以下三個標準中的其中兩個",
		"title-m":"個人投資者須滿足三個標準的其中兩個，即可申請為專業投資者",
		"desc":"過去一年各季度平均交易次數為<br>10次以上",
		"desc1":"持有超過50萬歐元的投資組合，<br>投資組合包括現金儲蓄和金融工具",
		"desc2":"於金融行業擁有至少一年的工作經驗，<br>瞭解差價合約/衍生品交易"
	},
	"fca5":{
		"title":"FCA 流動性清算帳戶開設流程",
		"desc":"申請表<br>下載並填寫",
		"desc1":"証明文件<br>準備和認證",
		"desc2":"以電子郵件<br>遞交資料及申請",
		"desc3":"合規審核<br>和文件簽署",
		"desc4":"成功開立<br>FCA 帳戶及設定",
		"btn":"專業投資者申請表下載",
		"btn1":"機構投資者申請表下載"
	},
	"fca6":{
		"title":"開設 FCA 流動性清算帳戶<br>最權威的監管保護和最安全的資金保障 ",
		"title-m":"FCA帳戶，最權威的監管保護和最安全的資金保障 ",
		"desc":"英國金融市場行為管理局（FCA）",
		"desc1":"FCA 流動性清算帳戶諮詢郵箱："
	},
	"lang":"cn"
}

