export default {
	"malaria-class-name": "en-ubm-box",
	"malaria-page1": {
		"text1": "EBC Financial Group、国連財団のマラリア撲滅キャンペーン「United to Beat Malaria」に参画し、すべての人々の明るい未来のためにマラリア撲滅活動を支援しています。",
		"text2": "",
	},
	"malaria-page2": {
		"text1": "マラリアは世界の子どもたちの健康にとって最も危険な問題のひとつです。",
		"text2": "2022年には、世界85カ国で推定2億4900万人のマラリア患者と60万8000人の死亡者が発生しました。恐ろしいことに、これらの死者の76％以上が5歳未満の子どもたちで占められているのです。みなさんがこれを読んでいる間にも、1分間に約1人の子どもがマラリアで命を落としています。",
		"text3": "2<i>億</i>4900<i>万</i>",
		"text4": "感染者数",
		"text5": "60<i>万</i>8000<i>人</i>",
		"text6": "死亡者数",
		"text7": "76%<i>以上</i>",
		"text8": "幼児",
		"text9": "*出典：世界保健機関（WHO）「世界マラリア報告書2023」。",
	},
	"malaria-page3": {
		"text1": "マラリアを撲滅するための世代になりましょう",
		"text2": "国連財団が主導するマラリア撲滅キャンペーン「United to Beat Malaria」は、マラリア撲滅のために世界中の支援者を巻き込んだ世界的な取り組みです。<br>EBCは、この深刻な問題に立ち向かうため、マラリア撲滅キャンペーンに参画することで、企業の社会的責任を果たそうとしています。",
		"text3": "60",
		"text4": "参加国",
		"text5": "4000<i>万人</i>",
		"text6": "保護された人々",
		"text7": "183,450",
		"text8": "防虫処理した蚊帳を寄贈",
	},
	"malaria-page4": {
		"text1": "ムーブ・アゲインスト・マラリア5kmバーチャル・ラン2024に参加して、マラリアと闘うグローバル・コミュニティに参加しませんか？",
		"text2": "2024年4月25日から5月5日の間、マラリア撲滅チャリティ・ランに参加し、命を救うマラリア治療と治療プログラムの開発のための意識向上と寄付を募ります。EBC Financial Groupと国連財団は、私たちの目指す未来をともに願う方々を歓迎し、すべての人々のために、より健やかな世界を築くため、共に手を携えていただけるよう呼びかけます。",
		"text3": "今すぐ参加する",
	},
	"malaria-page5": {
		"text1": "今までの活動状況",
	},
}