export default {
	"screen0": {
		"enTitle": "Your Trust, Our Protection",
		"title": "Chúng tôi coi bảo mật trực tuyến là ưu tiên hàng đầu của mình.",
		"text": "在EBC，我们提供了一系列安全、即时和易于操作的出入金方式。您可以随时提取您的获利或初始投资。<br /><br />为确保您的账户得到充分的保护，我们对在线支付实施了行业高级别的加密，并采取了额外的验证措施，全方位保障您在EBC上的所有资金均安全无忧。",
		"rightText": "有机会驾驭的人生，<span>即刻启程。</span>"
	},
	"screen1": {
		"smallEnTitle": "Multiple Deposit Methods",
		"smallTitle": "多种入金方式",
		"title": "我们支持借记卡，电汇，PayPal 等多种入金方式",
		"desc": "为帮助确保您拥有更多的剩余资金用于交易， EBC对于您从交易账户注入资金或从该账户提取资金不收取任何费用",
		"list": ["入金方式", "最低入金", "入金到账时间", "最低出金", "支付货币", "手续费", "出金处理时间"],
		"summary": "说明：",
		"btn": "开始入金",
		"summaryDesc": "<p>1. 具体到账时间取决于各银行、中间机构的办理情况</p><p>2. 具体限额可能因客户银行、发卡行规定而各有不同 </p><p>3. 请注意，如果您在提交出金申请时，您的账户持有仓位，请留意您提取的金额大小，以免影响您的持仓。</p>",
		"imgUrl": "zh"
	},
	"screen2": {
		"title": "成为全球百万交易者之一",
		"desc": "仅需轻松3步，享受极速安全的开户体验。",
		"list": [{
			"title": "注册账户",
			"desc": "提交基本信息，安全简单的网上申请页面"
		}, {
			"title": "提交个人信息",
			"desc": "只需1分钟轻松完成开户填写"
		}, {
			"title": "入金激活",
			"desc": "最低入金200美金实时激活"
		}],
		"btn1": "Dùng thử bản demo miễn phí",
		"btn2": "Mở một tài khoản thực",
		"yuyan": "vi"
	},
	"screen3": {
		"title": "Điều kiện gửi và rút tiền",
		"faqList": [{
			"title": "Làm cách nào để gửi tiền vào tài khoản của tôi?",
			"desc": "Sau khi tạo tài khoản, bạn có thể truy cập tùy chọn thanh toán của mình thông qua Khu vực khách hàng của chúng tôi. <br><br>Quy trình cụ thể: <br><br>Bạn chỉ cần đăng nhập vào Khu vực khách hàng của mình và nhấp vào nút “Nạp tiền” màu xanh lục ở bên trái giao diện. <br>Bước 1: Chọn tài khoản bạn muốn gửi tiền vào theo lời nhắc của hệ thống. <br><br>Bước 2: Sau đó chọn phương thức thanh toán bằng nút bên phải bảng phương thức thanh toán. <br><br>Bước 3: Nêu số tiền bạn muốn gửi vào tài khoản của mình. <br><br>Hệ thống có thể nhắc bạn nhập loại tiền gửi mà bạn muốn sử dụng hoặc các tùy chọn khác. Tài khoản EBC cung cấp cho bạn nhiều tùy chọn khác nhau cho các loại tiền tệ cơ bản, bao gồm RMB, USD, GBP và EUR được sử dụng phổ biến nhất. <br><br>Vui lòng đọc kỹ tất cả các hướng dẫn, điều khoản và điều kiện và nếu bạn đồng ý, vui lòng đảm bảo rằng bạn đánh dấu vào ô có nội dung “Tôi đã đọc tất cả các hướng dẫn và đồng ý với các điều khoản và điều kiện của hoạt động thanh toán.”<br> br><br>【Nhấp Gửi】"
		}, {
			"title": "Tại sao khoản tiền gửi ghi nợ của tôi không thành công?",
			"desc": "Nếu bạn không thể gửi tiền bằng thẻ ghi nợ của mình, vui lòng thử gửi tiền lại, đồng thời kiểm tra xem: <br><br>– Bạn đã nhập thông tin thẻ của mình chính xác chưa.<br> <br>– Cho dù bạn đang sử dụng thẻ còn hiệu lực (chưa hết hạn). <br><br>– Cho dù bạn có đủ tiền trong thẻ của mình hay không.<br><br>– Nếu không có vấn đề nào nêu trên mà bạn vẫn không thể gửi tiền, điều đó có thể có nghĩa là ngân hàng phát hành thẻ của bạn không cho phép thanh toán từ thẻ của bạn.<br><br>Trong trường hợp đó, vui lòng sử dụng thẻ khác hoặc bất kỳ phương thức thanh toán nào khác có sẵn trong tài khoản giao dịch của bạn. "
		}, {
			"title": "Làm cách nào để rút tiền từ tài khoản của tôi?",
			"desc": "Bạn có thể thực hiện yêu cầu Rút Tiền bất cứ lúc nào. Bộ phận của chúng tôi sẽ xử lý các yêu cầu vào mỗi ngày. Việc Rút Tiền sẽ được chuyển vào tài khoản chỉ định của bạn. Nếu tuỳ chọn này không khả dụng, tiền sẽ được tự động hoàn lại vào Ví của bạn. Ngoài ra, vui lòng lưu ý, đối với việc Rút Tiền bằng chuyển khoản ngân hàng, cần phải tham khảo thời gian xử lý thực tế của từng ngân hàng. Thời gian của mỗi ngân hàng là khác nhau, vui lòng liên hệ ngân hàng để biết thêm chi tiết.<br>Nếu bạn Rút Tiền trong khi đang giữ một vị thế, vui lòng đảm bảo rằng tỷ lệ trả trước trong tài khoản giao dịch cao hơn 200% sau khi trừ số tiền rút, nếu không nền tảng sẽ không thể xử lý yêu cầu rút tiền của bạn."
		}, {
			"title": "EBC có tính phí chuyển tiền không?",
			"desc": "EBC không tính bất kỳ khoản phí nào khi sử dụng các phương thức gửi và rút tiền của chúng tôi, nhưng một số chi phí có thể xảy ra khi bạn thanh toán cho hoặc từ các tổ chức ngân hàng quốc tế nhất định, điều này nằm ngoài tầm kiểm soát của chúng tôi. Ngoài ra, vui lòng lưu ý rằng ngân hàng của bạn có thể coi khoản thanh toán cho EBC là khoản ứng trước tiền mặt và có thể lập hóa đơn cho bạn theo các quy tắc thanh toán trước bằng tiền mặt của bạn."
		}, {
			"title": "Tôi có thể sử dụng thẻ ngân hàng của người khác để nạp/rút tiền không?",
			"desc": "Không. EBC không chấp nhận thanh toán của bên thứ ba. Vui lòng đảm bảo rằng tất cả các khoản tiền gửi vào tài khoản giao dịch của bạn là từ tài khoản của chính bạn. Nếu chủ tài khoản giao dịch là một bên của tài khoản chung, thanh toán từ tài khoản chung sẽ được chấp nhận. <br><br>Nếu chúng tôi nghi ngờ rằng tiền được cung cấp bởi bất kỳ bên thứ ba nào, chúng tôi sẽ bảo lưu quyền hoàn trả tiền của người chuyển tiền, đóng băng số dư tài khoản của bạn và đình chỉ đơn đăng ký của bạn sau khi danh tính và nguồn tiền được xác minh. Chúng tôi sẽ bảo lưu quyền đóng băng và bạn sẽ không được phép rút tiền từ số dư tài khoản của mình trước khi xác minh. <br><br>EBC không chấp nhận rút tiền vào tài khoản ngân hàng của bên thứ ba. Nếu có bất kỳ tình huống đặc biệt nào, vui lòng liên hệ với chúng tôi."
		}]
	},
	"funding1":{
		"text1": "Nạp rút tiền",
		"text2": "Bảo mật trực tuyến và phòng chống gian lận là ưu tiên hàng đầu của chúng tôi",
		"btn": "Nạp tiền ngay",
	},
	"funding2":{
		"text1": "Giải pháp tài trợ đa tiền tệ toàn cầu",
		"text2": "Cung cấp dịch vụ nạp và rút tiền tức thì bằng các loại tiền tệ chính trên toàn cầu, đơn giản hóa quy trình giao dịch quốc tế và tiền tệ chéo, đồng thời đạt được dòng vốn thuận tiện, phù hợp với mọi thời tiết",
	},
	"funding3":{
		"text1": "Cách ly quỹ, giữ độc lập",
		"text2": "Tài khoản ngân hàng doanh nghiệp cấp cao nhất của Ngân hàng Barclays",
		"text3": "EBC có tài khoản doanh nghiệp cấp cao nhất của Ngân hàng Barclays, yêu cầu doanh thu và tiền gửi của công ty phải đạt hơn 6,5 triệu bảng Anh, đồng thời phải vượt qua một loạt kiểm toán tài chính và kiểm tra lý lịch nghiêm ngặt của Barclays. EBC UK thực hiện nghiêm ngặt các quy định của CASS và quản lý độc lập quỹ của nhà đầu tư thông qua các thư ủy thác cụ thể để đảm bảo sự an toàn và độc lập của quỹ.",
		"btn": "Tìm hiểu thêm",
	},
	"funding4":{
		"text1": "Bảo hiểm trách nhiệm tài chính chuyên nghiệp",
		"text2": "Nhiều loại bảo hiểm trách nhiệm tài chính chuyên nghiệp quốc tế bảo vệ toàn diện an toàn cho tiền của khách hàng và tránh tổn thất tài chính một cách hiệu quả.",
	},
	"funding5":{
		"text1": "Phương thức nạp và rút tiền",
		"text2": "Hỗ trợ chuyển khoản ngân hàng nội địa, chuyển khoản ngân hàng quốc tế, ví điện tử, tiền điện tử cùng các phương thức nạp và rút tiền khác",
		"text3": "Phương thức nạp và rút tiền",
		"text4": "Tiền tệ được hỗ trợ",
		"text5": "Nạp tối thiểu",
		"text6": "Thời gian xử lý nạp tiền",
		"text7": "Thời gian xử lý rút tiền",
		"text8": "Ngân hàng địa phương",
		"text9": "Ngay lập tức",
		"text10": "1 ngày làm việc",
		"text11": "Chuyển khoản quốc tế",
		"text12": "Vô hạn",
		"text13": "2-5 ngày làm việc",
		"text14": "Ví điện tử",
		"text15": "Tiền tệ kỹ thuật số",
	}
}