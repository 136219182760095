export default {
    "box-name": "en-style2",
    "public-btn-text1": "สมัครตอนนี้",
    "public-btn-text2": "สินค้าทั้งหมด",
    screen0: {
        "title": "เงินปันผลจาก CFD",
        "desc": "โปรดทราบ: การรวบรวมเงินปันผล CFD จะแสดงในยอดคงเหลือใน MT4 ของคุณเป็นการฝากหรือถอน โดยมีหมายเหตุว่า “เงินปันผล”",
    },
    screen1: {
        "title": "ธุรกรรมที่ปลอดภัย",
    },
    screen2: {
        "title": "โลกการเงินอยู่ใกล้แค่เอื้อม",
    }
}