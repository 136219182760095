export default {
	"screen0": {
		"title": "<span>We Value Your Safety<br>Regulated, safe and stable</span>",
	},
	"screen1": {
		"link1": "Overview",
		"link2": "Regulation",
		"link3": "Honour",
		"link4": "Advantage",
		"link5": "Culture",
		"link6": "Partner",
		"link7": "Contact Us",

	},
	"screen2": {
		"title": "Overview",
		"desc1": "EBC Financial Group is a financial service provider, founded in London and guided by industry veterans with over three decades of experience in financial market operation, specialising in online trading, asset management and immigrant investing consultation with operational offices in major financial hubs including Tokyo, Sydney, Singapore and Hong Kong",
		"desc2": "Asset safety is EBC'S foremost priority. In addition to the world's most strict regulation, EBC boasts  account with Barclays, insured amount of over $10,000,000 and CASS compliance. Thus risk factors are offset for customers by financial strength and risk management.",
		"desc3": "Exceptional Brilliant Care for Every Committed Trader"
	},
	"screen3": {
		"title": "Top-Tier Regulation",
		"desc": "EBC Financial Group's subsidiaries are regulated and licensed in their local jurisdictions.",
		"desc-l1": "EBC Financial Group (UK) Ltd",
		"desc-l2": "authorised and regulated by the FCA",
		"desc-l3": "Reference Number : 927552",
		"desc-r1": "EBC Financial Group (Australia) Pty Ltd",
		"desc-r2": "authorised and regulated by the ASIC",
		"desc-r3": "Reference Number : 500991",
		"desc-rr1": "EBC Financial Group (Cayman) Ltd",
		"desc-rr2": "Authorised and regulated by the CIMA",
		"desc-rr3": "Reference Number : 2038223",
	},
	"screen4": {
		"title": "Honour",
		"desc1": "",
		"desc2": "",
		"desc3": "",
		"desc4": "",
		"desc5": "",
		"desc6": "",
		"desc7": "",
		"desc8": "",
	},
	"screen5": {
		"title": "Advantage",
		"list": [
			{
				"title": "1000",
				"desc": "Orders processed per second"
			},
			{
				"title": "<20<i>MS</i>",
				"desc": "Average execution speed"
			},
			{
				"title": "98<i>.75%</i>",
				"desc": "Data transmission stability uptime"
			},
			{
				"title": "25<i>+</i>",
				"desc": "Acess to top investment banks and hedge funds"
			},
			{
				"title": "7*24",
				"desc": "VIP service"
			},
			{
				"title": "0.0<i>PIPS",
				"desc": "Interbank-level RAW ECN Spreads"
			},
		]
	},
	"screen6": {
		"title": "Culture",
		"desc": "Our core value is to proactively create the full range of「Real Value」for our clients.",
		"list": [
			{
				"title": "Safe",
				"desc": "FCA and ASIC regulation. Barclays' assurance of fund safety."
			},
			{
				"title": "Fairness",
				"desc": "Take every customer seriously in the principle of integrity and respect"
			},
			{
				"title": "Dedication",
				"desc": "Service exellence through details in line with EBC's core value"
			},
			{
				"title": "Value",
				"desc": "Safe, professional and stable wealth building"
			},
			{
				"title": "Integrity",
				"desc": "Exceptional trading experience driven by integrity"
			},
			{
				"title": "Responsibility",
				"desc": "Make the world better by sharing"
			},
		]
	},
	"screen7": {
		"title": "Partner",
	},
	"screen8": {
		"title": "Contact Us",
		"desc1": "Cooperation",
		"desc2": "Join Us",
		"desc3": "Feedback and complaints",
	},
}

