export default {
    "box-name": "en-style2",
    "public-btn-text1": "Đăng ký ngay",
    "public-btn-text2": "Tất cả sản phẩm",
    screen0: {
        "title": "Cổ tức CFD",
        "desc": "Xin lưu ý'việc tổng hợp cổ tức CFD sẽ được phản ánh trong số dư trên tài khoản MT4 của bạn dưới dạng tiền gửi hoặc rút tiền, được chú thích là \"Cổ tức\".",
    },
    screen1: {
        "title": "Giao dịch an toàn",
    },
    screen2: {
        "title": "Thế giới tài chính nằm trong tầm tay bạn",
    }
}