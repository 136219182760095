export default {
	"fca1": "เปิดบัญชี FCA",
	"fca1-m": "เปิดบัญชี FCA",
	"fca2": "EBC Financial Group (UK) Ltd ที่ก่อตั้งขึ้นในประเทศอังกฤษ ได้รับการอนุญาตและควบคุมโดย Financial Conduct Authority หมายเลขอ้างอิง: 927552",
	"fca3": "ตามกฎของ Financial Conduct Authority (FCA) ที่เกี่ยวกับการจัดประเภทลูกค้า เราต้องจัดประเภทลูกค้าของเราเป็นลูกค้ามืออาชีพ ลูกค้ารายย่อย หรือคู่สัญญาที่มีคุณสมบัติที่เหมาะสม<br><br>ตามที่ บริษัท FCA อนุญาตให้ EBC Financial Group (UK) Ltd ให้บริการเฉพาะลูกค้ามืออาชีพหรือคู่สัญญาที่ได้รับสิทธิ์ที่เหมาะสม ดังนั้นถ้าคุณมีคุณสมบัติประเภทใดประเภทหนึ่งเหล่านี้ คุณอาจสามารถเปิดบัญชีด้วยเลเวอเรจ 100x และเงินของคุณจะถูกเก็บไว้ในบัญชี EBC Financial Group (UK) Ltd กับ Barclays Bank PLC",
    "fca4":{
		"title":"นักลงทุนมืออาชีพต้องมีคุณสมบัติตรงตามข้อกําหนดสองข้อต่อไปนี้:",
		"title-m":"นักลงทุนมืออาชีพต้องมีคุณสมบัติตรงตามข้อกําหนดสองข้อต่อไปนี้:",
		"desc":"ลูกค้าได้ดําเนินการอย่างมีนัยสําคัญในตลาดที่เกี่ยวข้องเฉลี่ย 10 รายการขึ้นไปต่อไตรมาสในปีที่ผ่านมา",
		"desc1":"ถือพอร์ตการลงทุนที่มีมูลค่ามากกว่าครึ่งล้านยูโร รวมไปถึงการฝากเงินสดและเครื่องมือทางการเงิน",
		"desc2":"มีประสบการณ์การทํางานในอุตสาหกรรมการเงินอย่างน้อย 1 ปี และมีความรู้ด้านการซื้อขาย CFD/อนุพันธ์เป็นอย่างดี"
	},
	"fca5":{
		"title":"ขั้นตอนการเปิดบัญชี FCA",
		"desc":"ดาวน์โหลดและกรอกแบบฟอร์มใบสมัคร",
		"desc1":"จัดเตรียมและลงชื่อรับรองเอกสาร",
		"desc2":"ส่งเอกสารและสมัครทางอีเมลที่ EBC Financial Group (UK) Ltd",
		"desc3":"การประเมินการปฏิบัติตามข้อกําหนดใบสมัครและเอกสารสําหรับการลงนามจะถูกส่งหากผู้สมัครมีคุณสมบัติเหมาะสม",
		"desc4":"เมื่อลงชื่อเสร็จสมบูรณ์ บัญชีจะได้รับการอนุมัติ",
		"btn":"application form for professional clients",
		"btn1":"แบบฟอร์มใบสมัครสําหรับลูกค้ามืออาชีพ"
	},
	"fca6":{
		"title":"เปิดบัญชีที่ได้รับการดูแลโดย FCA กับ EBC Financial Group (UK) Ltd และได้รับความสะดวกสบายจากกฎระเบียบชั้นนําและความปลอดภัยของกองทุน",
		"title-m":"เปิดบัญชีที่ได้รับการดูแลโดย FCA กับ EBC Financial Group (UK) Ltd และได้รับความสะดวกสบายจากกฎระเบียบชั้นนําและความปลอดภัยของกองทุน",
		"desc":"หน่วยงานกํากับดูแลทางการเงิน (FCA)",
		"desc1":"อีเมลล์: "
	},
	"lang":"en"
}

