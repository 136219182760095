export default {
	"box-name": "DiNapoli-page-en",
	"section1": {
		"text1": "Joe<br> DiNapoli",
		"text2": "<li>フィボナッチのゴッドファーザー</li><li>DiNapoli Levels創設者</li><li>FibNodesソフトウェアの開発者</li>",
		"text3": "ディナポリ氏は55年以上の取引経験を持つベテラントレーダーです。この長い取引歴の中には、1960年代のニューヨーク取引所におけるNifty 50の取引や、1987年のブラックマンデー、911直後の先物取引などが注目されます。1982年にS&P先物がオープン以来、S&P先物取引に注力しました。同氏は先行移動平均線（DMA）や、フィボナッチ分析、オシレーターやMACDの研究でよく知られており、特にLeading Indicatorsの開発により一躍有名になりました。商品投資顧問として25年以上世界中で講演を行い著書も多数執筆しました。なかでも「ディナポリの秘数フィボナッチ売買法 : 押し・戻り分析で仕掛けから手仕舞いまでわかる」は13言語に翻訳されておりさらに他の言語にも翻訳される予定です。",
		"text4": "ディナポリの秘数フィボナッチ売買法",
		"text5": "「ディナポリの秘数フィボナッチ売買法 : 押し・戻り分析で仕掛けから手仕舞いまでわかる」はフィボナッチ分析を価格変動に適用する方法について述べられており、最も実践的な書籍と評価されています。トレーダーにとって有益な知見が含まれており、取引戦略の精度向上に寄与することでしょう。",
		"text6": "<li>黄金比率投資取引について最も権威のある著書</li><li>「ディナポリの秘数フィボナッチ売買法」はその優れた実績から、史上最高の取引書籍の１つとしてよく知られています</li><li>世界で13の言語に翻訳されています</li>",
		"text7": "無料でゲット",
		"text8": "ディナポリMT4/MT5インジケーター",
		"text9": "効率的にトレンドを把握し、サポートとレジスタンスレベルを正確に把握できることから、世界中のトレーダーがディナポリ氏の先進的なインジケーターを愛用しています。様々なテクニカル分析を組合わせることにより、トレーダーの精緻な判断をサポートし、取引の精度を向上させます。",
		"text10": "トレンドの正確な把握",
		"text11": "先行移動平均線（DMA）により、マーケットトレンドをタイムリーに把握",
		"text12": "相場の過熱をいち早く察知",
		"text13": "オシレーター系により、マーケットのターニングポイントを的確に把握",
		"text14": "サポートとレジスタンスレベルの把握",
		"text15": "フィボナッチ分析をベースにトレーディング戦略を立案",
		"text16": "プロフェッショナルトレーダーからの好評",
		"text17": "過去何年にもわたって私が知っている、本当にお金を稼いだ優秀なトレーダーは皆、このコースを受講しました。",
		"text18": "イリノイ州Watseka 社長、RSIインジケーターの創設者",
		"text19": "Y経験を買うことはできませんが、ディナポリ氏の本を読むことにより、高い授業料を節約できます。",
		"text20": "Group創設者、30年以上の経験を持つマーケットエキスパート、取引スペシャリスト",
		"text21": "「ディナポリの秘数フィボナッチ売買法」に書かれている手法は実践的で上昇、下落相場の両方に適用できます。初心者のトレーダーは学習時間を数年間も短縮することができ、経験豊富なトレーダーも新しい方法を学ぶことができます。",
		"text22": "LBR Group 優秀トレーダー、作家、教育者",
		"text23": "ディナポリレベルについてはこちら",
		"text24": "ディナポリレベル及びディナポリエキスパートについてはこちら",
		"text25": "詳しくはこちら",
	},
}