import Vue from "vue";
import VueRouter from "vue-router";
import Home from "../views/index.vue";

Vue.use(VueRouter);

const routes = [{
		path: "/",
		name: "Home",
		component: Home,
	},{
		path: "/cn",
		name: "Home-cn",
		component: Home,
	},{
		path: "/en",
		name: "Home-en",
		component:Home,
	},{
		path: "/ko",
		name: "Home-ko",
		component: Home,
	},{
		path: "/ja",
		name: "Home-ja",
		component: Home,
	},{
		path: "/ug",
		name: "Home-ug",
		component: Home,
	},{
		path: "/tw",
		name: "Home-tw",
		component: Home,
	},{
		path: "/th",
		name: "Home-th",
		component: Home,
	},{
		path: "/vn",
		name: "Home-vn",
		component: Home,
	},
	{
		path: "/cfa",
		name: "cfa",
		component: () =>
			import( /* webpackChunkName: "about" */ "../views/cfa.vue"),
	},
	{
		path: "/cn/cfa",
		name: "cfa-cn",
		component: () =>
			import( /* webpackChunkName: "about" */ "../views/cfa.vue"),
	},{
		path: "/en/cfa",
		name: "cfa-en",
		component: () =>
			import( /* webpackChunkName: "about" */ "../views/cfa.vue"),
	},{
		path: "/ko/cfa",
		name: "cfa-ko",
		component: () =>
			import( /* webpackChunkName: "about" */ "../views/cfa.vue"),
	},{
		path: "/ja/cfa",
		name: "cfa-ja",
		component: () =>
			import( /* webpackChunkName: "about" */ "../views/cfa.vue"),
	},
	{
		path: "/ug/cfa",
		name: "cfa-ug",
		component: () =>
			import( /* webpackChunkName: "about" */ "../views/cfa.vue"),
	},{
		path: "/tw/cfa",
		name: "cfa-tw",
		component: () =>
			import( /* webpackChunkName: "about" */ "../views/cfa.vue"),
	},{
		path: "/th/cfa",
		name: "cfa-th",
		component: () =>
			import( /* webpackChunkName: "about" */ "../views/cfa.vue"),
	},
	{
		path: "/MT4",
		name: "MT4",
		component: () =>
			import( /* webpackChunkName: "about" */ "../views/MT4.vue"),
	},
	{
		path: "/cn/MT4",
		name: "MT4-cn",
		component: () =>
			import( /* webpackChunkName: "about" */ "../views/MT4.vue"),
	},
	{
		path: "/en/MT4",
		name: "MT4-en",
		component: () =>
			import( /* webpackChunkName: "about" */ "../views/MT4.vue"),
	},{
		path: "/ko/MT4",
		name: "MT4-ko",
		component: () =>
			import( /* webpackChunkName: "about" */ "../views/MT4.vue"),
	},{
		path: "/ja/MT4",
		name: "MT4-ja",
		component: () =>
			import( /* webpackChunkName: "about" */ "../views/MT4.vue"),
	},{
		path: "/ug/MT4",
		name: "MT4-ug",
		component: () =>
			import( /* webpackChunkName: "about" */ "../views/MT4.vue"),
	},{
		path: "/tw/MT4",
		name: "MT4-tw",
		component: () =>
			import( /* webpackChunkName: "about" */ "../views/MT4.vue"),
	},{
		path: "/th/MT4",
		name: "MT4-th",
		component: () =>
			import( /* webpackChunkName: "about" */ "../views/MT4.vue"),
	},{
		path: "/vn/MT4",
		name: "MT4-vn",
		component: () =>
			import( /* webpackChunkName: "about" */ "../views/MT4.vue"),
	},
	{
		path: "/MT5",
		name: "MT5",
		component: () =>
			import( /* webpackChunkName: "about" */ "../views/MT5.vue"),
	},
	{
		path: "/cn/MT5",
		name: "MT5-cn",
		component: () =>
			import( /* webpackChunkName: "about" */ "../views/MT5.vue"),
	},
	{
		path: "/en/MT5",
		name: "MT5-en",
		component: () =>
			import( /* webpackChunkName: "about" */ "../views/MT5.vue"),
	},{
		path: "/ko/MT5",
		name: "MT5-ko",
		component: () =>
			import( /* webpackChunkName: "about" */ "../views/MT5.vue"),
	},{
		path: "/ja/MT5",
		name: "MT5-ja",
		component: () =>
			import( /* webpackChunkName: "about" */ "../views/MT5.vue"),
	},{
		path: "/ug/MT5",
		name: "MT5-ug",
		component: () =>
			import( /* webpackChunkName: "about" */ "../views/MT5.vue"),
	},{
		path: "/tw/MT5",
		name: "MT5-tw",
		component: () =>
			import( /* webpackChunkName: "about" */ "../views/MT5.vue"),
	},{
		path: "/th/MT5",
		name: "MT5-th",
		component: () =>
			import( /* webpackChunkName: "about" */ "../views/MT5.vue"),
	},{
		path: "/vn/MT5",
		name: "MT5-vn",
		component: () =>
			import( /* webpackChunkName: "about" */ "../views/MT5.vue"),
	}
	,{
		path: "/FCA",
		name: "FCA",
		component: () =>
			import( /* webpackChunkName: "about" */ "../views/FCA.vue"),
	},
	{
		path: "/cn/FCA",
		name: "FCA-cn",
		component: () =>
			import( /* webpackChunkName: "about" */ "../views/FCA.vue"),
	},
	{
		path: "/en/FCA",
		name: "FCA-en",
		component: () =>
			import( /* webpackChunkName: "about" */ "../views/FCA.vue"),
	},{
		path: "/ko/FCA",
		name: "FCA-ko",
		component: () =>
			import( /* webpackChunkName: "about" */ "../views/FCA.vue"),
	},{
		path: "/ja/FCA",
		name: "FCA-ja",
		component: () =>
			import( /* webpackChunkName: "about" */ "../views/FCA.vue"),
	},{
		path: "/ug/FCA",
		name: "FCA-ug",
		component: () =>
			import( /* webpackChunkName: "about" */ "../views/FCA.vue"),
	},{
		path: "/tw/FCA",
		name: "FCA-tw",
		component: () =>
			import( /* webpackChunkName: "about" */ "../views/FCA.vue"),
	},{
		path: "/th/FCA",
		name: "FCA-th",
		component: () =>
			import( /* webpackChunkName: "about" */ "../views/FCA.vue"),
	},{
		path: "/vn/FCA",
		name: "FCA-vn",
		component: () =>
			import( /* webpackChunkName: "about" */ "../views/FCA.vue"),
	},
	{
		path: "/cn/funding",
		name: "funding-cn",
		component: () =>
			import( /* webpackChunkName: "about" */ "../views/funding.vue"),
	},{
		path: "/en/funding",
		name: "funding-en",
		component: () =>
			import( /* webpackChunkName: "about" */ "../views/funding.vue"),
	},{
		path: "/ko/funding",
		name: "funding-ko",
		component: () =>
			import( /* webpackChunkName: "about" */ "../views/funding.vue"),
	},{
		path: "/ja/funding",
		name: "funding-ja",
		component: () =>
			import( /* webpackChunkName: "about" */ "../views/funding.vue"),
	},{
		path: "/th/funding",
		name: "funding-th",
		component: () =>
			import( /* webpackChunkName: "about" */ "../views/funding.vue"),
	},{
		path: "/tw/funding",
		name: "funding-tw",
		component: () =>
			import( /* webpackChunkName: "about" */ "../views/funding.vue"),
	},{
		path: "/ug/funding",
		name: "funding-ug",
		component: () =>
			import( /* webpackChunkName: "about" */ "../views/funding.vue"),
	},{
		path: "/vn/funding",
		name: "funding-vn",
		component: () =>
			import( /* webpackChunkName: "about" */ "../views/funding.vue"),
	},{
		path: "/funding",
		name: "funding",
		component: () =>
			import( /* webpackChunkName: "about" */ "../views/funding.vue"),
	},
	{
		path: "/leverage-margin",
		name: "leverage-margin",
		component: () =>
			import( /* webpackChunkName: "about" */ "../views/leverage-margin.vue"),
	},{
		path: "/cn/leverage-margin",
		name: "leverage-margin-cn",
		component: () =>
			import( /* webpackChunkName: "about" */ "../views/leverage-margin.vue"),
	},{
		path: "/en/leverage-margin",
		name: "leverage-margin-en",
		component: () =>
			import( /* webpackChunkName: "about" */ "../views/leverage-margin.vue"),
	},{
		path: "/ko/leverage-margin",
		name: "leverage-margin-ko",
		component: () =>
			import( /* webpackChunkName: "about" */ "../views/leverage-margin.vue"),
	},{
		path: "/ja/leverage-margin",
		name: "leverage-margin-ja",
		component: () =>
			import( /* webpackChunkName: "about" */ "../views/leverage-margin.vue"),
	},{
		path: "/th/leverage-margin",
		name: "leverage-margin-th",
		component: () =>
			import( /* webpackChunkName: "about" */ "../views/leverage-margin.vue"),
	},{
		path: "/ug/leverage-margin",
		name: "leverage-margin-ug",
		component: () =>
			import( /* webpackChunkName: "about" */ "../views/leverage-margin.vue"),
	},{
		path: "/tw/leverage-margin",
		name: "leverage-margin-tw",
		component: () =>
			import( /* webpackChunkName: "about" */ "../views/leverage-margin.vue"),
	},{
		path: "/vn/leverage-margin",
		name: "leverage-margin-vn",
		component: () =>
			import( /* webpackChunkName: "about" */ "../views/leverage-margin.vue"),
	},{
		path: "/from0tohero1",
		name: "from0tohero1",
		component: () =>
			import( /* webpackChunkName: "about" */ "../views/from0tohero1.vue"),
	},
	{
		path: "/from0tohero",
		name: "from0tohero",
		component: () =>
			import( /* webpackChunkName: "about" */ "../views/from0tohero.vue"),
	},{
		path: "/cn/from0tohero",
		name: "from0tohero-cn",
		component: () =>
			import( /* webpackChunkName: "about" */ "../views/from0tohero.vue"),
	},{
		path: "/en/from0tohero",
		name: "from0tohero-en",
		component: () =>
			import( /* webpackChunkName: "about" */ "../views/from0tohero.vue"),
	},{
		path: "/ko/from0tohero",
		name: "from0tohero-ko",
		component: () =>
			import( /* webpackChunkName: "about" */ "../views/from0tohero.vue"),
	},{
		path: "/ja/from0tohero",
		name: "from0tohero-ja",
		component: () =>
			import( /* webpackChunkName: "about" */ "../views/from0tohero.vue"),
	},{
		path: "/th/from0tohero",
		name: "from0tohero-th",
		component: () =>
			import( /* webpackChunkName: "about" */ "../views/from0tohero.vue"),
	},{
		path: "/tw/from0tohero",
		name: "from0tohero-tw",
		component: () =>
			import( /* webpackChunkName: "about" */ "../views/from0tohero.vue"),
	},{
		path: "/ug/from0tohero",
		name: "from0tohero-ug",
		component: () =>
			import( /* webpackChunkName: "about" */ "../views/from0tohero.vue"),
	},{
		path: "/vn/from0tohero",
		name: "from0tohero-vn",
		component: () =>
			import( /* webpackChunkName: "about" */ "../views/from0tohero.vue"),
	},
	{
		path: "/PAMM",
		name: "PAMM",
		component: () =>
			import( /* webpackChunkName: "about" */ "../views/PAMM.vue"),
	},{
		path: "/cn/PAMM",
		name: "PAMM-cn",
		component: () =>
			import( /* webpackChunkName: "about" */ "../views/PAMM.vue"),
	},{
		path: "/en/PAMM",
		name: "PAMM-en",
		component: () =>
			import( /* webpackChunkName: "about" */ "../views/PAMM.vue"),
	},{
		path: "/ko/PAMM",
		name: "PAMM-ko",
		component: () =>
			import( /* webpackChunkName: "about" */ "../views/PAMM.vue"),
	},{
		path: "/ja/PAMM",
		name: "PAMM-ja",
		component: () =>
			import( /* webpackChunkName: "about" */ "../views/PAMM.vue"),
	},{
		path: "/th/PAMM",
		name: "PAMM-th",
		component: () =>
			import( /* webpackChunkName: "about" */ "../views/PAMM.vue"),
	},{
		path: "/tw/PAMM",
		name: "PAMM-tw",
		component: () =>
			import( /* webpackChunkName: "about" */ "../views/PAMM.vue"),
	},{
		path: "/ug/PAMM",
		name: "PAMM-ug",
		component: () =>
			import( /* webpackChunkName: "about" */ "../views/PAMM.vue"),
	},{
		path: "/vn/PAMM",
		name: "PAMM-vn",
		component: () =>
			import( /* webpackChunkName: "about" */ "../views/PAMM.vue"),
	},
	{
		path: "/win",
		name: "win",
		component: () =>
			import( /* webpackChunkName: "about" */ "../views/win.vue"),
	},{
		path: "/cn/win",
		name: "win-cn",
		component: () =>
			import( /* webpackChunkName: "about" */ "../views/win.vue"),
	},{
		path: "/en/win",
		name: "win-en",
		component: () =>
			import( /* webpackChunkName: "about" */ "../views/win.vue"),
	},{
		path: "/ko/win",
		name: "win-ko",
		component: () =>
			import( /* webpackChunkName: "about" */ "../views/win.vue"),
	},{
		path: "/ja/win",
		name: "win-ja",
		component: () =>
			import( /* webpackChunkName: "about" */ "../views/win.vue"),
	},{
		path: "/th/win",
		name: "win-th",
		component: () =>
			import( /* webpackChunkName: "about" */ "../views/win.vue"),
	},{
		path: "/ug/win",
		name: "win-ug",
		component: () =>
			import( /* webpackChunkName: "about" */ "../views/win.vue"),
	},{
		path: "/tw/win",
		name: "win-tw",
		component: () =>
			import( /* webpackChunkName: "about" */ "../views/win.vue"),
	},{
		path: "/vn/win",
		name: "win-vn",
		component: () =>
			import( /* webpackChunkName: "about" */ "../views/win.vue"),
	},
	{
		path: "/win2",
		name: "win2",
		component: () =>
			import( /* webpackChunkName: "about" */ "../views/win2.vue"),
	},{
		path: "/cn/win2",
		name: "win-cn2",
		component: () =>
			import( /* webpackChunkName: "about" */ "../views/win2.vue"),
	},{
		path: "/en/win2",
		name: "win-en2",
		component: () =>
			import( /* webpackChunkName: "about" */ "../views/win2.vue"),
	},{
		path: "/ko/win2",
		name: "win-ko2",
		component: () =>
			import( /* webpackChunkName: "about" */ "../views/win2.vue"),
	},{
		path: "/ja/win2",
		name: "win-ja2",
		component: () =>
			import( /* webpackChunkName: "about" */ "../views/win2.vue"),
	},{
		path: "/th/win2",
		name: "win-th2",
		component: () =>
			import( /* webpackChunkName: "about" */ "../views/win2.vue"),
	},{
		path: "/ug/win2",
		name: "win-ug2",
		component: () =>
			import( /* webpackChunkName: "about" */ "../views/win2.vue"),
	},{
		path: "/tw/win2",
		name: "win-tw2",
		component: () =>
			import( /* webpackChunkName: "about" */ "../views/win2.vue"),
	},{
		path: "/vn/win2",
		name: "win-vn2",
		component: () =>
			import( /* webpackChunkName: "about" */ "../views/win2.vue"),
	},
	{
		path: "/FreeSwap",
		name: "FreeSwap",
		component: () =>
			import( /* webpackChunkName: "about" */ "../views/FreeSwap.vue"),
	},{
		path: "/cn/FreeSwap",
		name: "FreeSwap-cn",
		component: () =>
			import( /* webpackChunkName: "about" */ "../views/FreeSwap.vue"),
	},{
		path: "/en/FreeSwap",
		name: "FreeSwap-en",
		component: () =>
			import( /* webpackChunkName: "about" */ "../views/FreeSwap-en.vue"),
	},{
		path: "/ko/FreeSwap",
		name: "FreeSwap-ko",
		component: () =>
			import( /* webpackChunkName: "about" */ "../views/FreeSwap-en.vue"),
	},{
		path: "/ja/FreeSwap",
		name: "FreeSwap-ja",
		component: () =>
			import( /* webpackChunkName: "about" */ "../views/FreeSwap-en.vue"),
	},{
		path: "/th/FreeSwap",
		name: "FreeSwap-th",
		component: () =>
			import( /* webpackChunkName: "about" */ "../views/FreeSwap-en.vue"),
	},{
		path: "/ug/FreeSwap",
		name: "FreeSwap-ug",
		component: () =>
			import( /* webpackChunkName: "about" */ "../views/FreeSwap.vue"),
	},{
		path: "/tw/FreeSwap",
		name: "FreeSwap-tw",
		component: () =>
			import( /* webpackChunkName: "about" */ "../views/FreeSwap.vue"),
	},{
		path: "/vn/FreeSwap",
		name: "FreeSwap-vn",
		component: () =>
			import( /* webpackChunkName: "about" */ "../views/FreeSwap.vue"),
	},
	{
		path: "/tips",
		name: "tips",
		component: () =>
			import( /* webpackChunkName: "about" */ "../views/tips.vue"),
	},
	{
		path: "/TradingCompetition",
		name: "TradingCompetition",
		component: () =>
			import( /* webpackChunkName: "about" */ "../views/TradingCompetition.vue"),
	},{
		path: "/cn/TradingCompetition",
		name: "TradingCompetition-cn",
		component: () =>
			import( /* webpackChunkName: "about" */ "../views/TradingCompetition.vue"),
	},{
		path: "/en/TradingCompetition",
		name: "TradingCompetition-en",
		component: () =>
			import( /* webpackChunkName: "about" */ "../views/TradingCompetition.vue"),
	},{
		path: "/ko/TradingCompetition",
		name: "TradingCompetition-ko",
		component: () =>
			import( /* webpackChunkName: "about" */ "../views/TradingCompetition.vue"),
	},{
		path: "/ja/TradingCompetition",
		name: "TradingCompetition-ja",
		component: () =>
			import( /* webpackChunkName: "about" */ "../views/TradingCompetition.vue"),
	},{
		path: "/th/TradingCompetition",
		name: "TradingCompetition-th",
		component: () =>
			import( /* webpackChunkName: "about" */ "../views/TradingCompetition.vue"),
	},{
		path: "/tw/TradingCompetition",
		name: "TradingCompetition-tw",
		component: () =>
			import( /* webpackChunkName: "about" */ "../views/TradingCompetition.vue"),
	},{
		path: "/ug/TradingCompetition",
		name: "TradingCompetition-ug",
		component: () =>
			import( /* webpackChunkName: "about" */ "../views/TradingCompetition.vue"),
	},{
		path: "/vn/TradingCompetition",
		name: "TradingCompetition-vn",
		component: () =>
			import( /* webpackChunkName: "about" */ "../views/TradingCompetition.vue"),
	},
	{
		path: "/social-trading",
		name: "social-trading",
		component: () =>
			import( /* webpackChunkName: "about" */ "../views/social-trading.vue"),
	},
	{
		path: "/pay",
		name: "pay",
		component: () =>
			import( /* webpackChunkName: "about" */ "../views/pay.vue"),
	},{
		path: "/cn/pay",
		name: "pay-cn",
		component: () =>
			import( /* webpackChunkName: "about" */ "../views/pay.vue"),
	},{
		path: "/en/pay",
		name: "pay-en",
		component: () =>
			import( /* webpackChunkName: "about" */ "../views/pay.vue"),
	},{
		path: "/ko/pay",
		name: "pay-ko",
		component: () =>
			import( /* webpackChunkName: "about" */ "../views/pay.vue"),
	},{
		path: "/ja/pay",
		name: "pay-ja",
		component: () =>
			import( /* webpackChunkName: "about" */ "../views/pay.vue"),
	},{
		path: "/th/pay",
		name: "pay-th",
		component: () =>
			import( /* webpackChunkName: "about" */ "../views/pay.vue"),
	},{
		path: "/ug/pay",
		name: "pay-ug",
		component: () =>
			import( /* webpackChunkName: "about" */ "../views/pay.vue"),
	},{
		path: "/tw/pay",
		name: "pay-tw",
		component: () =>
			import( /* webpackChunkName: "about" */ "../views/pay.vue"),
	},
	{
		path: "/orderflow",
		name: "orderflow",
		component: () =>
			import( /* webpackChunkName: "about" */ "../views/orderflow.vue"),
	},{
		path: "/cn/orderflow",
		name: "orderflow-cn",
		component: () =>
			import( /* webpackChunkName: "about" */ "../views/orderflow.vue"),
	},{
		path: "/en/orderflow",
		name: "orderflow-en",
		component: () =>
			import( /* webpackChunkName: "about" */ "../views/orderflow.vue"),
	},{
		path: "/ko/orderflow",
		name: "orderflow-ko",
		component: () =>
			import( /* webpackChunkName: "about" */ "../views/orderflow.vue"),
	},{
		path: "/ja/orderflow",
		name: "orderflow-ja",
		component: () =>
			import( /* webpackChunkName: "about" */ "../views/orderflow.vue"),
	},{
		path: "/th/orderflow",
		name: "orderflow-th",
		component: () =>
			import( /* webpackChunkName: "about" */ "../views/orderflow.vue"),
	},{
		path: "/ug/orderflow",
		name: "orderflow-ug",
		component: () =>
			import( /* webpackChunkName: "about" */ "../views/orderflow.vue"),
	},{
		path: "/tw/orderflow",
		name: "orderflow-tw",
		component: () =>
			import( /* webpackChunkName: "about" */ "../views/orderflow.vue"),
	},
	{
		path: "/lab",
		name: "lab",
		component: () =>
			import( /* webpackChunkName: "about" */ "../views/lab.vue"),
	},{
		path: "/cn/lab",
		name: "lab-cn",
		component: () =>
			import( /* webpackChunkName: "about" */ "../views/lab.vue"),
	},{
		path: "/en/lab",
		name: "lab-en",
		component: () =>
			import( /* webpackChunkName: "about" */ "../views/lab.vue"),
	},{
		path: "/ko/lab",
		name: "lab-ko",
		component: () =>
			import( /* webpackChunkName: "about" */ "../views/lab.vue"),
	},{
		path: "/ja/lab",
		name: "lab-ja",
		component: () =>
			import( /* webpackChunkName: "about" */ "../views/lab.vue"),
	},{
		path: "/th/lab",
		name: "lab-th",
		component: () =>
			import( /* webpackChunkName: "about" */ "../views/lab.vue"),
	},{
		path: "/ug/lab",
		name: "lab-ug",
		component: () =>
			import( /* webpackChunkName: "about" */ "../views/lab.vue"),
	},{
		path: "/tw/lab",
		name: "lab-tw",
		component: () =>
			import( /* webpackChunkName: "about" */ "../views/lab.vue"),
	},{
		path: "/vn/lab",
		name: "lab-vn",
		component: () =>
			import( /* webpackChunkName: "about" */ "../views/lab.vue"),
	},
	{
		path: "/tools",
		name: "tools",
		component: () =>
			import( /* webpackChunkName: "about" */ "../views/tools.vue"),
	},{
		path: "/cn/tools",
		name: "tools-cn",
		component: () =>
			import( /* webpackChunkName: "about" */ "../views/tools.vue"),
	},{
		path: "/en/tools",
		name: "tools-en",
		component: () =>
			import( /* webpackChunkName: "about" */ "../views/tools.vue"),
	},{
		path: "/ko/tools",
		name: "tools-ko",
		component: () =>
			import( /* webpackChunkName: "about" */ "../views/tools.vue"),
	},{
		path: "/ja/tools",
		name: "tools-ja",
		component: () =>
			import( /* webpackChunkName: "about" */ "../views/tools.vue"),
	},{
		path: "/th/tools",
		name: "tools-th",
		component: () =>
			import( /* webpackChunkName: "about" */ "../views/tools.vue"),
	},{
		path: "/ug/tools",
		name: "tools-ug",
		component: () =>
			import( /* webpackChunkName: "about" */ "../views/tools.vue"),
	},{
		path: "/tw/tools",
		name: "tools-tw",
		component: () =>
			import( /* webpackChunkName: "about" */ "../views/tools.vue"),
	},{
		path: "/vn/tools",
		name: "tools-vn",
		component: () =>
			import( /* webpackChunkName: "about" */ "../views/tools.vue"),
	},
	{
		path: "/LearningCenter",
		name: "LearningCenter",
		component: () =>
			import( /* webpackChunkName: "about" */ "../views/LearningCenter.vue"),
	},
	{
		path: "/LearningCenterInfo",
		name: "LearningCenterInfo",
		component: () =>
			import( /* webpackChunkName: "about" */ "../views/LearningCenterInfo.vue"),
	},
	{
		path: "/partner",
		name: "partner",
		component: () =>
			import( /* webpackChunkName: "about" */ "../views/partner.vue"),
	},
	{
		path: "/partner1",
		name: "partner1",
		component: () =>
			import( /* webpackChunkName: "about" */ "../views/partner1.vue"),
	},{
		path: "/cn/partner",
		name: "partner-cn",
		component: () =>
			import( /* webpackChunkName: "about" */ "../views/partner.vue"),
	},{
		path: "/en/partner",
		name: "partner-en",
		component: () =>
			import( /* webpackChunkName: "about" */ "../views/partner.vue"),
	},{
		path: "/ko/partner",
		name: "partner-ko",
		component: () =>
			import( /* webpackChunkName: "about" */ "../views/partner.vue"),
	},{
		path: "/ja/partner",
		name: "partner-ja",
		component: () =>
			import( /* webpackChunkName: "about" */ "../views/partner.vue"),
	},{
		path: "/th/partner",
		name: "partner-th",
		component: () =>
			import( /* webpackChunkName: "about" */ "../views/partner.vue"),
	},{
		path: "/ug/partner",
		name: "partner-ug",
		component: () =>
			import( /* webpackChunkName: "about" */ "../views/partner.vue"),
	},{
		path: "/tw/partner",
		name: "partner-tw",
		component: () =>
			import( /* webpackChunkName: "about" */ "../views/partner.vue"),
	},{
		path: "/vn/partner",
		name: "partner-vn",
		component: () =>
			import( /* webpackChunkName: "about" */ "../views/partner.vue"),
	},
	{
		path: "/tradingproduct",
		name: "tradingproduct",
		component: () =>
			import( /* webpackChunkName: "about" */ "../views/tradingproduct.vue"),
	},{
		path: "/cn/tradingproduct",
		name: "tradingproduct-cn",
		component: () =>
			import( /* webpackChunkName: "about" */ "../views/tradingproduct.vue"),
	},{
		path: "/en/tradingproduct",
		name: "tradingproduct-en",
		component: () =>
			import( /* webpackChunkName: "about" */ "../views/tradingproduct.vue"),
	},{
		path: "/ko/tradingproduct",
		name: "tradingproduct-ko",
		component: () =>
			import( /* webpackChunkName: "about" */ "../views/tradingproduct.vue"),
	},{
		path: "/ja/tradingproduct",
		name: "tradingproduct-ja",
		component: () =>
			import( /* webpackChunkName: "about" */ "../views/tradingproduct.vue"),
	},{
		path: "/th/tradingproduct",
		name: "tradingproduct-th",
		component: () =>
			import( /* webpackChunkName: "about" */ "../views/tradingproduct.vue"),
	},{
		path: "/ug/tradingproduct",
		name: "tradingproduct-ug",
		component: () =>
			import( /* webpackChunkName: "about" */ "../views/tradingproduct.vue"),
	},{
		path: "/tw/tradingproduct",
		name: "tradingproduct-tw",
		component: () =>
			import( /* webpackChunkName: "about" */ "../views/tradingproduct.vue"),
	},{
		path: "/vn/tradingproduct",
		name: "tradingproduct-vn",
		component: () =>
			import( /* webpackChunkName: "about" */ "../views/tradingproduct.vue"),
	},
	{
		path: "/dividend",
		name: "dividend",
		component: () =>
			import( /* webpackChunkName: "about" */ "../views/dividend.vue"),
	},{
		path: "/cn/dividend",
		name: "dividend-cn",
		component: () =>
			import( /* webpackChunkName: "about" */ "../views/dividend.vue"),
	},{
		path: "/en/dividend",
		name: "dividend-en",
		component: () =>
			import( /* webpackChunkName: "about" */ "../views/dividend.vue"),
	},{
		path: "/ko/dividend",
		name: "dividend-ko",
		component: () =>
			import( /* webpackChunkName: "about" */ "../views/dividend.vue"),
	},{
		path: "/ja/dividend",
		name: "dividend-ja",
		component: () =>
			import( /* webpackChunkName: "about" */ "../views/dividend.vue"),
	},{
		path: "/th/dividend",
		name: "dividend-th",
		component: () =>
			import( /* webpackChunkName: "about" */ "../views/dividend.vue"),
	},{
		path: "/ug/dividend",
		name: "dividend-ug",
		component: () =>
			import( /* webpackChunkName: "about" */ "../views/dividend.vue"),
	},{
		path: "/tw/dividend",
		name: "dividend-tw",
		component: () =>
			import( /* webpackChunkName: "about" */ "../views/dividend.vue"),
	},{
		path: "/vn/dividend",
		name: "dividend-vn",
		component: () =>
			import( /* webpackChunkName: "about" */ "../views/dividend.vue"),
	},
	{
		path: "/about-us",
		name: "about-us",
		component: () =>
			import( /* webpackChunkName: "about" */ "../views/about-us.vue"),
	},{
		path: "/cn/about-us",
		name: "about-us-cn",
		component: () =>
			import( /* webpackChunkName: "about" */ "../views/about-us.vue"),
	},{
		path: "/en/about-us",
		name: "about-us-en",
		component: () =>
			import( /* webpackChunkName: "about" */ "../views/about-us.vue"),
	},{
		path: "/ko/about-us",
		name: "about-us-ko",
		component: () =>
			import( /* webpackChunkName: "about" */ "../views/about-us.vue"),
	},{
		path: "/ja/about-us",
		name: "about-us-ja",
		component: () =>
			import( /* webpackChunkName: "about" */ "../views/about-us.vue"),
	},{
		path: "/th/about-us",
		name: "about-us-th",
		component: () =>
			import( /* webpackChunkName: "about" */ "../views/about-us.vue"),
	},{
		path: "/ug/about-us",
		name: "about-us-ug",
		component: () =>
			import( /* webpackChunkName: "about" */ "../views/about-us.vue"),
	},{
		path: "/tw/about-us",
		name: "about-us-tw",
		component: () =>
			import( /* webpackChunkName: "about" */ "../views/about-us.vue"),
	},{
		path: "/vn/about-us",
		name: "about-us-vn",
		component: () =>
			import( /* webpackChunkName: "about" */ "../views/about-us.vue"),
	},
	{
		path: "/Mam",
		name: "Mam",
		component: () =>
			import( /* webpackChunkName: "about" */ "../views/Mam.vue"),
	},{
		path: "/cn/Mam",
		name: "Mam-cn",
		component: () =>
			import( /* webpackChunkName: "about" */ "../views/Mam.vue"),
	},{
		path: "/en/Mam",
		name: "Mam-en",
		component: () =>
			import( /* webpackChunkName: "about" */ "../views/Mam.vue"),
	},{
		path: "/ko/Mam",
		name: "Mam-ko",
		component: () =>
			import( /* webpackChunkName: "about" */ "../views/Mam.vue"),
	},{
		path: "/ja/Mam",
		name: "Mam-ja",
		component: () =>
			import( /* webpackChunkName: "about" */ "../views/Mam.vue"),
	},{
		path: "/th/Mam",
		name: "Mam-th",
		component: () =>
			import( /* webpackChunkName: "about" */ "../views/Mam.vue"),
	},{
		path: "/ug/Mam",
		name: "Mam-ug",
		component: () =>
			import( /* webpackChunkName: "about" */ "../views/Mam.vue"),
	},{
		path: "/tw/Mam",
		name: "Mam-tw",
		component: () =>
			import( /* webpackChunkName: "about" */ "../views/Mam.vue"),
	},{
		path: "/vn/Mam",
		name: "Mam-vn",
		component: () =>
			import( /* webpackChunkName: "about" */ "../views/Mam.vue"),
	},
	{
		path: "/technology",
		name: "technology",
		component: () =>
			import( /* webpackChunkName: "about" */ "../views/technology.vue"),
	},{
		path: "/cn/technology",
		name: "technology-cn",
		component: () =>
			import( /* webpackChunkName: "about" */ "../views/technology.vue"),
	},{
		path: "/en/technology",
		name: "technology-en",
		component: () =>
			import( /* webpackChunkName: "about" */ "../views/technology.vue"),
	},{
		path: "/ko/technology",
		name: "technology-ko",
		component: () =>
			import( /* webpackChunkName: "about" */ "../views/technology.vue"),
	},{
		path: "/ja/technology",
		name: "technology-ja",
		component: () =>
			import( /* webpackChunkName: "about" */ "../views/technology.vue"),
	},{
		path: "/th/technology",
		name: "technology-th",
		component: () =>
			import( /* webpackChunkName: "about" */ "../views/technology.vue"),
	},{
		path: "/ug/technology",
		name: "technology-ug",
		component: () =>
			import( /* webpackChunkName: "about" */ "../views/technology.vue"),
	},{
		path: "/tw/technology",
		name: "technology-tw",
		component: () =>
			import( /* webpackChunkName: "about" */ "../views/technology.vue"),
	},{
		path: "/vn/technology",
		name: "technology-vn",
		component: () =>
			import( /* webpackChunkName: "about" */ "../views/technology.vue"),
	},
	{
		path: "/service",
		name: "service",
		component: () =>
			import( /* webpackChunkName: "about" */ "../views/service.vue"),
	},{
		path: "/cn/service",
		name: "service-cn",
		component: () =>
			import( /* webpackChunkName: "about" */ "../views/service.vue"),
	},{
		path: "/en/service",
		name: "service-en",
		component: () =>
			import( /* webpackChunkName: "about" */ "../views/service.vue"),
	},{
		path: "/ko/service",
		name: "service-ko",
		component: () =>
			import( /* webpackChunkName: "about" */ "../views/service.vue"),
	},{
		path: "/ja/service",
		name: "service-ja",
		component: () =>
			import( /* webpackChunkName: "about" */ "../views/service.vue"),
	},{
		path: "/th/service",
		name: "service-th",
		component: () =>
			import( /* webpackChunkName: "about" */ "../views/service.vue"),
	},{
		path: "/ug/service",
		name: "service-ug",
		component: () =>
			import( /* webpackChunkName: "about" */ "../views/service.vue"),
	},{
		path: "/tw/service",
		name: "service-tw",
		component: () =>
			import( /* webpackChunkName: "about" */ "../views/service.vue"),
	},{
		path: "/vn/service",
		name: "service-vn",
		component: () =>
			import( /* webpackChunkName: "about" */ "../views/service.vue"),
	},
	{
		path: "/Videoplayback",
		name: "Videoplayback",
		component: () =>
			import( /* webpackChunkName: "about" */ "../views/Videoplayback.vue"),
	},
	{
		path: "/FAQ",
		name: "FAQ",
		component: () =>
			import( /* webpackChunkName: "about" */ "../views/FAQ.vue"),
	},{
		path: "/cn/FAQ",
		name: "FAQ-cn",
		component: () =>
			import( /* webpackChunkName: "about" */ "../views/FAQ.vue"),
	},{
		path: "/en/FAQ",
		name: "FAQ-en",
		component: () =>
			import( /* webpackChunkName: "about" */ "../views/FAQ.vue"),
	},{
		path: "/ko/FAQ",
		name: "FAQ-ko",
		component: () =>
			import( /* webpackChunkName: "about" */ "../views/FAQ.vue"),
	},{
		path: "/ja/FAQ",
		name: "FAQ-ja",
		component: () =>
			import( /* webpackChunkName: "about" */ "../views/FAQ.vue"),
	},{
		path: "/th/FAQ",
		name: "FAQ-th",
		component: () =>
			import( /* webpackChunkName: "about" */ "../views/FAQ.vue"),
	},{
		path: "/ug/FAQ",
		name: "FAQ-ug",
		component: () =>
			import( /* webpackChunkName: "about" */ "../views/FAQ.vue"),
	},{
		path: "/tw/FAQ",
		name: "FAQ-tw",
		component: () =>
			import( /* webpackChunkName: "about" */ "../views/FAQ.vue"),
	},{
		path: "/vn/FAQ",
		name: "FAQ-vn",
		component: () =>
			import( /* webpackChunkName: "about" */ "../views/FAQ.vue"),
	},
	{
		path: "/LegalDocumen",
		name: "LegalDocumen",
		component: () =>
			import( /* webpackChunkName: "about" */ "../views/LegalDocumen.vue"),
	},{
		path: "/cn/LegalDocumen",
		name: "LegalDocumen-cn",
		component: () =>
			import( /* webpackChunkName: "about" */ "../views/LegalDocumen.vue"),
	},{
		path: "/en/LegalDocumen",
		name: "LegalDocumen-en",
		component: () =>
			import( /* webpackChunkName: "about" */ "../views/LegalDocumen.vue"),
	},{
		path: "/ko/LegalDocumen",
		name: "LegalDocumen-ko",
		component: () =>
			import( /* webpackChunkName: "about" */ "../views/LegalDocumen.vue"),
	},{
		path: "/ja/LegalDocumen",
		name: "LegalDocumen-ja",
		component: () =>
			import( /* webpackChunkName: "about" */ "../views/LegalDocumen.vue"),
	},{
		path: "/th/LegalDocumen",
		name: "LegalDocumen-th",
		component: () =>
			import( /* webpackChunkName: "about" */ "../views/LegalDocumen.vue"),
	},{
		path: "/ug/LegalDocumen",
		name: "LegalDocumen-ug",
		component: () =>
			import( /* webpackChunkName: "about" */ "../views/LegalDocumen.vue"),
	},{
		path: "/tw/LegalDocumen",
		name: "LegalDocumen-tw",
		component: () =>
			import( /* webpackChunkName: "about" */ "../views/LegalDocumen.vue"),
	},{
		path: "/vn/LegalDocumen",
		name: "LegalDocumen-vn",
		component: () =>
			import( /* webpackChunkName: "about" */ "../views/LegalDocumen.vue"),
	},
	{
		path: "/win2022",
		name: "win2022",
		component: () =>
			import( /* webpackChunkName: "about" */ "../views/win2022.vue"),
	},
	{
		path: "/TradingAccount",
		name: "TradingAccount",
		component: () =>
			import( /* webpackChunkName: "about" */ "../views/TradingAccount.vue"),
	},{
		path: "/cn/TradingAccount",
		name: "TradingAccount-cn",
		component: () =>
			import( /* webpackChunkName: "about" */ "../views/TradingAccount.vue"),
	},{
		path: "/en/TradingAccount",
		name: "TradingAccount-en",
		component: () =>
			import( /* webpackChunkName: "about" */ "../views/TradingAccount.vue"),
	},{
		path: "/ko/TradingAccount",
		name: "TradingAccount-ko",
		component: () =>
			import( /* webpackChunkName: "about" */ "../views/TradingAccount.vue"),
	},{
		path: "/ja/TradingAccount",
		name: "TradingAccount-ja",
		component: () =>
			import( /* webpackChunkName: "about" */ "../views/TradingAccount.vue"),
	},{
		path: "/th/TradingAccount",
		name: "TradingAccount-th",
		component: () =>
			import( /* webpackChunkName: "about" */ "../views/TradingAccount.vue"),
	},{
		path: "/ug/TradingAccount",
		name: "TradingAccount-ug",
		component: () =>
			import( /* webpackChunkName: "about" */ "../views/TradingAccount.vue"),
	},{
		path: "/tw/TradingAccount",
		name: "TradingAccount-tw",
		component: () =>
			import( /* webpackChunkName: "about" */ "../views/TradingAccount.vue"),
	},{
		path: "/vn/TradingAccount",
		name: "TradingAccount-vn",
		component: () =>
			import( /* webpackChunkName: "about" */ "../views/TradingAccount.vue"),
	},
	{
		path: "/AllProducts",
		name: "AllProducts",
		component: () =>
			import( /* webpackChunkName: "about" */ "../views/AllProducts.vue"),
	},{
		path: "/cn/AllProducts",
		name: "AllProducts-cn",
		component: () =>
			import( /* webpackChunkName: "about" */ "../views/AllProducts.vue"),
	},{
		path: "/en/AllProducts",
		name: "AllProducts-en",
		component: () =>
			import( /* webpackChunkName: "about" */ "../views/AllProducts.vue"),
	},{
		path: "/ko/AllProducts",
		name: "AllProducts-ko",
		component: () =>
			import( /* webpackChunkName: "about" */ "../views/AllProducts.vue"),
	},{
		path: "/ja/AllProducts",
		name: "AllProducts-ja",
		component: () =>
			import( /* webpackChunkName: "about" */ "../views/AllProducts.vue"),
	},{
		path: "/th/AllProducts",
		name: "AllProducts-th",
		component: () =>
			import( /* webpackChunkName: "about" */ "../views/AllProducts.vue"),
	},{
		path: "/ug/AllProducts",
		name: "AllProducts-ug",
		component: () =>
			import( /* webpackChunkName: "about" */ "../views/AllProducts.vue"),
	},{
		path: "/tw/AllProducts",
		name: "AllProducts-tw",
		component: () =>
			import( /* webpackChunkName: "about" */ "../views/AllProducts.vue"),
	},{
		path: "/vn/AllProducts",
		name: "AllProducts-vn",
		component: () =>
			import( /* webpackChunkName: "about" */ "../views/AllProducts.vue"),
	},
	// {
	// 	path: "/no1",
	// 	name: "No1New",
	// 	component: () =>
	// 		import( /* webpackChunkName: "about" */ "../views/No1New.vue"),
	// },{
	// 	path: "/cn/no1",
	// 	name: "No1New-cn",
	// 	component: () =>
	// 		import( /* webpackChunkName: "about" */ "../views/No1New.vue"),
	// },{
	// 	path: "/en/no1",
	// 	name: "No1New-en",
	// 	component: () =>
	// 		import( /* webpackChunkName: "about" */ "../views/No1New.vue"),
	// },{
	// 	path: "/ko/no1",
	// 	name: "No1New-ko",
	// 	component: () =>
	// 		import( /* webpackChunkName: "about" */ "../views/No1New.vue"),
	// },{
	// 	path: "/ja/no1",
	// 	name: "No1New-ja",
	// 	component: () =>
	// 		import( /* webpackChunkName: "about" */ "../views/No1New.vue"),
	// },{
	// 	path: "/th/no1",
	// 	name: "No1New-th",
	// 	component: () =>
	// 		import( /* webpackChunkName: "about" */ "../views/No1New.vue"),
	// },{
	// 	path: "/ug/no1",
	// 	name: "No1New-ug",
	// 	component: () =>
	// 		import( /* webpackChunkName: "about" */ "../views/No1New.vue"),
	// },{
	// 	path: "/tw/no1",
	// 	name: "No1New-tw",
	// 	component: () =>
	// 		import( /* webpackChunkName: "about" */ "../views/No1New.vue"),
	// },{
	// 	path: "/vn/no1",
	// 	name: "No1New-vn",
	// 	component: () =>
	// 		import( /* webpackChunkName: "about" */ "../views/No1New.vue"),
	// },
	{
		path: "/oxford",
		name: "Oxford",
		component: () =>
			import( /* webpackChunkName: "about" */ "../views/Oxford.vue"),
	},{
		path: "/cn/oxford",
		name: "Oxford-cn",
		component: () =>
			import( /* webpackChunkName: "about" */ "../views/Oxford.vue"),
	},{
		path: "/en/oxford",
		name: "Oxford-en",
		component: () =>
			import( /* webpackChunkName: "about" */ "../views/Oxford.vue"),
	},{
		path: "/ko/oxford",
		name: "Oxford-ko",
		component: () =>
			import( /* webpackChunkName: "about" */ "../views/Oxford.vue"),
	},{
		path: "/ja/oxford",
		name: "Oxford-ja",
		component: () =>
			import( /* webpackChunkName: "about" */ "../views/Oxford.vue"),
	},{
		path: "/th/oxford",
		name: "Oxford-th",
		component: () =>
			import( /* webpackChunkName: "about" */ "../views/Oxford.vue"),
	},{
		path: "/ug/oxford",
		name: "Oxford-ug",
		component: () =>
			import( /* webpackChunkName: "about" */ "../views/Oxford.vue"),
	},{
		path: "/tw/oxford",
		name: "Oxford-tw",
		component: () =>
			import( /* webpackChunkName: "about" */ "../views/Oxford.vue"),
	},{
		path: "/vn/oxford",
		name: "Oxford-vn",
		component: () =>
			import( /* webpackChunkName: "about" */ "../views/Oxford.vue"),
	},
	{
		path: "/FCB",
		name: "FCB",
		component: () =>
			import( /* webpackChunkName: "about" */ "../views/FCB.vue"),
	},{
		path: "/cn/FCB",
		name: "FCB-cn",
		component: () =>
			import( /* webpackChunkName: "about" */ "../views/FCB.vue"),
	},{
		path: "/en/FCB",
		name: "FCB-en",
		component: () =>
			import( /* webpackChunkName: "about" */ "../views/FCB.vue"),
	},{
		path: "/ko/FCB",
		name: "FCB-ko",
		component: () =>
			import( /* webpackChunkName: "about" */ "../views/FCB.vue"),
	},{
		path: "/ja/FCB",
		name: "FCB-ja",
		component: () =>
			import( /* webpackChunkName: "about" */ "../views/FCB.vue"),
	},{
		path: "/th/FCB",
		name: "FCB-th",
		component: () =>
			import( /* webpackChunkName: "about" */ "../views/FCB.vue"),
	},{
		path: "/ug/FCB",
		name: "FCB-ug",
		component: () =>
			import( /* webpackChunkName: "about" */ "../views/FCB.vue"),
	},{
		path: "/tw/FCB",
		name: "FCB-tw",
		component: () =>
			import( /* webpackChunkName: "about" */ "../views/FCB.vue"),
	},{
		path: "/vn/FCB",
		name: "FCB-vn",
		component: () =>
			import( /* webpackChunkName: "about" */ "../views/FCB.vue"),
	},
	{
		path: "/security",
		name: "security",
		component: () =>
			import( /* webpackChunkName: "about" */ "../views/security.vue"),
	},{
		path: "/cn/security",
		name: "security-cn",
		component: () =>
			import( /* webpackChunkName: "about" */ "../views/security.vue"),
	},{
		path: "/en/security",
		name: "security-en",
		component: () =>
			import( /* webpackChunkName: "about" */ "../views/security.vue"),
	},{
		path: "/ko/security",
		name: "security-ko",
		component: () =>
			import( /* webpackChunkName: "about" */ "../views/security.vue"),
	},{
		path: "/ja/security",
		name: "security-ja",
		component: () =>
			import( /* webpackChunkName: "about" */ "../views/security.vue"),
	},{
		path: "/th/security",
		name: "security-th",
		component: () =>
			import( /* webpackChunkName: "about" */ "../views/security.vue"),
	},{
		path: "/ug/security",
		name: "security-ug",
		component: () =>
			import( /* webpackChunkName: "about" */ "../views/security.vue"),
	},{
		path: "/tw/security",
		name: "security-tw",
		component: () =>
			import( /* webpackChunkName: "about" */ "../views/security.vue"),
	},{
		path: "/vn/security",
		name: "security-vn",
		component: () =>
			import( /* webpackChunkName: "about" */ "../views/security.vue"),
	},
	{
		path: "/malaria",
		name: "malaria",
		component: () =>
			import( /* webpackChunkName: "about" */ "../views/malaria.vue"),
	},{
		path: "/cn/malaria",
		name: "malaria-cn",
		component: () =>
			import( /* webpackChunkName: "about" */ "../views/malaria.vue"),
	},{
		path: "/en/malaria",
		name: "malaria-en",
		component: () =>
			import( /* webpackChunkName: "about" */ "../views/malaria.vue"),
	},{
		path: "/ko/malaria",
		name: "malaria-ko",
		component: () =>
			import( /* webpackChunkName: "about" */ "../views/malaria.vue"),
	},{
		path: "/ja/malaria",
		name: "malaria-ja",
		component: () =>
			import( /* webpackChunkName: "about" */ "../views/malaria.vue"),
	},{
		path: "/th/malaria",
		name: "malaria-th",
		component: () =>
			import( /* webpackChunkName: "about" */ "../views/malaria.vue"),
	},{
		path: "/ug/malaria",
		name: "malaria-ug",
		component: () =>
			import( /* webpackChunkName: "about" */ "../views/malaria.vue"),
	},{
		path: "/tw/malaria",
		name: "malaria-tw",
		component: () =>
			import( /* webpackChunkName: "about" */ "../views/malaria.vue"),
	},{
		path: "/vn/malaria",
		name: "malaria-vn",
		component: () =>
			import( /* webpackChunkName: "about" */ "../views/malaria.vue"),
	},
	{
		path: "/ESG",
		name: "ESG",
		component: () =>
			import( /* webpackChunkName: "about" */ "../views/ESG.vue"),
	},{
		path: "/cn/ESG",
		name: "ESG-cn",
		component: () =>
			import( /* webpackChunkName: "about" */ "../views/ESG.vue"),
	},{
		path: "/en/ESG",
		name: "ESG-en",
		component: () =>
			import( /* webpackChunkName: "about" */ "../views/ESG.vue"),
	},{
		path: "/ko/ESG",
		name: "ESG-ko",
		component: () =>
			import( /* webpackChunkName: "about" */ "../views/ESG.vue"),
	},{
		path: "/ja/ESG",
		name: "ESG-ja",
		component: () =>
			import( /* webpackChunkName: "about" */ "../views/ESG.vue"),
	},{
		path: "/th/ESG",
		name: "ESG-th",
		component: () =>
			import( /* webpackChunkName: "about" */ "../views/ESG.vue"),
	},{
		path: "/ug/ESG",
		name: "ESG-ug",
		component: () =>
			import( /* webpackChunkName: "about" */ "../views/ESG.vue"),
	},{
		path: "/tw/ESG",
		name: "ESG-tw",
		component: () =>
			import( /* webpackChunkName: "about" */ "../views/ESG.vue"),
	},{
		path: "/vn/ESG",
		name: "ESG-vn",
		component: () =>
			import( /* webpackChunkName: "about" */ "../views/ESG.vue"),
	},
	{
		path: "/official-verification",
		name: "official-verification",
		component: () =>
			import( /* webpackChunkName: "about" */ "../views/OfficialVerification.vue"),
	},{
		path: "/cn/official-verification",
		name: "official-verification-cn",
		component: () =>
			import( /* webpackChunkName: "about" */ "../views/OfficialVerification.vue"),
	},{
		path: "/en/official-verification",
		name: "official-verification-en",
		component: () =>
			import( /* webpackChunkName: "about" */ "../views/OfficialVerification.vue"),
	},{
		path: "/ko/official-verification",
		name: "official-verification-ko",
		component: () =>
			import( /* webpackChunkName: "about" */ "../views/OfficialVerification.vue"),
	},{
		path: "/ja/official-verification",
		name: "official-verification-ja",
		component: () =>
			import( /* webpackChunkName: "about" */ "../views/OfficialVerification.vue"),
	},{
		path: "/th/official-verification",
		name: "official-verification-th",
		component: () =>
			import( /* webpackChunkName: "about" */ "../views/OfficialVerification.vue"),
	},{
		path: "/ug/official-verification",
		name: "official-verification-ug",
		component: () =>
			import( /* webpackChunkName: "about" */ "../views/OfficialVerification.vue"),
	},{
		path: "/tw/official-verification",
		name: "official-verification-tw",
		component: () =>
			import( /* webpackChunkName: "about" */ "../views/OfficialVerification.vue"),
	},{
		path: "/vn/official-verification",
		name: "official-verification-vn",
		component: () =>
			import( /* webpackChunkName: "about" */ "../views/OfficialVerification.vue"),
	},
	{
		path: "/affiliate-programmes",
		name: "affiliate-programmes",
		component: () =>
			import( /* webpackChunkName: "about" */ "../views/AffiliateProgrammes.vue"),
	},{
		path: "/cn/affiliate-programmes",
		name: "affiliate-programmes-cn",
		component: () =>
			import( /* webpackChunkName: "about" */ "../views/AffiliateProgrammes.vue"),
	},{
		path: "/en/affiliate-programmes",
		name: "affiliate-programmes-en",
		component: () =>
			import( /* webpackChunkName: "about" */ "../views/AffiliateProgrammes.vue"),
	},{
		path: "/ko/affiliate-programmes",
		name: "affiliate-programmes-ko",
		component: () =>
			import( /* webpackChunkName: "about" */ "../views/AffiliateProgrammes.vue"),
	},{
		path: "/ja/affiliate-programmes",
		name: "affiliate-programmes-ja",
		component: () =>
			import( /* webpackChunkName: "about" */ "../views/AffiliateProgrammes.vue"),
	},{
		path: "/th/affiliate-programmes",
		name: "affiliate-programmes-th",
		component: () =>
			import( /* webpackChunkName: "about" */ "../views/AffiliateProgrammes.vue"),
	},{
		path: "/ug/affiliate-programmes",
		name: "affiliate-programmes-ug",
		component: () =>
			import( /* webpackChunkName: "about" */ "../views/AffiliateProgrammes.vue"),
	},{
		path: "/tw/affiliate-programmes",
		name: "affiliate-programmes-tw",
		component: () =>
			import( /* webpackChunkName: "about" */ "../views/AffiliateProgrammes.vue"),
	},{
		path: "/vn/affiliate-programmes",
		name: "affiliate-programmes-vn",
		component: () =>
			import( /* webpackChunkName: "about" */ "../views/AffiliateProgrammes.vue"),
	},
	{
		path: "/DiNapoli",
		name: "DiNapoli",
		component: () =>
			import( /* webpackChunkName: "about" */ "../views/DiNapoli.vue"),
	},{
		path: "/cn/DiNapoli",
		name: "DiNapoli-cn",
		component: () =>
			import( /* webpackChunkName: "about" */ "../views/DiNapoli.vue"),
	},{
		path: "/en/DiNapoli",
		name: "DiNapoli-en",
		component: () =>
			import( /* webpackChunkName: "about" */ "../views/DiNapoli.vue"),
	},{
		path: "/ko/DiNapoli",
		name: "DiNapoli-ko",
		component: () =>
			import( /* webpackChunkName: "about" */ "../views/DiNapoli.vue"),
	},{
		path: "/ja/DiNapoli",
		name: "DiNapoli-ja",
		component: () =>
			import( /* webpackChunkName: "about" */ "../views/DiNapoli.vue"),
	},{
		path: "/th/DiNapoli",
		name: "DiNapoli-th",
		component: () =>
			import( /* webpackChunkName: "about" */ "../views/DiNapoli.vue"),
	},{
		path: "/ug/DiNapoli",
		name: "DiNapoli-ug",
		component: () =>
			import( /* webpackChunkName: "about" */ "../views/DiNapoli.vue"),
	},{
		path: "/tw/DiNapoli",
		name: "DiNapoli-tw",
		component: () =>
			import( /* webpackChunkName: "about" */ "../views/DiNapoli.vue"),
	},{
		path: "/vn/DiNapoli",
		name: "DiNapoli-vn",
		component: () =>
			import( /* webpackChunkName: "about" */ "../views/DiNapoli.vue"),
	}
];

const router = new VueRouter({
	mode: "history",
	base: process.env.BASE_URL,
	routes,
	scrollBehavior(to, from, savedPosition) {
		if (savedPosition) {
			return savedPosition;
		} else {
			return {
				x: 0,
				y: 0
			}
		}
	},
});

export default router;
