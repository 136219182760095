export default {
  "screen0": {
    "title": "Pure Trading Pure Power",
    "subTitle": "取引は0.01秒の差が命運を左右する世界です。<br>圧倒的な速さでマーケットにアクセス可能な超低遅延ITインフラを実現しました。"
  },
  "screen1": {
    "line1": "取引約定速度最短0.02秒",
    "line2": "データ通信安定度98.75％",
    "line3": "広帯域通信を実現した金融専用光回線100Mbps",
    "line4": "はじめよう、世界と一体となって"
  },
  "screen2": {
    "title": "テクノロジーを活用した取引により",
    "desc": "エクイニクスを活用したEBC独自のサーバーは、ロンドンLD4、香港HK3、東京TY3、ニューヨークNY4に設置しており、グローバルに広がる金融エコシステムを構築しています。",
    "title1": "テクノロジーを活用した取引により、<br>EBCの約定速度は最短0.02秒",
    "desc1": "ソフトウェアデファインドストレージおよびネットワークをベースとしたハイパーコンバージドインフラ（HCI）が、最大98.75%の安定したシステム稼働率を実現しました。",
    "desc2": "<p>現在の金融市場において、スピードこそ利益を獲得するために不可欠な要因です。</p><p>EBCは取引サーバーは、世界有数のデータセンターであるエクイニクスを活用しており、ロンドンLD5、ニューヨークNY4、シンガポールSG1、東京TY3、香港HK2に設置しています。超高速の専用光回線接続により地域間の遅延を最小限に抑え、安定した取引環境を提供します。</p> <p>海外の金融プラットフォームをお使いの際、ファイアウォールや国際回線の混雑などの制限で遅延した経験を多くの人がお持ちかもしれません。EBCは、災害復旧対策も考慮したおよそ30箇所のデータセンターを備え、ユーラシアにおけるサーバ間はすべてP2Pを用いているため遅延とは無縁です。</p><p>従来ロンドンからアジアへの伝送時間は0.27秒以上かかっていました。対して、EBCはロンドンからアジアへの遅延を0.1秒以内に抑えており、アジアのお客様にとって最適な環境と言えます。</p>"
  },
  "screen3": {
    "title": "私たちは熟知しています。成功への鍵はソフトウェア技術だけではなく、すべてであることを。",
    "desc": "EBCは取引市場で最高の流動性を提供します。お客様はマルチアセットでの流動性を享受しながら、より柔軟な運用を実現します。",
    "plant": "取引プラットフォームについてもっと詳しく見る",
    "btn": "Lab 研究センター/取引プラットフォーム部門"
  },
  "screen4": {
    "enTitle": "Driving You to Succeed",
    "title": "先進のフィンテックによって、<br>金融取引の可能性は無限に拡がる"
  },
  "screen5": {
    "plant": "取引商品について詳しく見る",
    "btn": "Lab 研究センター/取引プラットフォーム部門",
    "title": "リスクを最小に 取引機会を最大に",
    "desc": "EBCは超低遅延なアグリケーション構成、スマートオーダールーティング、そして入札最適化エンジンなどを駆使して信頼性を高め、お客様にとって最適な注文実行を行ないます。",
    "list": [
      "取引サーバー",
      "入札集約",
      "注文送信",
      "上流の資金流動性プール"
    ],
    "link1": "取引時間帯を見る",
    "link2": "スプレッドとすべての商品を見る"
  },
  "screen6": {
    "title": "ITインフラ、OS、ネットワーク、そしてすべてのハードウェアについて一切の心配は不要です。お客様は取引だけに集中してください。お客様がご心配されることは、私たちがあらかじめすべて考え抜いています。ーー　最高の流動性の獲得と高性能な注文実行。",
    "desc": "EBCは最初の第5順位までの注文約定の通信が0.075〜0.125秒で完了します"
  },
  "screen7": {
    "pageTitle": "価格検索と注文約定",
    "pageEnTitle": "Discover Prices & Transfer Orders",
    "title": "EBCは、FIX（金融情報交換プロトコル）を介して届く銀行間取引市場の相場を集約し、お客様に最も流動性の高い取引市場価格を提供しております。加えて、専用のハードウェアであるハブ(HUB)を介して価格を集約した後、最狭スプレッドと取引における深度の維持を実現します。",
    "desc": "FIXプロトコルの核心技術はほとんど遅延のない注文約定と、価格検索エンジンで構成されます。",
    "imgUrl": "ja"
  },
  "screen8": {
    "title": "EBCのbridgeで集約後の<br>スプレッドとデプス",
    "desc": "いますぐ見る",
    "link1": "4つのアセットクラス",
    "link2": "100種類以上の取引商品",
    "link3": "すべてを表示する",
    "trList": [
      "区分",
      "買付単価",
      "購入数量",
      "スプレッド",
      "約定単価",
      "約定数量"
    ],
    "mobileTrList": [
      "区分",
      "買付単価",
      "スプレッド",
      "約定単価"
    ]
  },
  "screen9": {
    "title": "トランザクションのブラックボックス",
    "smallTitle": "注文販売アルゴリズムを正確に最適化することで、注文の 85% 以上がより有利な価格で販売されるようになります。",
    "desc": "EBCトレーディングブラックボックスは、数千万件に及ぶ過去の取引データを学習。ユーザーのプロファイル画像、市場現況、取引ネットワーク環境などのデータを基に、ビッグデータの統計解析や確率モデル、ニューラルネットワークモデル、システム制御理論モデル、人工知能アルゴリズムなどを用いて総合的に分析・判断します。最終的に各トレーダーの属性に適したLPをマッチングさせ、ベストな価格を提示します。",
    "link1": "取引時間帯を見る",
    "link2": "スプレッドとすべての商品を見る",
    "imgUrl": "ja"
  },
  "screen10": {
    "desc": "EBCトレーディングブラックボックスを従来の取引システム（少なくとも100件の注文を含む）と比較すると、以下の分布結果となりました。",
    "list": [
      "各データポイントは1日の取引量",
      "各データポイントの大きさは、注文数量の相対的な大きさ",
      "横軸は注文の約定率",
      "縦軸は取引価格の最適化率"
    ],
    "btn": "投資をはじめる",
    "title": "分析結果から次のことが導き出せます。EBCトレーディングボックスは従来の取引システムと比べて、価格最適化率、注文充填率、アルゴリズムの安定性においてはるかに優れているということです。"
  }
}
