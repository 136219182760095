export default {
	"box-class-name": "oxford-box-cn",
	"button-text": "了解更多",
	"oxford-page1": {
		"text1": "EBC<br> 聯合牛津大學<br>研討會",
		"text2": "「經濟學家都做了什麼」",
		"text3": "Sarah Clifford & David Barrett",
		"text4": "立即預約",
		"text5": "2024年3月6日12:00 pm GMT（北京時間：20:00）",
	},
	"oxford-page2": {
		"text1": "以面向未來的教育, 推動全球經濟發展",
		"text2": "EBC金融集團聯合牛津大學經濟學系舉辦「經濟學家都做了什麼」研討會，積極實踐ESG理念，推動以知識為驅的社會進步發展",
		"text3": "EBC與牛津大學達成合作，於2024年3月6日聯合舉辦主題為「經濟學家都做了什麼」 的研討會。 牛津大學以權威學術研究、對全球政策的傑出貢獻等享譽全球。 其經濟學系致力於透過創新的經濟研究，推動公共政策的改善及全球經濟發展。 與牛津大學攜手，是EBC實踐ESG理念的見證。 EBC積極承擔企業責任，長期致力於教育與人才培養，推動人類社會進步發展。 我們相信：透過發揮EBC集團和牛津大學在跨學科領域的雙向專業優勢，不僅能推動建構更專業安全、透明高效的投資環境，更有助於在全球範圍內建立開放包容的公共交流空間，產生 變革性及創新的經濟研究，以更好地促進公共政策的改進，全球經濟發展。 加入我們，共同創造更美好、平等的未來。",
		"text4": "",
		"text5": "研討會介紹",
		"text6": " 「經濟學家究竟在做什麼」結合時下熱議話題，探討如何利用經濟學推動政策的改進，促進經濟繁榮。 屆時將深入探討“避稅經濟學”，全面解讀不同稅收環境所引起的經濟趨勢及經濟學家在全球經濟安全中所扮演的角色等。",
	},
	"oxford-page3": {
		"text1": "與會嘉賓",
		"text2": "牛津大學經濟學副教授",
		"text3": "Sarah Clifford博士作為牛津大學「避稅經濟學」領域的學術巨擘，致力於探索避稅經濟學背後的經濟學原理，以及稅收相關的經濟行為模型。 Sarah Clifford博士是最早利用跨國面板資料研究MNE ETR的學者之一，主導並提出了經合組織OECD有關稅基侵蝕的「GloBE」法案，極大地推動了BEPS包容性框架的建構。 在其最新論文《避稅的全球影響及其對政策的啟示》中，她深入分析了避稅經濟學如何在全球範圍內對經濟產生影響，以及政府如何透過有效的政策幹預來減少這一現象。",
		"text4": "牛津大學經濟學教授",
		"text5": "Abi Adams-Prassl教授主攻應用微觀經濟學，擅長透過實證方法將數據引入決策模型中，輔助微觀個體和家庭做出決策。 作為英國政府科學辦公室的專家小組成員，Abi Adams-Prassl教授主導了英國政府的風險管理和彈性規劃，並將其從短期擴展為長期目標；同時，作為跨學術和政策研究領域的權威，Abi Adams-Prassl教授也制定了英國法律體系中有關司法的量化框架，確定了在大數據環境中平衡訴諸司法和開放司法原則。",
		"text6": "EBC金融集團（英國）首席執行官",
		"text7": "David Barrett在國際資產多角化配置、結構性風險防範領域有超過35年經驗，親歷多個市場週期，具備豐富的金融監理營運經驗。 擅長國際金融服務、商業策略諮詢、金融產品架構等，洞悉產業趨勢及FCA監理要求的移轉。 David曾主導全球最大投資集團美國AIG的執行和管理工作，推動了華爾街產品架構和合規風控等領域的一系列變革，為後金融危機時代的風險規避和流動性管理搭建了可行性框架。",
	},
	"oxford-page4": {
		"text1": "關於EBC金融集團",
		"text2": "EBC Financial Group成立於英國倫敦，是一家集金融券商、資產管理、移民投資等服務為一體的綜合性金融集團。 EBC業務遍佈全球，以全球頂級監管、毫秒級的訂單執行和機構級的清算池、全方位的保障體系，致力於為每位客戶提供「安全、專業、高效穩定」的交易環境。 EBC集團核心人員擁有逾30年的大型金融機構的工作經驗，親歷多個經濟週期，時間跨越85年《廣場協議》、97年亞洲金融危機、08年全球金融危機和15年瑞郎黑天鵝事件 等。 他們對突發事件及金融危機有優異的因應經驗。 基於實戰鍛鍊出的專業素養，促使EBC對自我要求更為嚴格。 EBC相信，每個認真交易的人，都值得被認真對待。",
		"text3": "關於牛津大學經濟學系",
		"text4": "牛津大學是享譽全球的頂尖高等學府，同時也是英國最具規模的研究機構。 在近千禧年的建校史上，牛津致力於創造開放包容的學術環境，產生創新的經濟研究，建構跨領域的國際合作與交流，推動人類社會發展與進步。 <br>牛津大學經濟學係以其專業權威的學術研究、為全球的經濟和公共政策做出的傑出貢獻而聞名於世，是世界上最多元化的學術經濟學家社群之一。 長期以來，致力於建構開放包容的學術環境，產生可變創新的經濟研究，推動全球經濟發展。",
	},
}