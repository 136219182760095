export default {
	"screen0": {
		"title": "<span>We Value Your Safety</span><br>持牌·安全·稳定",
	},
	"screen1": {
		"link1": "EBC简介",
		"link2": "国际顶级监管",
		"link3": "国际荣誉",
		"link4": "EBC优势",
		"link5": "企业文化",
		"link6": "合作伙伴",
		"link7": "联系我们",

	},
	"screen2": {
		"title": "EBC简介",
		"desc1": "EBC Group，成立于英国伦敦，是一家集金融券商、资产管理、移民投资等服务为一体的综合性金融集团。EBC业务遍布全球，在东京、悉尼、新加坡、香港等国际金融中心设有分公司和办事处，管理层拥有逾30年的环球金融市场营运经验。",
		"desc2": "EBC始终把维护资金安全作为首要责任。除拥有全球最严格监管外，EBC还为合作伙伴提供巴克莱银行独立托管账户，每年购超千万美元保额的保险，并严格执行CASS规定，以雄厚的资本实力和最高级别的风险管理措施，为客户隔离不确定的风险因素。",
		"desc3": "EBC坚信，每个认真交易的人，都值得被认真对待。"
	},
	"screen3": {
		"title": "国际顶级监管",
		"desc": "EBC金融集團旗下各公司，在對應司法管轄區域內，均受到嚴格監管並獲得頂級資格許可。",
		"desc-l1": "EBC Financial Group (UK) Ltd",
		"desc-l2": "英国金融行为监管局 (FCA) 授权和监管",
		"desc-l3": "监管号: 927552",
		"desc-r1": "EBC Financial Group (Australia) Pty Ltd",
		"desc-r2": "澳大利亚证券和投资委员会 (ASIC) 授权和监管",
		"desc-r3": "监管号: 500991",
		"desc-rr1": "EBC Financial Group (Cayman) Ltd",
		"desc-rr2": "由开曼群岛金融管理局(CIMA)授权和监管",
		"desc-rr3": "监管号: 2038223",
	},
	"screen4": {
		"title": "国际荣誉",
		"desc1": "最受信赖券商",
		"desc2": "最佳CFD券商",
		"desc3": "十大最受欢迎券商",
		"desc4": "全球最佳ECN券商",
		"desc5": "全球新锐券商",
		"desc6": "最透明交易券商",
		"desc7": "最受好评券商",
		"desc8": "卓越执行券商",
	},
	"screen5": {
		"title": "EBC优势",
		"list": [
			{
				"title": "1000<i>笔</i>",
				"desc": "每秒最高聚合订单"
			},
			{
				"title": "<20<i>MS</i>",
				"desc": "平均执行速度"
			},
			{
				"title": "98<i>.75%</i>",
				"desc": "数据传输稳定性高达"
			},
			{
				"title": "25<i>+</i>",
				"desc": "对接国际顶级银行和对冲基金"
			},
			{
				"title": "7*24",
				"desc": "VIP尊享服务"
			},
			{
				"title": "0.0<i>PIPS",
				"desc": "同业银行级别的 RAW ECN点差"
			},
		]
	},
	"screen6": {
		"title": "企业文化",
		"desc": "我们的核心价值在于积极为客户创造全方位<span>「Real Value」</span>",
		"list": [
			{
				"title": "安全",
				"desc": "英澳顶级监管，巴克莱托管<br>清算级安全保障"
			},
			{
				"title": "公平",
				"desc": "EBC秉承诚信与尊重的原则<br>认真对待每一位交易者"
			},
			{
				"title": "专注",
				"desc": "在细节中感知EBC服务理念<br>提供最卓越的用户体验"
			},
			{
				"title": "价值",
				"desc": "“安全、专业、稳定”<br>全方位提升个人财富价值"
			},
			{
				"title": "诚实",
				"desc": "EBC视诚实守信为根本<br>用心呈现完美交易体验"
			},
			{
				"title": "责任",
				"desc": "每一次积极的传递<br>都让世界向美好更进一步"
			},
		]
	},
	"screen7": {
		"title": "合作伙伴",
	},
	"screen8": {
		"title": "联系我们",
		"desc1": "市场合作",
		"desc2": "加入我们",
		"desc3": "投诉与建议",
	},
}

