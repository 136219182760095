export default {
    "box-name": "",
    "public-btn-text1": "今すぐ登録",
    "public-btn-text2": "取引商品一覧",
    screen0: {
        "title": "CFD配当金",
        "desc": "CFD配当金は、MT4アカウントの残高に\"Dividend\"と注記され、入金または出金として付与されます。",
    },
    screen1: {
        "title": "対象CFD銘柄と配当金一覧",
    },
    screen2: {
        "title": "金融市場に簡単アクセス",
    }
}