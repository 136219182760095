export default {
	"fca1": "FCA 유동성 청산 계정 개설 프로세스",
	"fca1-m": "FCA 유동성 청산 계정 개설 프로세스",
	"fca2": "EBC Financial Group (UK) Ltd는 영국 FCA(Financial Conduct Authority)의 승인 및 규제를 받는 종합 금융 증권 회사입니다. 레퍼런스 번호: 927552",
	"fca3": "유럽 ​​연합의 \"금융상품투자지침(MiFID)\" 및 후속 개정판인 MiFID II는 고객의 지식수준, 경험 및 전문성에 따라 \"소매 투자자\" 또는 \"전문 투자자\"로 분류합니다.<br><br>FCA 규정에 따라 EBC Financial Group Limited는 전문 투자자와 기관 투자자에게만 허용되며 레버리지 100배의 FCA 유동성 청산계정을 개설할 수 있습니다. 투자자 자금은 바클레이은행 계좌에 독립적으로 예탁됩니다.",
    "fca4":{
		"title":"전문 투자자는 다음 세 가지 기준 중 두 가지를 충족해야 합니다.",
		"title-m":"전문 투자자는 다음 세 가지 기준 중 두 가지를 충족해야 합니다.",
		"desc":"지난 1년간 평균 거래횟수 분기당 10회 달성",
		"desc1":"현금 저축 및 금융 상품으로 구성된 €500,000 이상의 투자 포트폴리오 보유",
		"desc2":"금융업에 종사하거나 최소 1년 이상 근무 경험 보유, CFD 혹은  파생상품 거래의 이해"
	},
	"fca5":{
		"title":"FCA 유동성 청산 계정 개설 프로세스",
		"desc":"신청서 다운로드 및 작성",
		"desc1":"서류 준비 및 인증",
		"desc2":"서류제출 및 이메일 접수",
		"desc3":"규정 준수 검토 및 문서 서명",
		"desc4":"FCA 계좌 개설 및 계좌 설정",
		"btn":"전문 투자자 신청서 다운로드",
		"btn1":"기관 투자자 신청서 다운로드"
	},
	"fca6":{
		"title":"FCA 계좌를 개설하면 가장 권위 있는 관리 감독과 가장 안전한 금융 보호를 받을 수 있습니다. 지금 바로 개설하세요.",
		"title-m":"FCA 유동성 청산 계정 개설",
		"desc":"영국 금융감독청(FCA)",
		"desc1":"FCA 유동성 청산 계정 상담 이메일:"
	},
	"lang":"en"
}

