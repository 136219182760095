export default {
  "screen0": {
    "title": "合理化された PAMM ソリューション",
    "content": "EBCは、専門的なアカウント管理サービスを探してファンドマネジャーと投資家のためにカスタマイズされたPercentage Allocation Management Module(PAMM)を提供する。 ウェブバージョンバックオフィスを通じて投資家とファンドマネジャーが取引資本管理、収益分配、詳細な策定、取引記録ダウンロードなど総合機能に接近できる。 Webバージョンのインターフェイスは使いやすく、レイアウトが明確で包括的な機能を備えています。",
    "tips":"EBCは、ファンドマネージャーと投資家に明確な権利と責任および包括的な機能を備えたパーセンテージアロケーションマネジメントモデル（PAMM）を提供します。Webバージョンの画面がシンプルで、複雑な情報を分かりやすくご覧いただけます。"
  },
  "screen1": {
    "pageEnTitle": "CLEAR DUTIES",
    "pageTitle": "明確な義務",
    "c1Title": "投資家",
		"c1Desc": "シームレスな取引体験を楽しみ、ファンドマネジャーの専門知識を活用してください。 投資家はリスク許容度と収益目標に基づいてファンドマネジャーを選択する自由がある。 高水準の条項を盛り込むことは、資金管理者の権利を保護すると同時にインセンティブとして作用する。",
		"c2Title": "ファンドマネジャー",
		"c2Desc": "幅広い取引経験を持つトレーダーは、PAMMアカウントファンドマネージャーとして資格を得ることができます。 ファンドマネジャーは、複数の取引口座を同時に管理する能力があり、投資家から集められた資金はもちろん、投資家が取引し収益を上げることもできる。 取引の利益または費用の一部は、口座ファンドマネジャーのサービスに対する報酬として機能します。",
    "title": "PAMMアカウントが、多くの人の多様なニーズにお応えします",
    "desc": "投資家：ワンストップな取引サービスによって、ファンドマネージャーと取引結果を共有できます。<br>投資家は、リスク許容度と収益目標に合わせて自由にファンドマネージャーを選択できます。<br>ハイウォーターマーク条項（HWM)によって、ファンドマネージャーにインセンティブを与え、その権利が保護されます。 <br> <br>ファンドマネージャー：豊富な取引経験を持つトレーダーは、PAMMアカウントマネージャーとしての役割をします。<br>ファンドマネージャーは、複数の取引アカウントを同時にマネジメントし、自己資金と投資家から募った資金を利用して取引をして収益を得ることができます。取引による収益またはコストの一部はアカウントマネージャーの報酬として支払われます。"
  },
  "screen2": {
    "pageEnTitle": "SIMPLE IS THE BEST",
    "pageTitle": "シンプル化",
    "title": "Web版はシンプルな画面で、情報が分かりやすいレイアウトにしています",
    "desc": "日々の情報管理、取引ロット/利益配分、レコードダウンロードを含む包括的なソリューションを提供します。 Webバージョンのバックオフィスは、シンプルで明瞭な設計になっており、複雑さを効果的に軽減します。"
  },
  "screen3": {
    "pageEnTitle": "FEATURES AND FUNCTIONS",
    "pageTitle": "利点",
    "title": "あらゆる種類の取引およびマネーマネジメントに最適化",
    "text1":"最大 9 言語",
    "text2":"資金セキュリティの強化とシームレスな取引",
    "text3":"2 主流の利益配分方法（純価値別配分、残高別配分）",
    "text4":"高 ファンドマネジャーに対するウォーターマークインセンティブ 投資家の保護",
    "text5":"Webバージョンは簡潔でわかりやすく、使いやすいように設計されています",
    "text6":"ファンドマネジャーに対し、5つの収益方法（利益シェア/管理手数料/年間管理手数料）を提供する ロット毎の手数料/注文毎の手数料）"
  },
	"imgUrl": "en",
	"styleName": "en-style en-style2",
	"btn-text": "PAMM を申請する"
}
