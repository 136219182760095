export default {
	"leftNav": [{ "id": "#Trading", "name": "交易" },
		{ "id": "#Regulation", "name": "頂級監管" },
		{ "id": "#Security", "name": "安全保障" },
		{ "id": "#Fintech", "name": "金融科技" },
		{ "id": "#Black-Box", "name": "交易黑盒" },
		{ "id": "#Private-Room", "name": "交易空間" },
		{ "id": "#Liquidity", "name": "流動性" },
		{ "id": "#Honor", "name": "國際榮譽" },
		//{ "id": "#News", "name": "新聞" }
	],
	"pages1": {
		"h1":"Trust & Respect",
		"title": "每個認真交易的人,都值得被認真對待",
		"btn": " 觀看全視頻 "
	},
	"pages2": {
		"title": "連接全球熱門交易產品",
		"title1": "探索一個充滿機遇和競爭優勢的世界，掌握最真實和穩定的市場價格",
		"btn": " 了解交易產品 ",
		"text": " 貨幣交易 ",
		"text1": " 大宗商品 ",
		"text2": " 現貨指數差價合約 ",
		"text3": " 股票差價合約 "
	},
	"pages3": {
		"title": "全球頂級監管",
		"title1": "EBC金融集團旗下各公司，在對應司法管轄區域內，均受到嚴格監管並獲得頂級資格許可。",
		"btn": "了解EBC監管",
		"text": "英國 FCA 監管",
		"text1": "EBC Financial Group (UK) Ltd<br class='regulation-br'> 英國金融行為監管局 (FCA) 授權和監管。<br class='regulation-br'> 監管號: 927552 ",
		"text2": "澳大利亞 ASIC 監管",
		"text3": "EBC Financial Group (Australia) Pty Ltd<br class='regulation-br'>澳大利亞證券和投資委員會 (ASIC) 授權和監管。<br class='regulation-br'>監管號: 500991",
		"text4": "開曼 CIMA 監管",
		"text5": "EBC Financial Group (Cayman) Ltd<br class='regulation-br'>由開曼金融管理局（CIMA）授權和監管。<br class='regulation-br'>監管號: 2038223"
	},
	"pages4": {
		"title": "科技賦能交易",
		"title1": "部署於倫敦LD4、紐約NY4、新加坡SG1、東京TY3、香港HK2的超高速專線連接的獨立服務器，提供超低延遲聚合、智能訂單路由和報價優化引擎，構建全球超高速金融生態體。",
		"btn": " 探索底層科技",
		"text": "每秒最高聚合訂單 ",
		"text1": "極速訂單執行 ",
		"text2": "資料傳輸穩定高達 ",
		"text3": "同業銀行級別的RAW ECN點差",
	},
	"pages5": {
		"title": "讓85%以上的訂單成交於更優的價格",
		"title1": "EBC交易黑盒，精準優化拋單算法，在價格優化率、訂單成交率以及算法穩定性方面， EBC交易黑盒遠遠優於傳統交易系統。",
		"btn": " 了解交易黑盒"
	},
	"pages6": {
		"title": "EBC交易空間",
		"title1": "無論您是高頻交易者，還是大單交易家，EBC 的 Private Room 都將在流動性匹配基礎上，搭建您的專屬的交易通道，甚至隱藏您的部分交易信息，保護您的“底牌”，這意味著您的訂單不僅能獲得更好的聚合報價，也可以在成交過程中免於狙擊。",
		"btn": " 了解交易空間"
	},
	"pages7": {
		"title": "國際頂級流動性",
		"title1": "EBC與多家國際頂級銀行建立了長期穩定的合作關係，整合多家優質流動性報價，提供極具競爭力的市場點差，讓您輕鬆享有超低交易成本。",
		"btn": "了解EBC流動性"
	},
	"pages8": {
		"btn": "瀏覽更多新聞"
	},
	"pages9": {
		"title": "全面保障 安心以致",
		"title1": "EBC將資金安全視為首要原則，客戶資金獨立存放於巴克萊銀行的託管帳戶中，並透過與FCA、Financial Commission、Lloyds of London、AON合作，建立了全面、多層次的安全保障機制。",
		"btn": "了解安全保障"
	},
	"lang": "tw"
}

