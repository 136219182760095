import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import MintUI from 'mint-ui';
import 'mint-ui/lib/style.css'
import $ from 'jquery';
import 'bootstrap/dist/js/bootstrap.min.js';
// import 'bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap-icons/font/bootstrap-icons.css';
import '@/assets/css/reset-bootstrap.scss';
import '@/assets/css/common.scss';
import '@/assets/js/reset-bootstrap.js';
import VueAnimateNumber from 'vue-animate-number'
import global from '@/assets/js/globalMixins.js';
import VueI18n from 'vue-i18n'
import MetaInfo from 'vue-meta-info'
import 'wowjs/css/libs/animate.css'
// 全屏滚动 vue-fullpage.js
import 'fullpage.js/vendors/scrolloverflow'
import VueFullpage from 'vue-fullpage.js'
Vue.use(VueFullpage)
Vue.use(MetaInfo)
Vue.use(VueI18n)
Vue.use(global)
Vue.use(VueAnimateNumber)
Vue.use(MintUI)
import i18nMessages from '@/i18n'
const i18n = new VueI18n({
  locale: localStorage.getItem('language') || 'zh', // 语言标识 //this.$i18n.locale // 通过切换locale的值来实现语言切换
  messages: i18nMessages
})


Vue.config.productionTip = false;

new Vue({
  router,
  store,
  i18n,
  render: (h) => h(App)
  // mounted () {
  //     document.dispatchEvent(new Event('render-event'))
  //   }
}).$mount("#app");

router.afterEach((to, from, next) => { 
  gtag('config', 'G-TJ431PDHZX', {'page_path': to.fullPath});
});
