export default {
	"fca1": "FCA流動性清算口座開設プロセス",
	"fca1-m": "FCA流動性清算口座",
	"fca2": "EBC Financial Group (UK) Ltdは、英国金融行動監視機構であるFCAによって認可および監督されているの総合な金融証券会社である。 規制番号：927552。",
	"fca3": "EUの金融商品市場指令（MiFID）とその後の改正であるMiFID IIでは、顧客の知識、経験、専門性のレベルに応じて「個人投資家」と「プロ投資家」に分類しています。<br><br>FCA規制によって、EBC Financial Group (UK) Ltdはただ専門取引者と機関投資家を受け入れます、レバレッジが100倍のFCA流動性清算口座を開設できます。投資家資金はバークレイズ銀行の分離口座で別々に保管されます。",
    "fca4":{
		"title":"専門投資家は次の３つ基準要求のうち2つを満たす必要があります：",
		"title-m":"専門投資家は次の３つ基準要求のうち2つを満たす必要があります：",
		"desc":"過去1年間の平均取引頻度は<br>10次/四半期を達しました。",
		"desc1":"50万ヨーロ以上の投資組合を持っています。<br>投資組合は現金貯蓄預金と金融ツールと含まれています。",
		"desc2":"金融業界で働いたり、少なくとも1年働いたりして、差額契約や派生品取引を理解しています。"
	},
	"fca5":{
		"title":"FCA流動性清算口座開設プロセス",
		"desc":"申込書ダウンロードと記入",
		"desc1":"書類準備と認証",
		"desc2":"書類提出・郵送申し込み",
		"desc3":"コンプライアンス審査と書類への署名",
		"desc4":"FCA口座開設サクセス口座開設",
		"btn":"専門投資家の申込書ダウンロード",
		"btn1":"機関投資家の申込書ダウンロード"
	},
	"fca6":{
		"title":"FCA流動性清算口座を開設<br>最高の権威ある規制保護と最も安全な資金保障",
		"title-m":"FCA流動性清算口座を開設",
		"desc":"英国金融行動監視機構(FCA)",
		"desc1":"FCA 流動性清算口座お問い合わせメール："
	},
	"lang":"en"
}

