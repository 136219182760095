export default {
  "screen0": {
    "enTitle": "Your Trust, Our Protection",
    "title": "온라인 거래의 안전과 사기 방지는 저희가 최우선으로 고려하는 사항입니다",
    "text": "EBC에서 저희는 안전하고 즉시적이고 조작이 용이한 입출금 방식을 제공해 드립니다.고객님은 수시로 자신의 수익 또는 초기투자를 인출할 수 있습니다.고객님의 계정을 충분히 보호하기 위해저희는온라인 지불에 대해 업계 높은 등급의 암호화를실시하고 또 추가 검증조치를 취해EBC에서 고객님의 모든 자금의 안전을 전면적으로 보장해 드립니다.",
    "rightText": "통제 할 기회가있는 삶, <span>지금 출발.</span>"
  },
  "screen1": {
    "smallEnTitle": "Multiple Deposit Methods",
    "smallTitle": "입출금 방식",
    "title": "다양한 입금방식: 유니온페이, T/T, PayPal 등 여러가지 입금방식을 지원합니다.",
    "desc": "고객님이 더 많은 잔여자금을 거래에 사용할수 있게 하기 위해 EBC는 고객님이 거래계좌에 자금을 입금하거나 또는 해당 계좌에서 자금을 인출시 그 어떠한 비용도 받지 않습니다.",
    "list": ["入金方式", "最低入金", "入金到账时间", "最低出金", "支付货币", "手续费", "出金处理时间"],
    "summary": "설명:",
    "btn": "입금 진행",
    "summaryDesc": "<p>1. 구체적인 입금시간은 각 은행, 중간기관의 진행상황에 의해 결정됩니다.</p><p>2. 구체적인 한도는 고객의 은행, 카드발행은행의 규정에 따라 다를수 있습니다.</p> <p>3. 출금 신청 제출시 고객님의 계좌에 보유포지션이 있을 경우 포지션에 영향주지 않기 위해 인출금액에 유의하여 주시기 바랍니다.</p>",
    "imgUrl": "ko"
  },
  "screen2": {
    "title": "전 세계 수백만 명의 상인 중 하나가 되십시오",
    "desc": "간단한 3 단계 만 필요합니다 ，빠르고 안전한 계좌 개설 경험을 즐기십시오.",
    "list": [{
      "title": "등록",
      "desc": "계좌종류 선택후 신청 제출 및 검증 완성"
    }, {
      "title": "개인 정보 제출",
      "desc": "계좌 개설 정보를 쉽게 완료하는 데 1 분이면 충분합니다"
    }, {
      "title": "입금",
      "desc": "$200 의 최소 예치금으로 실시간 활성화 "
    }],
    "btn1": "데모 계좌 개설",
    "btn2": "실제 계좌 개설",
    "yuyan": "ko"
  },
  "screen3": {
    "title": "입출금시 자주 발생하는 문제",
    "faqList": [{
      "title": "계좌에 입금하는 방법은?",
      "desc": "계좌 개설 후 저희 네트워크 클라이언트를 통해 고객님의 지불 메뉴에 들어갑니다.<br>구체적인 방법:<br> 절차1: 시스템은 우선 고객님의 입금계좌를 선택하라고 안내합니다.<br>절차2: 그다음 지불방식표 우측의 버튼을 눌러 지불방식을 선택합니다.<br> 절차3: 입금금액을 입력합니다.<br>입금 화폐 또는 기타 선택항목을 입력하라고 안내할 수 있습니다. EBC계좌는 가장 보편적으로 사용하는 인민폐, 달러, 파운드와 유로화를 포함한 여러가지 기초화폐 선택을 제공합니다.<br> 모든 설명, 조항과 조건을 자세히 읽어 주시고, 동의하시면 “모든 설명을 읽었고 지불 조항과 조건에 동의합니다”를 선택하여 주십시오.<br>【제출을 클릭하십시오】"
    }, {
      "title": "유니온페이를 이용시 입금이 안되는 이유는?",
      "desc": "체크카드를 이용해 입금이 되지 않을 경우, 재입금을 시도하여 주시고 아래 사항을 확인하여 주십시오:<br>  —정확한 카드정보 정확 여부.<br> —유효(기한 미경과)카드 사용 여부.<br>—카드내 자금의 충족 여부.<br> 상기 문제가 존재하지 않으나 여전히 입금이 되지 않을 경우, 고객님의 카드발행은행이 카드를 이용한 입금을 수권하지 않았을 수 있습니다. 이럴 경우 기타 카드를 이용하거나 또는 거래계좌 중의 기타 지불방식을 이용하여 주시기 바랍니다."
    }, {
      "title": "나의 계좌에서 출금하는 방법은?",
      "desc": "아무 시간이나 출금신청을 제출할 수 있습니다. 저희 계좌부서는 매일 출금신청을 처리합니다. 출금은 고객님의 은행계좌로 돌아갑니다. 해당 선택항목을 이용할 수 없을 경우, 저희는 고객님의 은행계좌로 자금을 송금해 드립니다.<br>  또한,<br><br> 은행 T/T출금은 각 은행의 실제 처리시간을 기준으로 합니다. 은행마다 입금시간이 다르므로 자세한 사항은 은행에 문의하여 주시기 바랍니다.<br> 유니온페이를 이용시, 22시 전에 출금하면 1 근무일 내에 입금됩니다.<br><br> 포지션시 출금할 경우 거래계좌 중의 마진율 비율이 출금금액을 제외뒤에도 여전히 200% 이상인 것을 확보하시기 바랍니다. 그러지 않으면 플랫폼이 고객님의 출금신청을 처리할 수 없습니다."
    }, {
      "title": "EBC는 수수료를 받나요?",
      "desc": "EBC는 어떠한 지불방식의 입금 또는 출금에 대해서도 모두 어떠한 비용도 받지 않습니다. 단, 고객님이 모 국제은행기관에 자금을 지불하거나 또는 해당 기관에서 자금지불을 진행 시 비용이 발생할 수 있습니다. 또한 고객님의 은행은 고객님이 EBC에 지불한 자금을 선불현금으로 간주하여 고객님의 선불현금 규칙에 따라 계산서를 발급할 수 있습니다."
    }, {
      "title": "타인의 은행카드로 입금/출금이 가능하나요?",
      "desc": "불가능 합니다. EBC는 제3자의 자금지불을 받지 않습니다. 고객님의 거래계좌에 들어오는 입금은 전부 고객님 명의의 계좌를 통하여야 합니다. 거래계좌의 소유자가 연합계좌의 그중 한측일 경우, 연합계좌의 자금지불을 받게 됩니다.<br><br> 제3자가 제공한 자금으로 의심될 경우, 저희는 자금을 송금자에게 반환하고 고객님의 계좌잔액을 동결할 권리를 보류하며, 신분증명과 자금출처를 검증한 후 다시 처리합니다. 검증을 거치지 않은 상황에서 저희는 고객님의 계좌잔액을 동결할 권리를 보류하며, 고객님은 고객님의 계좌잔액을 인출하지 못합니다.<br><br> EBC는 제3자에 대한 출금을 처리하지 않습니다. 특수상황 시에는 저희에게 연락 주시기 바랍니다."
    }]
  },
  "funding1":{
		"text1": "입출금",
		"text2": "온라인 거래의 안전과 사기 방지는 저희가 최우선으로 고려하는 사항입니다",
		"btn": "즉시 입금",
	},
	"funding2":{
		"text1": "글로벌 다중 통화 자금 솔루션",
		"text2": "글로벌 주요 통화의 즉시 입출금 서비스를 제공하여 국제 및 통화 간 거래 과정을 간소화하고, 24시간 언제든지 편리한 자금 이동을 실현합니다",
	},
	"funding3":{
		"text1": "자금 격리, 독립 보관",
		"text2": "바클레이 은행 최고 수준의 Corporate Banking Account",
		"text3": "EBC는 바클레이 은행 최고 수준의 계좌를 보유하고 있으며, 계좌에는 기업 매출액 및 예금이 650만 파운드 이상이어야 하고, 바클레이의 엄격한 재무 감사와 배경 조사를 통과해야 합니다. EBC는 영국에서 CASS 규정을 엄격히 시행하며, 신탁 자료를 통해 투자자 자금을 독립적으로 위탁하여 자금의 안전과 독립을 보장합니다.",
		"btn": "더 알아보기",
	},
	"funding4":{
		"text1": "전문 금융 책임 보험",
		"text2": "다중 국제 전문 금융 책임 보험으로 고객 자금 안전을 전면적으로 보호하여 자금 손실을 효과적으로 방지합니다.",
	},
	"funding5":{
		"text1": "자금 입출금 방식",
		"text2": "지역 은행 송금, 국제 전신, 전자 지갑, 암호화폐 등의 다양한 자금 입출금 방법을 지원합니다.",
		"text3": "자금 입출금 방식",
		"text4": "지원되는 통화",
		"text5": "입금 한도",
		"text6": "입금 처리 시간",
		"text7": "출금 처리 시간",
		"text8": "현지 은행",
		"text9": "즉시 지급",
		"text10": "영업일 1일",
		"text11": "국제 전신 송금",
		"text12": "제한 없는",
		"text13": "2-5 영업일",
		"text14": "전자 지갑",
		"text15": "디지털 화폐",
	}
}
