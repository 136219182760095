export default {
	"fcb-class-name": "en-fcb-box",
	"video-btn": "動画を視聴する",
	"fcb-page1": {
		"text1": "FCバルセロナ公式パートナー",
		"text2": "EBC Financial GroupはFCバルセロナのFXパートナーとして提携したことを発表します。このパートナーシップによりEBCはFX業界においてユニークな立ち位置を確立することができます。",
		"text3": "このパートナーシップはFX取引、CFD取引やアドバイザリーなどの当社の業務をさらに拡充するものになります。",
	},
	"fcb-page2": {
		"text1": "情熱に惹かれて",
		"text2": "情熱が注がれるすべてのエンカウントは、過酷な挑戦でもあります。<br>それは単なる勝ち負けを超え、結果をも越えます。<br>変革を求める道のりにおいて、私たちは孤独ではありません。<br>私たちは知識と技術の活用を志し、業界に革命を起こすためにスタートラインに立ち、初志貫徹の精神で全力を尽くします。",
	},
	"fcb-page3": {
		"text1": "クラブ以上の存在",
		"text2": "サッカーファンでなくても、誰もが知っています。",
		"text3": "FCバルセロナは120年以上の歴史を持ち、世界で最も愛されているサッカークラブのひとつです。マラドーナ、メッシ、ロナウジーニョ、グアルディオラといった象徴的なスター選手や監督たちの輝きを目の当たりにしてきました。他とは一線を画すスタイルを持つバルセロナは、「単なるクラブ以上の存在」であることの本質を体現しています。",
		"text4": "EBCも単なるトレーディングにとどまらず、包括的な国際金融エコシステムの構築に取り組んでいます。",
	},
	"fcb-page4": {
		"text1": "新たなベンチマークの創造",
		"text2": "多くのスター選手を擁するチーム、情熱と夢を大切にする育成システム、誇り高き功績を物語る数々のトロフィー、積極的な社会貢献への取り組み、これらすべてがFCバルセロナを業界のベンチマークとして確立してきました。",
		"text3": "EBCとFCバルセロナのパートナーシップは、互いの長所を認め合う証です。EBCは、あらゆるトレーダーに対して真摯に対応するという信念を貫いています。トップクラスの国際的金融規制、包括的な保護システム、そして国際的に広く評価されている影響力により、EBCは業界のベンチマークとなっています。",
	},
	"fcb-page5": {
		"text1": "技術革新が 「ドリームチーム」を作り上げる",
		"text2": "FCバルセロナは、ボールポゼッション、ショートパス、チームワークを重視する独自のプレースタイルで世界的に有名です。この戦略は、単なるフィジカルの強さよりも、技術的なスキルと戦術的なインテリジェンスの勝利を強調し、現代サッカーの戦術に影響を与えました。",
		"text3": "同様に、EBCは技術的な躍進に尽力し、一貫して国際標準の最前線に立ち、投資家にとってコンプライアンス、専門性、効率性、安定性の高い国際金融エコシステムを構築しています。"
	},
	"fcb-page6": {
		"text1": "世界をより良くするために最高を目指して",
		"text2": "尊敬、努力、向上心、チームワーク、謙虚さは、FCバルセロナのコアバリューを形成しています。こうした価値観を育み、選手たちがベストを尽くせるよう鼓舞するクラブの取り組みは、EBCの理念と共通しています。",
		"text3": "EBCは、誠実と尊敬の原則に基づき、トレーダー一人ひとりの情熱と期待を大切にしています。業界の透明性を高め、その成長をけん引し、公正な社会の構築に貢献することを使命と考えています。",
		"text4": "共に未来を築こう。",
	},
	"fcb-page7": {
		"text1": "パートナーシップについて",
	}
}