export default {
	"headlist": [{
		"text": "首頁",
		"url": "/"
	}, {
		"text": "交易",
		"tips": "",
		"img": "11",
		"menu": [{
			"tit": "帳戶及條件",
			"list": [ {
				"text": "交易賬戶",
				"url": "/TradingAccount"
			}, {
				"text": "交易產品",
				"url": "/tradingproduct"
			}, {
				"text": "槓桿保證金",
				"url": "/leverage-margin"
			},{
				"text": "出入金",
				"url": "/funding"
			}, {
				"text": "股息",
				"url": "/dividend"
			}]
		},  {
			"tit": "交易工具",
			"list": [{
				"text": "MT4下載",
				"url": "/MT4"
			},{
				"text": "MT5下載",
				"url": "/MT5"
			},{
				"text": "MT4交易工具",
				"url": "/tools"
			}, {
				"text": "PAMM",
				"url": "/PAMM"
			}]
		}]
	}, {
		"text": "EBC研究院",
		"tips": "",
		"img": "33",
		"menu": [{
			"tit": "",
			"list": [{
				"text": "學習中心",
				"url": "/LearningCenter"
			}, {
				"text": "資料中心",
				"url": "/lab"
			},]
		}]
	}, {
		"text": "最新活動",
		"tips": "",
		"img": "33",
		"menu": [{
			"tit": "",
			"list": [{
				"text": "積分商城",
				"url": "PointsMall"
			},{
				"text": "百萬美金交易大賽",
				"url": "/win"
			},
			// {
			// 	"text": "挑戰最强交易環境",
			// 	"url": "/no1"
			// }
		]
		}]
	}, {
		"text": "關於EBC",
		"tips": "",
		"img": "55",
		"menu": [ {
			"tit": "關於我們",
			"list": [{
				"text": "關於EBC",
				"url": "/about-us"
			},{
				"text": "社會責任",
				"url": "/ESG"
			}, {
				"text": "幫助中心",
				"url": "https://support.ebchelp.com/hc/zh-tw"
			}]
		},{
			"tit": "合作夥伴",
			"list": [{
				"text": "影響力聯盟",
				"url": "/affiliate-programmes"
			},{
				"text": "機構服務",
				"url": "/service"
			},{
				"text": "底層科技",
				"url": "/technology"
			},
			]
		}]
	}, {
		"text": "跟單社區",
		"url": "/from0tohero"
	}],
	"FCA": "FCA開戶",
	"login": "登錄",
	"zhuce": "立即開戶",
	"from0tohero_text": "跟單社區",
	"from0tohero_url": "/from0tohero",
	"faq_url": "https://support.ebchelp.com/hc/zh-tw",
}

